import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./index.scss";

import lib1 from "../../assets/img/teethStyles/lib1.jpg";
import lib2 from "../../assets/img/teethStyles/lib2.jpg";
import lib3 from "../../assets/img/teethStyles/lib3.jpg";
import lib4 from "../../assets/img/teethStyles/lib4.jpg";
import lib5 from "../../assets/img/teethStyles/lib5.jpg";
import lib6 from "../../assets/img/teethStyles/lib6.jpg";
import lib7 from "../../assets/img/teethStyles/lib7.jpg";
import lib8 from "../../assets/img/teethStyles/lib8.jpg";
import lib9 from "../../assets/img/teethStyles/lib9.jpg";
import lib10 from "../../assets/img/teethStyles/lib10.jpg";
import lib11 from "../../assets/img/teethStyles/lib11.jpg";
import lib12 from "../../assets/img/teethStyles/lib12.jpg";
import lib13 from "../../assets/img/teethStyles/lib13.jpg";
import lib14 from "../../assets/img/teethStyles/lib14.jpg";
import lib15 from "../../assets/img/teethStyles/lib15.jpg";
import lib16 from "../../assets/img/teethStyles/lib16.jpg";
import lib17 from "../../assets/img/teethStyles/lib17.jpg";
import lib18 from "../../assets/img/teethStyles/lib18.jpg";
import lib19 from "../../assets/img/teethStyles/lib19.jpg";
import lib20 from "../../assets/img/teethStyles/lib20.jpg";
import lib21 from "../../assets/img/teethStyles/lib21.jpg";
import lib22 from "../../assets/img/teethStyles/lib22.jpg";
import lib23 from "../../assets/img/teethStyles/lib23.jpg";
import lib24 from "../../assets/img/teethStyles/lib24.jpg";
import lib33 from "../../assets/img/teethStyles/lib33.jpg";
import lib44 from "../../assets/img/teethStyles/lib44.jpg";
import lib55 from "../../assets/img/teethStyles/lib55.jpg";
import lib66 from "../../assets/img/teethStyles/lib66.jpg";
import lib77 from "../../assets/img/teethStyles/lib77.jpg";
import lib88 from "../../assets/img/teethStyles/lib88.jpg";
import lib99 from "../../assets/img/teethStyles/lib99.jpg";
import lib111 from "../../assets/img/teethStyles/lib111.jpg";
import lib222 from "../../assets/img/teethStyles/lib222.jpg";
import lib1010 from "../../assets/img/teethStyles/lib1010.jpg";
import lib1111 from "../../assets/img/teethStyles/lib1111.jpg";
import lib1212 from "../../assets/img/teethStyles/lib1212.jpg";
import lib1313 from "../../assets/img/teethStyles/lib1313.jpg";
import lib1414 from "../../assets/img/teethStyles/lib1414.jpg";
import lib1515 from "../../assets/img/teethStyles/lib1515.jpg";
import lib1616 from "../../assets/img/teethStyles/lib1616.jpg";
import lib1717 from "../../assets/img/teethStyles/lib1717.jpg";
import lib1818 from "../../assets/img/teethStyles/lib1818.jpg";
import lib1919 from "../../assets/img/teethStyles/lib1919.jpg";
import lib2020 from "../../assets/img/teethStyles/lib2020.jpg";
import lib2121 from "../../assets/img/teethStyles/lib2121.jpg";
import lib2222 from "../../assets/img/teethStyles/lib2222.jpg";
import lib2323 from "../../assets/img/teethStyles/lib2323.jpg";
import lib2424 from "../../assets/img/teethStyles/lib2424.jpg";

const toothStyles = [
  {
    pic1: lib1,
    pic2: lib11,
    style: "D1",
    shape: "D",
    abrasion: 1,
  },
  {
    pic1: lib2,
    pic2: lib22,
    style: "G1",
    shape: "G",
    abrasion: 1,
  },
  {
    pic1: lib3,
    pic2: lib33,
    style: "P1",
    shape: "P",
    abrasion: 1,
  },
  {
    pic1: lib4,
    pic2: lib44,
    style: "S1",
    shape: "S",
    abrasion: 1,
  },
  {
    pic1: lib5,
    pic2: lib55,
    style: "U1",
    shape: "U",
    abrasion: 1,
  },
  {
    pic1: lib6,
    pic2: lib66,
    style: "V1",
    shape: "V",
    abrasion: 1,
  },
  {
    pic1: lib7,
    pic2: lib77,
    style: "O1",
    shape: "O",
    abrasion: 1,
  },
  {
    pic1: lib8,
    pic2: lib88,
    style: "T1",
    shape: "T",
    abrasion: 1,
  },
  {
    pic1: lib9,
    pic2: lib99,
    style: "D2",
    shape: "D",
    abrasion: 2,
  },
  {
    pic1: lib10,
    pic2: lib1010,
    style: "G2",
    shape: "G",
    abrasion: 2,
  },
  {
    pic1: lib111,
    pic2: lib1111,
    style: "O2",
    shape: "O",
    abrasion: 2,
  },
  {
    pic1: lib12,
    pic2: lib1212,
    style: "P2",
    shape: "P",
    abrasion: 2,
  },
  {
    pic1: lib13,
    pic2: lib1313,
    style: "S2",
    shape: "S",
    abrasion: 2,
  },
  {
    pic1: lib14,
    pic2: lib1414,
    style: "T2",
    shape: "T",
    abrasion: 2,
  },
  {
    pic1: lib15,
    pic2: lib1515,
    style: "U2",
    shape: "U",
    abrasion: 2,
  },
  {
    pic1: lib16,
    pic2: lib1616,
    style: "V2",
    shape: "V",
    abrasion: 2,
  },
  {
    pic1: lib17,
    pic2: lib1717,
    style: "D3",
    shape: "D",
    abrasion: 3,
  },
  {
    pic1: lib18,
    pic2: lib1818,
    style: "G3",
    shape: "G",
    abrasion: 3,
  },
  {
    pic1: lib19,
    pic2: lib1919,
    style: "O3",
    shape: "O",
    abrasion: 3,
  },
  {
    pic1: lib20,
    pic2: lib2020,
    style: "P3",
    shape: "P",
    abrasion: 3,
  },
  {
    pic1: lib21,
    pic2: lib2121,
    style: "S3",
    shape: "S",
    abrasion: 3,
  },
  {
    pic1: lib222,
    pic2: lib2222,
    style: "T3",
    shape: "T",
    abrasion: 3,
  },
  {
    pic1: lib23,
    pic2: lib2323,
    style: "U3",
    shape: "U",
    abrasion: 3,
  },
  {
    pic1: lib24,
    pic2: lib2424,
    style: "V3",
    shape: "V",
    abrasion: 3,
  },
];

class ToothStyles extends PureComponent {
  render() {
    const { t: _t } = this.props;
    return (
      <div className="toothStyles">
        {toothStyles.map(({ pic1, pic2, style, shape, abrasion }) => (
          <div className="item" key={style}>
            <div className="main">
              <img src={pic1} alt={style} />
            </div>
            <div className="details">
              <h5>
                {_t("Style ")}
                {style}
              </h5>
              <p>
                {_t("Tooth shape type: ")}
                {shape}
              </p>
              <p>
                {_t("Tooth abrasion rate: ")}
                {abrasion}
              </p>
              <div className="pic">
                <img src={pic2} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

ToothStyles.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ToothStyles);
