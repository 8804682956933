import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "../../../i18n";
import ContactUs from "../../Dialogs/ContactUs";
import CookiePolicy from "../../Dialogs/CookiePolicy";
import TermsConditions from "../../Dialogs/TermsConditions";
import TermsOfUse from "../../Dialogs/TermsOfUse";
import { Facebook, Instagram, YouTube, Pinterest } from "@material-ui/icons";
import { toggleContactUs, toggleCookiePolicy, toggleTermsConditions, toggleTermsOfUse } from "../../../actions";

import "./index.scss";

const Footer = () => {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();

  function changeLanguage(lang) {
    i18next.changeLanguage(lang);
  }

  const { isContactUs, isCookiePolicy, isTermsConditions, isTermsOfUse } = useSelector(({ dialog }) => ({
    isContactUs: dialog.isContactUs,
    isCookiePolicy: dialog.isCookiePolicy,
    isTermsConditions: dialog.isTermsConditions,
    isTermsOfUse: dialog.isTermsOfUse,
  }));

  return (
    <footer className="footer">
      <ContactUs isShow={isContactUs} onClose={() => dispatch(toggleContactUs({ isShow: false }))} />
      <CookiePolicy isShow={isCookiePolicy} onClose={() => dispatch(toggleCookiePolicy({ isShow: false }))} />
      <TermsConditions isShow={isTermsConditions} onClose={() => dispatch(toggleTermsConditions({ isShow: false }))} />
      <TermsOfUse isShow={isTermsOfUse} onClose={() => dispatch(toggleTermsOfUse({ isShow: false }))} />
      <div className="langs">
        <div className="lang" onClick={() => changeLanguage("en")}>
          English (EN)
        </div>
        <div className="lang" onClick={() => changeLanguage("ru")}>
          Русский (РУС)
        </div>
      </div>
      <div className="modals">
        <a target="_blank" className="modal" href="/electronic-contract">
          {_t("Contract")}
        </a>
        <a target="_blank" className="modal" href="/terms-of-use">
          {_t("Terms of Use")}
        </a>
        <a target="_blank" className="modal" href="/policy">
          {_t("Policy")}
        </a>
        <a className="modal" href="#contact_us" onClick={() => dispatch(toggleContactUs({ isShow: true }))}>
          {_t("Contact Us")}
        </a>
      </div>
      <div className="socials">
        <a href="https://www.facebook.com/meshalign/" className="social">
          <Facebook />
        </a>
        <a href="https://www.instagram.com/meshalign" className="social">
          <Instagram />
        </a>
        <a href="https://www.youtube.com/channel/UCPy2ioh4MCFFvTy-GbYgu3Q/featured?view_as=public" className="social">
          <YouTube />
        </a>
        <a href="https://www.pinterest.com/Mesh_Align/" className="social">
          <Pinterest />
        </a>
      </div>
      <div className="studio">Colleagial Studio</div>
    </footer>
  );
};

export default Footer;
