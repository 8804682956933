import React from "react";
import { Link } from "react-router-dom";
import ArrowBack from "../../ArrowBack";
import "./index.scss";

const TermsOfUseRu = () => (
  <div className="classification">
    <div className="overlay" />
    <header className="header">
      <Link className="logo" to="/">
        <span className="dark">Mesh</span>
        <span className="pink">Align</span>
      </Link>
    </header>
    <section>
      <ArrowBack path="/" text="Назад на главную страницу" />
      <h2>ЭЛЕКТРОННОЕ СООБЩЕНИЕ</h2>
      <p className="font-bold text-center">ПЕРЕД ИСПОЛЬЗОВАНИЕМ ИНТЕРНЕТ-СЕРВИСА «MESH ALIGN»</p>

      <p className="font-bold">УВАЖАЕМЫЙ ЗАКАЗЧИК!</p>

      <p className="f-18">
        ПОЖАЛУЙСТА, ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ С НИЖЕИЗЛОЖЕННЫМИ УСЛОВИЯМИ ИСПОЛЬЗОВАНИЯ (ДАЛЕЕ ПО ТЕКСТУ - «УСЛОВИЯ»),
        КОТОРЫЕ ЯВЛЯЮТСЯ НЕОТЪЕМЛИМОЙ ЧАСТЬЮ ЭЛЕКТРОННОГО ДОГОВОРА НА ВЫПОЛНЕНИЕ РАБОТ (ДАЛЕЕ ПО ТЕКСТУ - «ДОГОВОР»),
        УКАЗАННЫЙ ПО АДРЕСУ: https://meshalign.com/electronic-contract.
      </p>
      <p className="f-18">
        ПРИНИМАЯ (АКЦЕПТУЯ) ПУБЛИЧНОЕ ПРЕДЛОЖЕНИЕ ЗАКЛЮЧИТЬ ДОГОВОР, ВЫ АВТОМАТИЧЕСКИ ПРИНИМАЕТЕ УСЛОВИЯ И СОГЛАСНЫ
        ДОБРОСОВЕСТНО И НЕУКОСНИТЕЛЬНО ИХ ВЫПОЛНЯТЬ.
      </p>
      <p className="f-18">ДАННОЕ ЭЛЕКТРОННОЕ СООБЩЕНИЕ ЯВЛЯЕТСЯ НЕОТЪЕМЛИМОЙ ЧАСТЬЮ ДОГОВОРА.</p>

      <p className="font-bold text-center">УСЛОВИЯ ИСПОЛЬЗОВАНИЯ</p>
      <p>ИНТЕРНЕТ-СЕРВИСА «MESH ALIGN»</p>
      <p>Редакция от: 27.04.2020 года.</p>

      <p>
        Условиями использования (далее по тексту - «Условия») определяются правила использования интернет-сервиса «Mesh
        Align» (далее по тексту - интернет-сервис «Mesh Align»). Данные Условия являются неотъемлемой частью
        электронного Договора на выполнение работ (далее по тексту - «Договор»), который указан по адресу:
        https://meshalign.com/electronic-contract.
      </p>

      <p>1. Основные термины и определения.</p>

      <p>
        1.1. «Авторизация» - электронный процесс входа Заказчика в его Аккаунт, во время которого происходит проверка
        наличия прав последнего на предоставление ему соответствующего доступа к такому Аккаунту. Для авторизации
        Заказчик должен ввести в соответствующую электронную форму свои идентификаторы, и подтвердить намерение
        осуществить вход в свой аккаунт.
      </p>
      <p>
        1.2. «Аккаунт» - совокупность электронных сведений о Заказчике, которые последний размещает, загружает или
        вводит в интернет-сервисе «Mesh Align», и которые включают в себя Учетную запись, Профиль, и Личный кабинет.
        Составной частью Аккаунта является также Контент. Для использования интернет-сервиса «Mesh Align» и возможности
        осуществления заказа на выполнение работ - нужен Аккаунт.
      </p>

      <p>
        1.3. «Акцепт» - полное и безусловное принятие Заказчиком предложения (оферты) Исполнителя заключить настоящий
        Договор на определенных им условиях (пункты 3.1. И 3.2. Договора).
      </p>
      <p>1.4. «Идентификаторы» - уникальные логин и пароль, которые создаются лично Заказчиком.</p>
      <p>
        1.5. «Интернет» - глобальная информационная сеть общего доступа, основанная на Интернет-протоколах, определенных
        международными стандартами.
      </p>
      <p>
        1.6. «Контент» - текстовые (письменные) или видео материалы, аудиозаписи или изображения, а также любые другие
        материалы или информация, которые размещаются, загружаются или вводятся Заказчиком в любые разделы
        интернет-сервиса «Mesh Align». Для целей настоящего Договора, Контентом, в том числе, но не исключительно, также
        могут быть соответствующие электронные текстовые сообщения, электронные медицинские карты пациентов, любые
        загруженные в интернет-сервис «Mesh Align» электронные компьютерные файлы любого формата, любые электронные
        цифровые изображения, любое электронное цифровое видео, любая информация, и любые персональные данные всех лиц.
        Контент является составной частью Аккаунта.
      </p>
      <p>
        1.7. «Логин» - уникальный набор символов, который идентифицирует Заказчика в интернет-сервисе «Mesh Align»,
        выраженный в виде адреса электронной почты, находится в беспрепятственном и личном использовании Заказчика. Имя
        является элементом учетной записи. Исполнитель имеет право запретить использование определенных Логинов, а также
        устанавливать по ним требования (длина, допустимые символы и др.).
      </p>
      <p>
        1.8. «Учетная запись» - совокупность размещенной, загруженной или введенной в интернет-сервис «Mesh Align»
        информации о Заказчике.
      </p>
      <p>
        1.9. «Личный кабинет» - раздел интернет-сервиса «Mesh Align», доступ к которому имеет только зарегистрированный
        Заказчик после прохождения электронной процедуры авторизации с помощью идентификатора, и через который Заказчик
        осуществляет управление учетной записью.
      </p>
      <p>
        1.10. "Пароль" - тайное слово или определенная последовательность символов, предназначенная для подтверждения
        личности Заказчика и его прав. Пароль используется для защиты информации, имеющейся в интернет-сервисе «Mesh
        Align» от несанкционированного доступа любых посторонних лиц. Пароль является элементом учетной записи, и
        используется интернет-сервисом «Mesh Align» для предоставления Заказчику разрешения на вход в его аккаунт.
        Исполнитель имеет право запретить использование определенных Паролей, а также устанавливать по ним требования
        (длина, допустимые символы и др.).
      </p>
      <p>
        1.11. «Письменное уведомление» - электронное письмо, может включать в себя текстовые (письменные) или видео
        материалы, аудиозаписи или изображения, оставленные любой Стороной настоящего Договора друг другу на
        соответствующий адрес электронной почты. Для целей настоящего Договора, адресом электронной почты Заказчика
        является адрес, указанный последним в качестве его Логина, а адресом электронной почты Исполнителя является
        адрес, указанный в настоящем Договоре среди других контактных данных последнего. Письменное уведомление может
        быть изложено на украинском, английском или русском языках.
      </p>
      <p>
        1.12. «Профиль» - совокупность персональных сведений о Заказчика, указанных последним при Регистрации в
        интернет-сервисе «Mesh Align» путем заполнения соответствующей регистрационной формы.
      </p>
      <p>
        1.13. Интернет-сервис «Mesh Align» - веб-сайт, размещенный в сети Интернет по адресу https://meshalign.com, с
        помощью которого Заказчик имеет возможность заказывать у Исполнителя выполнения работ по настоящему Договору.
      </p>
      <p>
        1.14. «Регистрация» - электронная процедура, во время которой Заказчик заполняет соответствующую регистрационную
        форму, и создает в интернет-сервисе «Mesh Align» Аккаунт. Для целей настоящего Договора, Регистрация является
        действием Заказчика, которая считается полным и безусловным принятием предложения (оферты) Исполнителя заключить
        настоящий Договор.
      </p>

      <p>2. Общие положения.</p>
      <p>
        2.1. Исполнитель осуществляет текущее управление интернет-сервисом «Mesh Align», определяет его структуру и
        дизайн (внешний вид), позволяет, приостанавливает, ограничивает или блокирует доступ к ней на основании
        Договора, Условий, и Политики конфиденциальности, которая приведена по адресу:
        https://meshalign.com/electronic-contract, https://meshalign.com/terms-of-use, https://meshalign.com/policy.
      </p>
      <p>
        2.2. Исполнитель предоставляет техническую возможность использования интернет-сервиса «Mesh Align» и не
        участвует в формировании (создании) Аккаунта, учетной записи, профиля, и Личного кабинета Заказчика.
      </p>
      <p>2.3. Регистрация в интернет-сервисе «Mesh Align» - бесплатная.</p>
      <p>
        2.4. Исполнитель имеет право в любой момент вносить в интернет-сервис «Mesh Align» любые обновления
        (совершенствование), изменения или корректировки, согласно предписаниям п. 13.7. Договора. Также, Исполнитель
        может в любое удобное для себя время, проводить любые технические работы по интернет-сервису «Mesh Align»
        (например - его обновления, исправления ошибок в работе и т.д.), при которых доступ к нему временно может быть
        заблокирован. В данном случае, Исполнитель заблаговременно предупреждает Заказчика о предстоящих технических
        работах такого рода, с указанием времени их проведения и общей продолжительности.
      </p>
      <p>
        2.5. Заказчику запрещено осуществлять любую модификацию интернет-сервиса «Mesh Align», его структуры и дизайна
        (внешнего вида) под себя или под его программно-аппаратную среду.
      </p>
      <p>
        2.6. Заказчик не может использовать интернет-сервис «Mesh Align», если легитимные санкции любой страны запрещают
        это в соответствующем регионе, в котором заказчик находится.
      </p>
      <p>2.7. Лицам до 13 лет использовать интернет-сервис «Mesh Align» - запрещено.</p>
      <p>
        2.8. Если любое лицо является резидентом Европейской экономической зоны, ей разрешается использовать
        интернет-сервис «Mesh Align» только при достижении возраста, в котором такое лицо может дать согласие на
        обработку персональных данных в соответствии с предписаниями национального законодательства соответствующей
        страны, входящей в состав Европейской экономической зоны.
      </p>
      <p>
        2.9. Дополнительная плата за использование сети Интернет для доступа к интернет-сервису «Mesh Align» -
        покрывается за счет Заказчика.
      </p>
      <p>
        2.10. Заключая Договор, Заказчик соглашается с последующим: в случае, если некоторые положения, правила,
        предписания и/или требования Договора, Условий и/или Политики конфиденциальности (далее - «Положение»)
        противоречат друг другу, имеют между собой разногласия по смыслу и сути или любые другие несогласованности, то
        высшую юридическую силу будет иметь именно то Положение, которое предоставляет Исполнителю больше прав,
        привилегий и льгот, а также является наиболее выгодным для Исполнителя с точки зрения ответственности,
        обязанностей и свободы действий. Например, если в одном Положении говорится об обязанности Исполнителя по
        согласованию с Заказчиком определенных действий или предупреждения последнего об их совершении, а в другом
        Положении такая обязанность - отсутствует, то высшую юридическую силу будет иметь то Положение, которое такие
        обязанности не предусматривает и/или освобождает от них.
      </p>
      <p>
        2.11. Заключая Договор, Заказчик соглашается с последующим: в случае возникновения разногласий в толковании
        настоящих Условий, как в целом, так и отдельных их частей и положений, текст Условий изложен на украинском языке
        - имеет большую и высокую силу.
      </p>

      <p>3. АККАУНТ.</p>

      <p>
        3.1. Для доступа к интернет-сервису «Mesh Align» и его использования нужен Аккаунт, который создается лично
        Заказчиком путем Регистрации. Для возможности осуществить регистрацию, необходимо подать Исполнителю
        соответствующую электронную заявку, указав в ней действующий адрес электронной почты, фамилия, имя, отчество.
      </p>
      <p>
        3.2. Регистрация включает в себя определенную аккредитацию Заказчика на соответствие определенным Исполнителем
        критериям, которые являются обязательными для сотрудничества. Аккредитация производится администратором
        интернет-сервиса «Mesh Align» и предусматривает право Исполнителя перед Регистрацией Заказчика запрашивать у
        последнего определенную информацию и/или предлагать предоставить некоторые документы по уровню профессиональной
        подготовки, наличия необходимого образования и квалификации. По результатам рассмотрения поданной электронной
        заявки, администратор интернет-сервиса «Mesh Align» сообщает о принятом решении по электронной почте. В случае
        несоответствия лица определенным критериям сотрудничества, Исполнитель в праве отказать в Регистрации.
      </p>
      <p>
        3.3. Для Регистрации Заказчику необходимо иметь действующий адрес электронной почты, в дальнейшем выполняеющей
        роль Логина для авторизации в интернет-сервисе «Mesh Align».
      </p>
      <p>
        3.4. Во время Регистрации Заказчик обязан указывать достоверные данные о своей личности, а именно: фамилия, имя,
        отчество, сведения о занимаемой должности и места работы, фотография Профиля (по желанию), номер телефона для
        связи, страна нахождения, адрес электронной поты. Заказчик не обязан раскрывать свою личность, но последний
        должен предоставить Исполнителю точную и актуальную информацию (в том числе Регистрационную). Заказчик не вправе
        выдавать себя за того, кем он не является на самом деле, а также не имеет права создавать аккаунты для других
        лиц.
      </p>
      <p>3.5. Логин должен состоять только из букв английского алфавита, цифр и символов таблицы кодов "ASCII".</p>
      <p>3.6. Пароль должен отвечать следующим требованиям:</p>
      <p>- содержать не менее 6-ти знаков;</p>
      <p>- содержать только латинские буквы и цифры (без знаков препинания, пропусков и других специальных знаков)</p>
      <p>- содержать минимум 1 латинскую букву;</p>
      <p>- содержать минимум 1 цифру.</p>
      <p>
        Пароль не должен совпадать с логином, его не рекомендуется связывать с лицом Заказчика. Пароль должен быть
        таким, чтобы Заказчик мог его запомнить, а не записывать на лист и/или любое устройство для хранения данных.
      </p>
      <p>
        3.7. Заказчик несет полную и персональную ответственность за любые действия в интернет-сервисе «Mesh Align»,
        выполняемые с использованием его аккаунта. Исполнитель вправе заблокировать доступ Заказчика к его Аккаунту в
        интернет-сервисе «Mesh Align», если такой Аккаунт используется с нарушением данных Условий, а также на основании
        и в случаях, определенных в п. 10.2 Договора.
      </p>
      <p>
        3.8. Для Регистрации Аккаунта, Заказчик должен достичь совершеннолетия и/или быть полностью дееспособным и
        правоспособной лицом в понимании национального законодательства страны последнего. В случае, если Заказчик
        является несовершеннолетним, недееспособным и/или неправоспособными лицом, он должен ознакомиться с настоящими
        Условиями вместе с его родителями или законными опекунами. Поскольку Исполнитель лишен какой-либо практической
        возможности осуществлять контроль соблюдения со стороны Заказчика или его родителей или законных опекунов
        изложенных в данном пункте требований - Исполнитель освобождается от любой ответственности при наступлении
        каких-либо негативных последствий из-за использования интернет-сервиса «Mesh Align». Такая ответственность в
        полном объеме возлагается лично на Заказчика или на его родителей или законных опекунов.
      </p>
      <p>
        3.9. Заказчик обязан принимать все необходимые и возможные меры по конфиденциальности его Пароля. При
        необходимости Заказчик в любое время может такой Пароль изменить. С целью предотвращения любого
        несанкционированного использования Аккаунта Заказчика, Исполнитель может ограничить количество попыток ввода
        пароля при авторизации.
      </p>
      <p>
        3.10. В случае, если в результате несанкционированного доступа посторонних лиц, Заказчик потерял доступ к его
        аккаунту, он обязан безотлагательно сообщить об этом Исполнителю путем отправки письменного уведомления, с целью
        предотвращения уничтожения или повреждения Контента, несанкционированной передачи контроля над аккаунтом, и тому
        подобное.
      </p>
      <p>
        3.11. Исполнитель принимает все усилия для обеспечения безопасности Аккаунта Заказчика, включая его Контент, но,
        несмотря на это, не гарантирует, что несанкционированные третьи лица не смогут преодолеть такие меры
        безопасности.
      </p>

      <p>4. КОНТЕНТ.</p>

      <p>
        4.1. Заказчик несет полную и персональную ответственность за любой Контент и его содержание, размещается,
        загружается или вводится Заказчиком в любые разделы интернет-сервиса «Mesh Align».
      </p>
      <p>
        4.2. Заказчик не имеет права размещать, загружать или вводить в интернет-сервис «Mesh Align» Контент, содержание
        которого нарушает любые личные неимущественные или имущественные права других лиц, включая права на
        конфиденциальность, права интеллектуальной собственности и авторские права, права на персональные данные, и
        т.п., или предписания национального законодательства Украины, международного права и/или национального
        законодательства страны Заказчика.
      </p>
      <p>
        4.3. Исполнитель не осуществляет мониторинг и анализ Контента Заказчика, не поощряет и не утверждает его
        содержание, и не заставляет последнего к размещению, загрузки или введение такого Контента к интернет-сервису
        «Mesh Align».
      </p>
      <p>
        4.4. Условия размещения, загрузки или введение в интернет-сервис «Mesh Align», а также обработки, использования,
        распространения и перемещения Контента, который содержит в себе персональные данные любых физических лиц -
        определяются Политикой конфиденциальности и учитывает предписания национального законодательства Украины,
        международного права, национального законодательства страны Заказчика и Генерального регламента Европейского
        парламента и Совета по защите персональных данных.
      </p>
      <p>
        4.5. В интернет-сервисе «Mesh Align» могут содержаться ссылки на сторонние веб-сайты, рекламодателей, сервисы,
        специальные предложения и другие мероприятия или деятельность, которые не принадлежат Исполнителю но не
        контролируются им. Исполнитель не подтверждает и не берет на себя никакой ответственности в отношении таких
        сторонних веб-сайтов, информации, материалов, товаров или услуг. Если Заказчик переходит на любой сторонний
        сайт, сервис или контент через интернет-сервис «Mesh Align», он несет за это полную и персональную
        ответственность и дает согласие на то, что Исполнитель не несет перед ним обязательств в связи с тем, что он
        использовал или перешел на сторонний сайт или сервис. Исполнитель отказывается от любой ответственности, если
        такие сторонние веб-сайты или сервисы:
      </p>
      <p>- нарушают права интеллектуальной собственности или авторских прав любых третьих сторон;</p>
      <p>- являются неточными, неполными или дезинформирующими;</p>
      <p>- коммерчески не выгодными и не подходят для выполнения специальной цели или выполнения определенной цели;</p>
      <p>- не обеспечивают надлежащую защищенность;</p>
      <p>- содержат вирусы или другие объекты разрушительного характера;</p>
      <p>- являются клеветническими или обижают человеческое достоинство.</p>
      <p>
        4.6. Исполнитель оставляет за собой право, но не обязанность, по своему усмотрению без предварительного
        уведомления Заказчика удалять Контент или его отдельные элементы, если по мнению Исполнителя их содержание
        нарушает предписания Договора, Условий и Политики конфиденциальности.
      </p>
      <p>
        4.7. В случае прекращения действия Договора или его расторжения (независимо от того, какая именно сторона и на
        каких именно основаниях его разорвала) Исполнитель имеет право по своему усмотрению, без специального разрешения
        Заказчика, хранить в интернет-сервисе «Mesh Align" (не удалять из него ) Контент, который был размещен, загружен
        ли введен Заказчиком в интернет-сервисе «Mesh Align» в течение всего срока его использования. Исполнитель может
        хранить такой Контент в интернет-сервисе «Mesh Align» в течение любого срока. Контент или его часть подлежит
        обязательному удалению из интернет-сервиса «Mesh Align» в случае получения Исполнителем письменного уведомления
        с требованием о таком удаления.
      </p>

      <p>5. ПРАВИЛА ПОВЕДЕНИЯ.</p>

      <p>
        5.1. Используя интернет-сервис «Mesh Align», Заказчик обязан добросовестно и неукоснительно соблюдать следующие
        правила поведения:
      </p>
      <p>
        - не нарушать предписания и требования национального законодательства Украины, международного права и/или
        национального законодательства страны Заказчика при использовании интернет-сервиса «Mesh Align»;
      </p>
      <p>- не использовать интернет-сервис «Mesh Align» в любых незаконных или запрещенных целях;</p>
      <p>- не регистрироваться под фамилией и именем другого лица, выдавая себя за него;</p>
      <p>- не размещать в своем Профиле изображения других лиц в качестве собственной фотографии;</p>
      <p>
        - не нарушать работу интернет-сервиса «Mesh Align», не использовать любое программное обеспечение, которое
        повреждает, изменяет, прерывает интернет-сервис «Mesh Align» и/или прекращает его работу, или иным образом
        влияет на его нормальное и стабильное функционирование;
      </p>
      <p>- не совершать несанкционированный доступ (попытки доступа) к Аккаунту других Заказчиков;</p>
      <p>
        - не размещать, не загружать и не вводить в интернет-сервис «Mesh Align» Контент, содержание которого может быть
        вредным для детей;
      </p>
      <p>
        - не размещать, не загружать и не вводить в интернет-сервиса «Mesh Align» Контент, который является неуместным,
        оскорбительным, приносящим беспокойство, кощунственным, угрожающим, полным ненависти, неприличным, вульгарным,
        что дискредитирует, унижает, ущемляет или нарушает конфиденциальность других людей или недопустимо по другим
        причинам;
      </p>
      <p>
        - не размещать, не загружать и не вводить в интернет-сервис «Mesh Align» Контент, который является нелегальным
        (противозаконным), или Контент, на который у Заказчика нет прав на его свободное распространение;
      </p>
      <p>- не выдавать себя за другое лицо, или за сотрудника Исполнителя или его представителя;</p>
      <p>
        - не пытаться получить от других Заказчиков сведения об их Паролях, данных об их Аккаунте, или любую другую
        частную и/или персональную информацию;
      </p>
      <p>- не пользоваться любыми средствами оплаты в мошеннических целях, без разрешения уполномоченного владельца;</p>
      <p>
        - не использовать роботов, программ-обходчиков или других автоматизированных устройств для доступа к
        интернет-сервису «Mesh Align», и/или для копирования его материалов;
      </p>
      <p>
        - не использовать запрещенные программы или другие средства для мошенничества, поиска уязвимостей в
        интернет-сервисе «Mesh Align», его взлома или внесения в него изменений;
      </p>
      <p>
        - не использовать обнаруженные в работе интернет-сервиса «Mesh Align» уязвимости, сбои и/или ошибки в
        противоправных и/или корыстных целях;
      </p>
      <p>
        - не продавать, не дарить, не обменивать, не сдавать в аренду, не передавать во временное пользование или любым
        другим способом не отчуждать свой Аккаунт другим лицам;
      </p>
      <p>
        - не использовать интернет-сервис «Mesh Align» в стране, на территории которой законодательство запрещает
        Исполнителю выполнять определенные Договором Работы;
      </p>
      <p>
        - не использовать интернет-сервис «Mesh Align» для политических, пропагандистских, рекламных и/или религиозных
        целей;
      </p>
      <p>
        - не передавать с помощью интернет-сервиса «Mesh Align» вирусы, злонамеренное и шпионское программное
        обеспечение или любые другие элементы разрушительного характера. 5.2. В случае, если Заказчик используя Аккаунт
        нарушает определенные в п. 5.1. настоящих Условий правила поведения, но не устраняет или не прекращает такие
        нарушения в течение суток после отправки Исполнителем письменного уведомления с предупреждением об их устранении
        или прекращения, Исполнитель вправе заблокировать доступ к такому Аккаунту на неограниченное время, с
        последующим его удалением из интернет-сервиса «Mesh Align», включая весь размещенный, загруженный ли введенный
        Заказчиком Контент.
      </p>
      <p>
        5.3. Используя интернет-сервис «Mesh Align», Заказчик гарантирует, что любые его действия не противоречат
        национальному законодательству Украины, международному праву и/или национальному законодательству страны
        Заказчика, не нарушают любые личные неимущественные или имущественные права всех лиц, включая права на
        конфиденциальность, права интеллектуальной собственности и авторских прав.
      </p>

      <p>6. ИСПОЛЬЗОВАНИЕ ДАННЫХ.</p>

      <p>
        6.1. Заключая Договор, Заказчик соглашается с тем, что Исполнитель может собирать, хранить и использовать любые
        данные из персонального компьютера Заказчика, или любого другого подобного устройства, которым последний
        пользуется для доступа к интернет-сервису «Mesh Align», включая данные об используемые на таких устройствах
        операционные системы, об их IP-адреса и тому подобное.
      </p>
      <p>
        6.2. Исполнитель собирает, хранит и использует определенные в п. 6.1. Условий данные исключительно для улучшения
        качества функционала интернет-сервиса «Mesh Align», и для устранения возможных сбоев или ошибок в его работе на
        соответствующих персональных компьютерах или других подобных устройствах Заказчика.
      </p>
      <p>
        6.3. Сбор, хранения и использование, определенных в п. 6.1. Условий, данных осуществляется Исполнителем в
        соответствии с Политикой конфиденциальности.
      </p>
      <p>
        6.4. Исполнитель вправе принимать в любое время любых мероприятий, которые считает обоснованно необходимыми или
        уместными для обеспечения соблюдения требований Договора, Условий, Политики конфиденциальности, национального
        законодательства Украины, международного права и/или национального законодательства страны Заказчика. Заказчик
        признает и соглашаетесь с тем, что Исполнитель может без обязательств перед Заказчиком получать доступ,
        использовать, хранить и/или раскрывать персональную информацию и/или содержание Контента Заказчика
        правоохранительным органам, другим органам государственной власти и третьим лицам в случае, если Исполнитель
        считает такие действия обоснованно необходимыми или уместными, или если это необходимо в соответствии с
        национальным законодательством Украины, международного права, и/или национального законодательства страны
        Заказчика, или если Исполнитель добросовестно предусматривает, что подобный доступ, использование, раскрытие или
        хранения обоснованно необходимые для:
      </p>
      <p>- выполнение судебного решения или иного исполнительного документа;</p>
      <p>
        - выполнение любого правомерного предписания со стороны соответствующего контролирующего государственного
        органа;
      </p>
      <p>- обеспечение выполнения Договора, включая расследование любого возможного нарушения;</p>
      <p>
        - выявление, предупреждение или иного реагирования на угрозы национальной безопасности, мошенничество или иные
        правонарушения, или технические неисправности;
      </p>
      <p>
        - защиты прав, собственности или безопасности Исполнителя, других Заказчиков, третьих лиц, общества или
        государства, как того требует или предполагает национальное законодательство Украины, международное право и/или
        национальное законодательство страны Заказчика.
      </p>

      <p>7. ТЕХНИЧЕСКАЯ ПОДДЕРЖКА.</p>
      <p>
        7.1. Исполнитель осуществляет техническую поддержку интернет-сервиса «Mesh Align» по вопросам, связанным с
        функциональностью, особенностями его эксплуатации. Техническая поддержка представляет собой мероприятия,
        осуществляемые Исполнителем в установленных им пределах и объемах для обеспечения функционирования
        интернет-сервиса «Mesh Align», включая информационно-консультационную поддержку по вопросам его использования.
      </p>
      <p>
        7.2. Заказчик вправе обращаться в службу технической поддержки Исполнителя без выплаты дополнительного
        вознаграждения.
      </p>
      <p>
        7.3. Для возможности предоставления качественной, своевременной и результативной поддержки Исполнитель вправе
        требовать от Заказчика предоставления любой информации, касающейся данных его Аккаунту, Личного кабинета,
        профиля, учетной записи, технических характеристик его персонального компьютера, или любого другого подобного
        устройства, которым последний пользуется для доступа к интернет-сервису «Mesh Align».
      </p>
      <p>
        7.4. Если при использовании интернет-сервиса «Mesh Align» будут выявлены ошибки, Исполнитель принимать меры для
        их исправления в максимально короткие сроки. Заказчик соглашается, что точное определение срока устранения
        ошибки не может быть установлено заранее, поскольку Программа тесно взаимодействует с другими программами
        сторонних разработчиков, операционными системами и аппаратными ресурсами различных компьютеров и
        работоспособность и устранение проблем может зависеть не только от Исполнителя.
      </p>

      <p>8. ПРАВООБЛАДАТЕЛЯМ.</p>

      <p>
        8.1. Если любое физическое лицо (независимо от гражданства, страны проживания, места нахождения, расовой
        принадлежности, политических взглядов и вероисповедания) или юридическое лицо (независимо от формы
        собственности, организационно-правовой структуры, места регистрации) считает, что любой размещенный, загруженный
        или введенный в интернет-сервис «Mesh Align» Контент, нарушает любые его личные неимущественные или
        имущественные права, включая права на конфиденциальность, права интеллектуальной собственности и авторские
        права, права на персональные данные и т.д. - такое лицо, или его, надлежащим образом уполномоченный,
        представитель, может в любое время обратиться к Исполнителю с соответствующим письменным обращением.
      </p>
      <p>
        8.2. Письменное обращение должно быть изложено преимущественно на украинском, английском или русском языках, и
        направлено Исполнителю на следующий адрес электронной почты: meshalign@gmail.com. Письменное обращение
        обязательно должно включать:
      </p>
      <p>
        - Ф.И.О. или наименование лица, направившего письменное обращение, его контактные данные (адрес электронной
        почты для переписки, полный номер телефона и т.д.);
      </p>
      <p>
        - в случае если письменное обращение направлено уполномоченным представителем - копии документов (или URL-ссылки
        на них), которые подтверждают его полномочия действовать от имени владельца нарушенных прав;
      </p>
      <p>
        - детальное описание обжалуемого Контента, который нарушает права, по возможности - URL-ссылки, по которым он
        размещен, загружен ли введен в интернет-сервис «Mesh Align», или другие обстоятельства, по которым такой Контент
        можно найти и/или идентифицировать;
      </p>
      <p>- информацию о том, каким образом и какие именно, оспариваемый Контент, права нарушает;</p>
      <p>- информацию о лице, которому нарушеные права принадлежат;</p>
      <p>
        - копии любых официальных документов (или URL-ссылки на них), подтверждающие принадлежность нарушенных прав
        соответствующему лицу;
      </p>
      <p>
        - четкое и понятное формулирование соответствующего требования по устранению и/или прекращении нарушения прав.
      </p>
      <p>
        8.3. В случае необходимости, для надлежащего и объективного рассмотрения соответствующего письменного обращения,
        Исполнитель вправе спрашивать или требовать у лица, которое его отправило, предоставления дополнительной
        информации и/или дополнительных документов.
      </p>
      <p>
        8.4. При наличии практической возможности, и при отсутствии обстоятельств непреодолимой силы, Исполнитель
        приложит все усилия для того, чтобы соответствующее письменное обращение было рассмотрено в течение десяти
        рабочих дней с момента его получения. Сообщение о результатах такого рассмотрения Исполнитель направляет в
        письменном виде на соответствующий адрес электронной почты лица, направившего письменное обращение, либо
        сообщает ей об этом с помощью телефонной связи, при наличии полного номера телефона, по которому с таким лицом
        можно связаться.
      </p>

      <p>9. РЕКЛАМНЫЕ МАТЕРИАЛЫ.</p>

      <p>
        9.1. Используя интернет-сервис «Mesh Align», Заказчик осознает, не отрицает и соглашается о следующем:
        Исполнитель имеет право с помощью интерфейса и функционала интернет-сервиса «Mesh Align», в любое время, любым
        способом демонстрировать Заказчику любые материалы рекламного характера.
      </p>
      <p>
        9.2. Заключая Договор, Заказчик дает свое безусловное согласие на демонстрацию в интернет-сервисе «Mesh Align»
        любых материалов рекламного характера, без выплаты Заказчику со стороны Исполнителя или других лиц любой
        денежного вознаграждения и/или компенсации.
      </p>

      <p>10. КОММЕРЧЕСКОЕ ИСПОЛЬЗОВАНИЕ.</p>

      <p>
        10.1. Заказчику запрещается использовать интернет-сервис «Mesh Align» в коммерческих целях на общих основаниях
        ее использования, изложенных в Договоре, данных Условиях и Политике конфиденциальности.
      </p>
      <p>
        10.2. Если Заказчик желает от своего имени, или от имени других лиц, распространять с помощью интернет-сервиса
        «Mesh Align» определенную коммерческую информацию и/или предлагать для коммерческих целей определенные товары,
        услуги или работы, он может обратиться с соответствующим письменным уведомлением к Исполнителю, изложив в нем
        свои просьбы и предложения по данному поводу.
      </p>
      <p>
        10.3. По результатам рассмотрения соответствующего письменного обращения Заказчика, Исполнитель может позволить
        коммерческое использование интернет-сервиса «Mesh Align» исключительно на основании и на условиях письменного
        (не электронного), отдельно заключенного между Заказчиком и Исполнителем договора, не имеющего никакого
        отношения к Договору, данных Условий и политики конфиденциальности.
      </p>

      <p className="font-bold">РЕКВИЗИТЫ И КОНТАКТНАЯ ИНФОРМАЦИЮ ИСПОЛНИТЕЛЯ:</p>
      <p className="font-bold">ФИЗИЧЕСКОЕ ЛИЦО-ПРЕДПРИНИМАТЕЛЬ ПОГАРСКИЙ АНТОН ЮРЬЕВИЧ.</p>

      <p>
        Дата и номер записи в Едином государственном реестре юридических лиц, физических лиц-предпринимателей и
        общественных формирований: 28.03.2018 года, № 21030000000096301.
      </p>

      <p>
        Адрес (место фактического проживания): 69600, Украина, Запорожская область, город Запорожье, Коммунарский район,
        ул. Реконструктивная, дом 3.
      </p>

      <p>Регистрационный номер учетной карточки налогоплательщика: 2987814459.</p>

      <p>Адрес электронной почты: meshalign@gmail.com.</p>

      <p>
        Режим работы: с понедельника по пятницу каждую неделю (кроме общемировых и украинских праздничных дней), с 10
        час. 00 мин. до 17 час. 00 мин.
      </p>

      <p>Тел .: + 38 097 977 39 98.</p>
    </section>
  </div>
);

export default TermsOfUseRu;
