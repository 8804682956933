import React from "react";
import { Link } from "react-router-dom";
import ArrowBack from "../../ArrowBack";

import "./index.scss";

const PolicyUa = () => (
  <div className="classification">
    <div className="overlay" />
    <header className="header">
      <Link className="logo" to="/">
        <span className="dark">Mesh</span>
        <span className="pink">Align</span>
      </Link>
    </header>
    <section>
      <ArrowBack path="/" text="Назад на главную страницу" />
      <h2>ЕЛЕКТРОННЕ ПОВІДОМЛЕННЯ</h2>
      <p className="font-bold text-center">перед використанням інтернет-сервісу «Mesh Align»</p>

      <p className="font-bold">ШАНОВНИЙ ЗАМОВНИК!</p>

      <p className="f-18">
        БУДЬ ЛАСКА, УВАЖНО ОЗНАЙОМТЕСЯ ІЗ ВИКЛАДЕНОЮ НИЖЧЕ ПОЛІТИКОЮ КОНФІДЕНЦІЙНОСТІ (далі по тексту – «Політика»), ЯКА
        Є НЕВІД’ЄМНОЮ ЧАСТИНОЮ ЕЛЕКТРОННОГО ДОГОВОРУ НА ВИКОНАННЯ РОБІТ (далі по тексту – «Договір»), НАВЕДЕНОГО ЗА
        АДРЕСОЮ: https://meshalign.com/electronic-contract.
      </p>
      <p className="f-18">
        ПРИЙМАЮЧИ (АКЦЕПТУЮЧИ) ПУБЛІЧНУ ПРОПОЗИЦІЮ УКЛАСТИ ДОГОВІР, ВИ АВТОМАТИЧНО ПРИЙМАЄТЕ ПОЛІТИКУ, І ПОГОДЖУЄТЕСЬ
        СУМЛІННО ТА НЕУХИЛЬНО ЇЇ ВИКОНУВАТИ.
      </p>
      <p className="f-18">ДАНЕ ЕЛЕКТРОННЕ ПОВІДОМЛЕННЯ Є НЕВІД’ЄМНОЮ ЧАСТИНОЮ ДОГОВРУ.</p>

      <p className="font-bold text-center">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</p>

      <p>Редакція від: 27.04.2020 року.</p>

      <p>
        Даною Політикою конфіденційності (далі по тексту – «Політика») визначаються умови розміщення, завантаження
        та/або введення до будь-яких розділів інтернет-сервісу «Mesh Align» (далі по тексту – інтернет-сервіс «Mesh
        Align») персональних даних будь-яких фізичних осіб чи іншої конфіденційної інформації, а також умови обробки,
        використання, поширення, переміщення і захисту таких даних та інформації. Дана Політика є невід’ємною частиною
        електронного Договору на виконання робіт (далі по тексту – «Договір»), який наведено за адресою:
        https://meshalign.com/electronic-contract.
      </p>

      <p>1. ОСНОВНІ ТЕРМІНИ ТА ВИЗНАЧЕННЯ.</p>

      <p>
        1.1. «Авторизація» – електронний процес входу Замовника до його Акаунту, під час якого відбувається перевірка
        наявності прав останнього на надання йому відповідного доступу до такого Акаунту. Для Авторизації Замовник
        повинен ввести у відповідну електронну форму свої Ідентифікатори, та підтвердити намір здійснити вхід до
        Акаунту.
      </p>
      <p>
        1.2. «Акаунт» – сукупність електронних відомостей щодо Замовника, які останній розміщує, завантажує чи вводить в
        інтернет-сервісі «Mesh Align», і які включають в себе Обліковий запис, Профіль, та Особистий кабінет. Складовою
        частиною Акаунту також є Контент. Для використання інтернет-сервісу «Mesh Align» та можливості здійснювати
        замовлення виконання робіт – потрібен Акаунт.
      </p>
      <p>
        1.3. «Акцепт» – повне та безумовне прийняття Замовником пропозиції (оферти) Виконавця укласти даний Договір на
        визначених ним умовах (пункти 3.1. та 3.2. Договору).
      </p>
      <p>1.4. «Ідентифікатори» – унікальні Логін та Пароль, які створюються особисто Замовником.</p>
      <p>
        1.5. «Інтернет» – глобальна інформаційна мережа загального доступу, яка базується на Інтернет-протоколах,
        визначених міжнародними стандартами.
      </p>
      <p>
        1.6. «Контент» – текстові (письмові) або відео матеріали, аудіо записи чи зображення, а також будь-які інші
        матеріали чи інформація, що розміщуються, завантажуються чи вводяться Замовником у будь-які розділи
        інтернет-сервісу «Mesh Align». Для цілей даного Договору, Контентом, в тому числі, але не виключно, також є
        відповідні електронні текстові повідомлення, електронні медичні картки пацієнтів, будь-які завантажені до
        інтернет-сервісу «Mesh Align» електронні комп’ютерні файли будь-якого формату, будь-які електронні цифрові
        зображення, будь-яке електронне цифрове відео, будь-яка інформація, та будь-які персональні дані будь-яких осіб.
        Контент є складовою частиною Акаунту.
      </p>
      <p>
        1.7. «Логін» – унікальний набір символів, що ідентифікує Замовника в інтернет-сервісі «Mesh Align», виражений у
        вигляді адреси електронної пошти, що знаходиться у безперешкодному та особистому використанні Замовника. Логін є
        елементом Облікового запису. Виконавець має право заборонити використання певних Логінів, а також встановлювати
        щодо них вимоги (довжина, допустимі символи, та ін.).
      </p>
      <p>
        1.8. «Обліковий запис» – сукупність розміщеної, завантаженої чи введеної до інтернет-сервісу «Mesh Align»
        інформації про Замовника.
      </p>
      <p>
        1.9. «Особистий кабінет» – розділ інтернет-сервісу «Mesh Align», доступ до якого має тільки зареєстрований
        Замовник після проходження електронної процедури Авторизації за допомогою Ідентифікаторів, та через який
        Замовник здійснює управляння Обліковим записом.
      </p>
      <p>
        1.10. «Пароль» – таємне слово або певна послідовність символів, призначена для підтвердження особи Замовника та
        його прав. Пароль використовується для захисту інформації, наявної у інтернет-сервісі «Mesh Align» від
        несанкціонованого доступу будь-яких сторонніх осіб. Пароль є елементом Облікового запису, та використовуються
        інтернет-сервісом «Mesh Align» для надання Замовнику дозволу на вхід до його Акаунту. Виконавець має право
        заборонити використання певних Паролів, а також встановлювати щодо них вимоги (довжина, допустимі символи, та
        ін.).
      </p>
      <p>
        1.11. «Письмове повідомлення» – електронний лист, що може містити в собі текстові (письмові) або відео
        матеріали, аудіо записи чи зображення, надісланий будь-якою Стороною даного Договору одна одній на відповідну
        адресу електронної пошти. Для цілей даного Договору, адресою електронної пошти Замовника є адреса, зазначена
        останнім в якості його Логіну, а адресою електронної пошти Виконавця є адреса, зазначена в даному Договорі серед
        інших контактних даних останнього. Письмове повідомлення може бути викладене українською, англійською або
        російською мовами.
      </p>
      <p>
        1.12. «Профіль» – сукупність персональних відомостей про Замовника, вказаних останнім при Реєстрації в
        інтернет-сервісі «Mesh Align» шляхом заповнення відповідної реєстраційної форми.
      </p>
      <p>
        1.13. Інтернет-сервіс «Mesh Align» – веб-сайт, розміщений в мережі Інтернет за адресою https://meshalign.com, за
        допомогою якого Замовник має можливість замовляти у Виконавця виконання робіт за даним Договором.
      </p>
      <p>
        1.14. «Реєстрація» – електронна процедура, під час якої Замовник заповнює відповідну реєстраційну форму, та
        створює у інтернет-сервісі «Mesh Align» Акаунт. Для цілей даного Договору, Реєстрація є дією Замовника, яка
        вважається повним та безумовним прийняттям пропозиції (оферти) Виконавця укласти даний Договір.
      </p>

      <p>2. ЗАГАЛЬНІ ПОЛОЖЕННЯ.</p>

      <p>
        2.1. Виконавець поважає права кожної людини на конфіденційність інформації щодо неї, усвідомлює важливість їх
        дотримання, та зобов’язується вживати усіх можливих правових, організаційних і технічних заходів для захисту
        такої інформації від несанкціонованого та/або протиправного використання.
      </p>
      <p>2.2. Дана Політика визначає наступне:</p>
      <p>- яку Виконавець збирає конфіденційну інформацію;</p>
      <p>- для яких цілей Виконавець збирає конфіденційну інформацію;</p>
      <p>- як Виконавець зберігає конфіденційну інформацію;</p>
      <p>- як довго Виконавець зберігає конфіденційну інформацію;</p>
      <p>- як Виконавець використовує конфіденційну інформацію;</p>
      <p>- як Виконавець поширює конфіденційну інформацію;</p>
      <p>- як Виконавець розкриває конфіденційну інформацію;</p>
      <p>- права Замовника та інших фізичних осіб (людей) щодо конфіденційної інформації;</p>
      <p>- повідомлення щодо файлів «сookie».</p>
      <p>
        2.3. Для цілей даної Політики, під конфіденційною інформацією розуміється будь-яка інформація про певну людину,
        яка може збиратися, зберігатися, використовуватися, поширюватися, передаватися та розкриватися у визначеному нею
        порядку, за її згодою, та відповідно до передбачених нею умов.
      </p>
      <p>2.4. Для цілей даної Політики, до складу конфіденційної інформації входить:</p>
      <p>
        - персональні дані щодо певної людини (відомості чи сукупність відомостей про фізичну особу, яка ідентифікована
        або може бути конкретно ідентифікована, наприклад: прізвище, ім’я, по батькові, номер паспорта або
        ідентифікаційного посвідчення, дата народження, місце народження, місце проживання, місце реєстрації, інформація
        щодо прописки, номер телефону, раса і національність, біологічна стать, політичні погляди, віросповідання,
        сексуальна орієнтація, біометричні дані – фізичні, фізіологічні або поведінкові ознаки фізичної особи, за
        допомогою яких можливо однозначно ідентифікувати людину, зображення людського обличчя, відбитки пальців, запис
        голосу, дані про фізичний або психічний стан здоров'я людини, медичні аналізи і висновки, генетичні дані –
        успадковані чи набуті генетичні ознаки фізичної особи, що надають унікальну інформацію про фізіологію або стан
        здоров'я, відповідні біологічні зразки, медичні висновки, результати медичних обстежень та/або досліджень,
        тощо);
      </p>
      <p>- інформація про Обліковий запис та Пароль Замовника;</p>
      <p>
        - інформація, яка автоматично передається інтернет-сервісу «Mesh Align» за допомогою файлів «сookie» (наприклад:
        адреса електронної пошти, IP-адреса і параметри з’єднання, дата та час відвідування інтернет ресурсів, історія
        та параметри таких відвідувань, назва браузерів, тощо).
      </p>
      <p>
        2.5. Укладаючи Договір, Замовник підтверджує, що він обізнаний відносно наступного: розміщення, завантаження чи
        введення до інтернет-сервісу «Mesh Align», а також обробка, використання, поширення, та переміщення Замовником
        Контенту, який містить в собі будь-яку конфіденційну інформацію щодо певних людей, включаючи їх персональні дані
        – здійснюється виключно та безпосередньо під персональну відповідальність Замовника, під час чого останній
        повинен дотримуватися приписів національного законодавства України, міжнародного права, національного
        законодавства країни Замовника, та Генерального регламенту Європейського парламенту і Ради щодо захисту
        персональних даних.
      </p>
      <p>
        2.6. Замовник повинен самостійно проінформувати певну людину про те, для чого саме Замовнику потрібна
        конфіденційна інформація, яким чином вона буде збиратися, зберігатися, використовуватися, поширюватися,
        передаватися та розкриватися у інтернет-сервісі «Mesh Align», хто саме та яким саме чином до неї буде мати
        доступ за допомогою інтернет-сервісу «Mesh Align». Від так, Замовник повинен самостійно отримати від певної
        людини дозвіл на збір, використання, поширення, передачу та розкриття конфіденційної інформації та/або
        персональних даних щодо неї за допомогою інтернет-сервісу «Mesh Align». Надаючи Виконавцю через інтернет-сервіс
        «Mesh Align» будь-яку конфіденційну інформацію та/або персональні дані щодо інших осіб (людей) – Замовник
        гарантує, що отримав від таких осіб (людей) відповідний дозвіл на збір, використання, поширення, передачу та
        розкриття конфіденційної інформації та/або персональних даних щодо неї за допомогою інтернет-сервісу «Mesh
        Align».
      </p>
      <p>
        2.7. Виконавець не вимагає, не ініціює і не контролює розміщення, завантаження чи введення до Програми
        Замовником будь-якого Контенту, який складає конфіденційну інформацію, та/або персональні дані щодо певної
        людини, не впливає на зміст і цілісність такої інформації, а також не знає та не може знати – чи отримана така
        інформація законно та/або за згодою відповідної людини, і чи порушує вона охоронювані законом права та інтереси
        третіх осіб, приписи національного законодавства України, міжнародного права та національного законодавства
        країни Замовника, та Генерального регламенту Європейського парламенту і Ради щодо захисту персональних даних.
      </p>
      <p>
        2.8. Укладаючи Договір, Замовник надає Виконавцю безумовну згоду на збір, зберігання, використання, поширення,
        передачу та розкриття конфіденційної інформації щодо Замовника, виключно на умовах, в порядку, та в межах даної
        Політики. При цьому, Замовник підтверджує, що викладені в даній Політиці умови та порядок щодо збору,
        використання, поширення, передачі та розкриття конфіденційної інформації – влаштовують останнього в повному
        обсязі, як визначені ним самим, а уся конфіденційна інформація щодо нього – надається ним добровільно, без
        будь-якого примусу з боку Виконавця та/або третіх осіб, не під впливом обману та/або помилки, та без будь-якого
        фізичного та/або психологічного тиску.
      </p>

      <p>3. ЯКУ ВИКОНАВЕЦЬ ЗБИРАЄ КОНФІДЕНЦІЙНУ ІНФОРМАЦІЮ.</p>

      <p>
        3.1. Для можливості використання інтернет-сервісу «Mesh Align», як в цілому, так і окремих його розділів, а
        також для можливості надавати відповідну технічну підтримку та здійснювати покращення роботи інтернет-сервісу
        «Mesh Align», Виконавець може запропонувати Замовнику надати деяку конфіденційну інформацію про себе. При цьому
        Виконавець гарантує, що буде збирати лише ту конфіденційну інформацію, яка необхідна для визначених, конкретних,
        явних та законних цілей.
      </p>
      <p>
        3.2. Замовник має право вирішувати – надавати Виконавцю конфіденційну інформацію щодо себе, чи не надавати. При
        цьому може статися так, що в разі відмови від надання певної конфіденційної інформації щодо себе – Замовник не
        зможе використовувати інтернет-сервіс «Mesh Align» та/або окремі її розділи. Необхідність надання певної
        конфіденційної інформації щодо Замовника, а також відповідні наслідки в разі відмови з боку останнього від її
        надання – можуть зазначатися безпосередньо у інтернет-сервісі «Mesh Align».
      </p>
      <p>
        3.3. Укладаючи Договір, Замовник підтверджує, що він обізнаний відносно наступного: Виконавець не вимагає від
        Замовника надання будь-яких відомостей про його платіжні дані та інструменти, банківські рахунки, платіжні
        картки, а також будь-яких інших відомостей, які необхідні для оплати грошових коштів. Виконавець в жодному разі
        не несе відповідальності за збереження чи протиправне використання вказаних вище та подібних відомостей, навіть
        якщо такі відомості були отримані Виконавцем випадково або через навмисні чи помилкові дії Ліцензіата.
      </p>
      <p>3.4. Виконавець може збирати наступну конфіденційну інформацію щодо Замовника:</p>
      <p>- адреса електронної пошти;</p>
      <p>- прізвище, ім’я та по батькові;</p>
      <p>- найменування займаної посади та/або місця роботи;</p>
      <p>- номер телефону для зв’язку;</p>
      <p>- країна знаходження;</p>
      <p>
        - цифрова фотографія, на якій зображено особу Замовника, або на якій міститься будь-яке інше зображення, за яким
        можна ідентифікувати особу Замовника.
      </p>
      <p>
        3.5. Виконавець може збирати наступну конфіденційну інформацію щодо інших осіб (людей), яка надається Замовником
        під час використання інтернет-сервісу «Mesh Align», з урахуванням приписів пунктів 2.5., 2.6. та 2.7. даної
        Політики:
      </p>
      <p>- прізвище, ім’я та по батькові;</p>
      <p>- найменування займаної посади та/або місця роботи;</p>
      <p>- місце проживання;</p>
      <p>- номер телефону;</p>
      <p>- біологічна стать;</p>
      <p>- паспортні дані;</p>
      <p>- номер страхування;</p>
      <p>
        - цифрова фотографія, на якій зображено особу, або на якій міститься будь-яке інше зображення, за яким таку
        особу можна ідентифікувати;
      </p>
      <p>
        - будь-які електроні комп’ютерні (цифрові) файли, які містять в собі результати медичного обстеження та/або
        дослідження певної особи (людини), як в цілому, так і окремих частин тіла (наприклад: телерентгенограмма,
        комп'ютерна томографія, ортопантомограмма, цифрове тривимірне зображення верхньої та/або нижньої щелепи, цифрове
        тривимірне зображення черепу, ультразвукове дослідження, магнітно-резонансна томографія, тощо);
      </p>
      <p>
        - будь-яку іншу конфіденційну інформацію та/або персональні дані щодо певної особи (людини), яку Замовник
        розміщує, завантажує чи вводить до інтернет-сервісу «Mesh Align».
      </p>

      <p>4. ДЛЯ ЯКИХ ЦІЛЕЙ ВИКОНАВЕЦЬ ЗБИРАЄ КОНФІДЕНЦІЙНУ ІНФОРМАЦІЮ.</p>

      <p>
        4.1. Конфіденційна інформація щодо Замовника надається Виконавцю для можливості створення Акаунту (в тому числі
        Облікового запису) Замовника, за допомогою якого останній використовує інтернет-сервіс «Mesh Align», та здійснює
        управління Обліковим записом, а також для:
      </p>
      <p>
        - можливості приймання від Замовника замовлень на виконання відповідної Роботи за Договором, безпосереднього
        виконання такої Роботи та передачі її результатів Замовнику;
      </p>
      <p>
        - можливості ідентифікації особи Замовника в разі надання технічної або інформаційно-консультаційної підтримки з
        питань використання інтернет-сервісу «Mesh Align».
      </p>
      <p>
        4.2. Конфіденційна інформацію щодо інших осіб (людей), яка надається Замовником під час використання
        інтернет-сервісу «Mesh Align», з урахуванням приписів пунктів 2.5., 2.6. та 2.7. даної Політики, може збиратися
        Виконавцем виключно з метою ідентифікації певних осіб (людей) та їх клінічних захворювань, для можливості
        виконання певної Роботи, яку замовив Замовник за Договором.
      </p>

      <p>5. ЯК ЗАМОВНИК ЗБЕРІГАЄ КОНФІДЕНЦІЙНУ ІНФОРМАЦІЮ.</p>

      <p>
        5.1. Розміщена, завантажена чи введена до інтернет-сервісу «Mesh Align» Замовником конфіденційна інформація щодо
        Замовника, або іншої особи (людини) – зберігається у відповідних комп’ютерних файлах, які є складовою частиною
        інтернет-сервісу «Mesh Align», який в свою чергу розміщується на зовнішніх серверах у вигляді SaaS-версії.
      </p>
      <p>
        5.2. Виконавець використовує «хмарні» серверні технології обробки даних, за якими фізичне розташування
        комп’ютерних файлів, які є складовою частиною інтернет-сервісу «Mesh Align» – може час від часу та динамічно
        змінюватися, що забезпечує підвищений захист таких даних від несанкціонованого та/або протиправного
        використання. Про поточне місцезнаходження таких файлів, в яких міститься конфіденційна інформація Замовника,
        або іншої особи (людини), можна дізнатися безпосередньо від Виконавця, шляхом направлення Письмового
        повідомлення із відповідним запитом.
      </p>
      <p>
        5.3. На виконання приписів національного законодавства України, міжнародного права та національного
        законодавства країни Замовника, та Генерального регламенту Європейського парламенту і Ради щодо захисту
        персональних даних, Виконавець зобов’язується укладати відповідні договори щодо обробки конфіденційної
        інформації із відповідними постачальниками послуг «хмарних» серверних технологій, які будуть залучатися
        Виконавцем для обробки даних інтернет-сервісу «Mesh Align».
      </p>
      <p>
        5.4. Виконавець використовує у роботі інтернет-сервісу «Mesh Align» протокол передачі даних «HTTPS», який
        підтримує захист даних при транспортуванні за допомогою шифрування інформації відповідно до стандартів SSL і
        TLS, що призначені для забезпечення більш безпечного з'єднання між сервером і Замовником.
      </p>

      <p>6. ЯК ДОВГО ВИКОНАВЕЦЬ ЗБЕРІГАЄ КОНФІДЕНЦІЙНУ ІНФОРМАЦІЮ.</p>

      <p>
        6.1. Конфіденційна інформація щодо Замовника, або іншої особи (людини) зберігається Виконавцем протягом усього
        часу функціонування інтернет-сервісу «Mesh Align».
      </p>
      <p>
        6.2. Конфіденційна інформація щодо Замовника, або іншої особи (людини) може бути видалена з інтернет-сервісу
        «Mesh Align» в будь-який час, на підставі приписів даної Політики або Умов використання.
      </p>

      <p>7. ЯК ВИКОНАВЕЦЬ ВИКОРИСТОВУЄ КОНФІДЕНЦІЙНУ ІНФОРМАЦІЮ.</p>

      <p>
        7.1. Виконавець використовує конфіденційну інформацію виключно для забезпечення працездатності інтернет-сервісу
        «Mesh Align» та зручності його використання Замовником, згідно із приписами п. 4.1. та 4.2. даної Політики, а
        також для можливості виконувати зобов’язання Сторін за Договором.
      </p>

      <p>8. ЯК ВИКОНАВЕЦЬ ПОШИРЮЄ КОНФІДЕНЦІЙНУ ІНФОРМАЦІЮ.</p>

      <p>
        8.1. Розміщена, завантажена чи введена до інтернет-сервісу «Mesh Align» Замовником конфіденційна інформація щодо
        Замовника, або іншої особи (людини) – залишається у інтернет-сервісі «Mesh Align», та жодним чином не
        поширюється і не розповсюджується мережею Інтернет, або через інші стороні ресурси чи іншим стороннім програмним
        забезпеченням.
      </p>

      <p>9. ЯК ВИКОНАВЕЦЬ РОЗКРИВАЄ КОНФІДЕНЦІЙНУ ІНФОРМАЦІЮ.</p>

      <p>
        9.1. Виконавець може розкрити конфіденційну інформацію виключно в тому разі, якщо того вимагає національне
        законодавство України, міжнародне право, національне законодавство країни Замовника, та Генеральний регламент
        Європейського парламенту і Ради щодо захисту персональних даних. В будь-яких інших випадках – Виконавець не за
        яких обставин та в жодному разі не розкриває конфіденційну інформацію стороннім особам.
      </p>

      <p>10. ПРАВА ЗАМОВНИКА ТА ІНШИХ ФІЗИЧНИХ ОСІБ (ЛЮДЕЙ) ЩОДО КОНФІДЕНЦІЙНОЇ ІНФОРМАЦІЇ.</p>

      <p>
        10.1. Виконавець поважає право кожної людини на недоторканність її приватного життя, та на можливість
        контролювати обіг персональних даних щодо себе.
      </p>
      <p>
        10.2. В залежності від приписів національного законодавства України, міжнародного права, національного
        законодавства країни Замовника або країни певної фізичної особи (людини), та Генерального регламенту
        Європейського парламенту і Ради щодо захисту персональних даних, Замовник та будь-яка інша фізична особа
        (людина) має право на доступ, виправлення та/або видалення конфіденційної інформації щодо себе.
      </p>
      <p>
        10.3. Виконавець вимагає, щоб запит Замовника чи будь-якої іншої фізичної особи (людини) щодо доступу,
        виправлення та/або видалення конфіденційної інформації – задовольняв чинне національне законодавство України,
        міжнародне право, національне законодавство країни Замовника, та Генеральний регламент Європейського парламенту
        і Ради щодо захисту персональних даних. Крім того, Виконавець вимагає, щоб така особа надала достатню
        інформацію, яка дозволяє підтвердити її особистість і переконатися, що заявник є суб'єктом або юридично
        уповноваженою особою щодо запитуваної конфіденційної інформації.
      </p>
      <p>10.4. Замовник чи будь-яка інша фізична особа (людина) мають право:</p>
      <p>
        - отримувати чітку, ясну і зрозумілу інформацію про те, як Виконавець використовує конфіденційну інформацію;
      </p>
      <p>- отримати копію своїх особистих конфіденційних даних безкоштовно;</p>
      <p>- на виправлення або доповнення їх особистої конфіденційної інформації відповідно до мети використання;</p>
      <p>- на видалення з інтернет-сервісу «Mesh Align» конфіденційної інформації щодо себе.</p>

      <p>11. ПОВІДОМЛЕННЯ ЩОДО ФАЙЛІВ «СOOKIE».</p>

      <p>
        11.1. Використовуючи інтернет-сервіс «Mesh Align», Замовник погоджується із наведеним нижче порядком
        використання Виконавцем файлів «cookie». Якщо Замовник не згоден із наведеним нижче порядком використання
        Виконавцем файлів «cookie» – він повинен відключити відповідні налаштування щодо файлів «cookie» у браузері, за
        допомогою якого планує використовувати інтернет-сервіс «Mesh Align», або повністю відмовитися від його
        використання.
      </p>
      <p>
        УВАГА! Відключення відповідних налаштувань щодо файлів «cookie» у браузері, за допомогою якого Замовник планує
        використовувати інтернет-сервіс «Mesh Align» – може привести до зниження якості його використання.
      </p>
      <p>11.2. Що таке файли «cookie»?</p>
      <p>
        Файли «cookie» – це невеликі текстові файли, які зберігаються в браузері певного комп'ютера або мобільного
        телефону після відвідування будь-яких веб-сайтів. Файли «cookie» широко застосовуються для підтримки роботи
        веб-сайтів або для підвищення якості досвіду користувача, а також для надання певної інформації власникам
        веб-сайтів.
      </p>
      <p>11.3. Як можна дозволити або заборонити використання файлів «cookie»?</p>
      <p>
        В будь який момент, Замовник у налаштуваннях свого браузера, за допомогою якого він планує використовувати
        інтернет-сервіс «Mesh Align», може дозволити використання всіх файлів «cookie», встановити попередження про
        використання (збереження) файлів «cookie» у відповідному браузері відповідного пристрою, або взагалі заборонити
        використання файлів «cookie». У налаштуваннях певного браузера також можна заборонити будь-які файли «cookie»
        або тільки певні файли «cookie». Оскільки всі браузери відрізняються один від одного, більш детальна інформація
        про налаштування для файлів «cookie» наведена у відповідному розділі певного браузера. Порядок зміни налаштувань
        браузера і параметрів використання файлів «cookie» залежить від використовуваного браузера. При необхідності
        Замовник може звернутися до довідки певного браузеру.
      </p>
      <p>11.4. Для чого Виконавець використовує файли «cookie»?</p>
      <p>
        Файли «cookie» використовуються Виконавцем виключно для підвищення зручності та якості використання Замовником
        інтернет-сервісу «Mesh Align» через відповідні браузери.
      </p>
      <p>11.5. Управління файлами «cookie» за допомогою налаштувань браузера.</p>
      <p>
        Налаштування більшості браузерів передбачають автоматичне отримання використання файлів «cookie». Якщо Замовник
        не хоче, щоб веб-сайт зберігав файли «cookie» на його пристрої, необхідно змінити налаштування браузера так, щоб
        отримувати попередження при кожній спробі збереження файлів «cookie» певного типу. Замовник також можете змінити
        налаштування так, щоб заборонити браузеру отримувати більшість файлів «cookie» або тільки деякі сторонні з них.
      </p>
      <p>11.6. Які саме файли «cookie» використовує Виконавець?</p>
      <p>Файли «cookie», які використовуються Виконавцем, можна розділити на наступні категорії:</p>
      <p>- які дозволяють ідентифікувати особистість Замовника;</p>
      <p>
        - які необхідні для підвищення ефективності роботи інтернет-сервісу «Mesh Align» (не дозволяють ідентифікувати
        особистість Замовника);
      </p>
      <p>
        - які використовуються для розширення функціональних можливостей (можуть містити персональні дані, які надаються
        Замовником);
      </p>
      <p>
        - які пов'язані з показом цільової реклами і рекламних оголошень за допомогою інтерфейсу інтернет-сервісу «Mesh
        Align», за ідентифікатором пристрою або IP-адресою (можуть збирати персональні дані).
      </p>
      <p>11.7. Тривалість зберігання файлів «cookie».</p>
      <p>
        Сеансові файли «cookie» (тимчасові), зберігаються на пристрої Замовника до того моменту, коли він залишаєте
        інтернет-сервіс «Mesh Align».
      </p>
      <p>
        Постійні файли «cookie», залишаються на пристрої Замовника на більш тривалий термін або до моменту їх ручного
        видалення.
      </p>

      <p className="font-bold">РЕКВІЗИТИ ТА КОНТАКТНА ІНФОРМАЦІЯ ВИКОНАВЦЯ:</p>

      <p className="font-bold">ФІЗИЧНА ОСОБА-ПІДПРИЄМЕЦЬ ПОГАРСЬКИЙ АНТОН ЮРІЙОВИЧ.</p>

      <p>
        Дата та номер запису в Єдиному державному реєстрі юридичних осіб, фізичних осіб-підприємців та громадських
        формувань: 28.03.2018 року, № 21030000000096301.
      </p>

      <p>
        Адреса (місце фактичного проживання): 69600, Україна, Запорізька область, місто Запоріжжя, Комунарський район,
        вул. Реконструктивна, будинок 3.
      </p>

      <p>Реєстраційний номер облікової картки платника податків: 2987814459.</p>

      <p>Адреса електронної пошти: meshalign@gmail.com.</p>

      <p>
        Режим роботи: з понеділка по п’ятницю кожного тижня (крім загальносвітових та українських святкових днів), з 10
        год. 00 хв. до 17 год. 00 хв.
      </p>

      <p>Тел.: + 38 097 977 39 98.</p>
    </section>
  </div>
);

export default PolicyUa;
