import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";

import MalocclusionDialog from "./MalocclusionDialog";
import { malocclusion } from "./data";

class Malocclusion extends PureComponent {
  state = {
    isModal: false,
    currentItem: 1,
  };
  toggleModal = (id) => {
    this.setState({ isModal: !this.state.isModal, currentItem: id });
  };

  render() {
    const { t: _t, isAuth } = this.props;
    const { isModal, currentItem } = this.state;
    const malocclusion1 = malocclusion(_t);

    return (
      <div className="classificationList">
        {isModal && (
          <MalocclusionDialog
            data={malocclusion1.find((el) => el.id === currentItem)}
            isShow={isModal}
            toggle={this.toggleModal}
          />
        )}

        {malocclusion1.map(({ id, pic, title, necData, link, clarification }) => (
          <div className="item" key={id}>
            <div className="main">
              {isAuth ? (
                <div className="pic" onClick={() => this.toggleModal(id)}>
                  <img src={pic} alt={title} />
                </div>
              ) : (
                <Link to={`classification/${link}`} target="_blank" rel="noopener noreferrer" className="pic">
                  <img src={pic} alt={title} />
                </Link>
              )}

              <h6>{title}</h6>
            </div>
            <div className="details">
              <p>{_t("Necessary input data:")}</p>
              <div className="data">
                {necData.map((el) => (
                  <div className="pic" title={el.title} key={el.title}>
                    <img src={el.pic} alt={el.title} />
                    <p>{el.title}</p>
                  </div>
                ))}
              </div>
              {clarification && (
                <>
                  <p>{_t("Clarification:")}</p>
                  {clarification.map((el) => (
                    <p className="clarification" key={el}>
                      {el}
                    </p>
                  ))}
                </>
              )}
              {isAuth ? (
                <Button variant="contained" color="secondary" onClick={() => this.toggleModal(id)}>
                  {_t("Learn more")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  target="_blank"
                  component={Link}
                  to={`classification/${link}`}
                >
                  {_t("Learn more")}
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

Malocclusion.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({ login }) => ({
  isAuth: login.isAuth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Malocclusion));
