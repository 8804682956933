import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, TextField, Button } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { API } from "../../../modules/Api";
import * as Yup from "yup";
import "./index.scss";

class DialogContactUs extends PureComponent {
  state = {
    recaptcha: "",
  };

  onlineSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      email: Yup.string().email(_t("Invalid email")).required(_t("Required")),
    });
  };

  onlineSend = ({ email, text }) => {
    API.post("/contact_us/new", { email, text, recaptcha: this.state.recaptcha }).then(() => {
      this.props.onClose();
    });
  };

  render() {
    const { t: _t, onClose, isShow } = this.props;
    return (
      <Dialog fullWidth maxWidth={"md"} className="dialogContactUs" open={isShow} onClose={onClose}>
        <Formik
          initialValues={{
            email: "",
            text: "",
          }}
          validationSchema={this.onlineSchema}
          onSubmit={this.onlineSend}
        >
          {({ errors }) => (
            <Form className="form">
              <Field name="text">
                {({ field }) => (
                  <TextField
                    margin="dense"
                    label={_t("Message")}
                    multiline
                    fullWidth
                    rows="2"
                    rowsMax="40"
                    {...field}
                    className="textarea"
                  />
                )}
              </Field>
              <Field name="email">
                {({ field }) => (
                  <TextField
                    type="email"
                    label={_t("Email")}
                    margin="dense"
                    fullWidth
                    className="input"
                    error={!!errors.email}
                    helperText={errors.email}
                    {...field}
                  />
                )}
              </Field>
              <Button type="submit" className="dialogBtn">
                {_t("Send")}
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}

DialogContactUs.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogContactUs);
