import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";

const PasswordInput = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <TextField
      type={passwordVisible ? "text" : "password"}
      error={!!props.errors[props.name] && !!props.touched[props.name]}
      helperText={!!props.errors[props.name] && !!props.touched[props.name] && props.errors[props.name]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordInput;
