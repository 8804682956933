import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./index.scss";

import lib1 from "../../assets/img/teethStyles2/lib1.jpg";
import lib2 from "../../assets/img/teethStyles2/lib2.jpg";
import lib3 from "../../assets/img/teethStyles2/lib3.jpg";
import lib4 from "../../assets/img/teethStyles2/lib4.jpg";
import lib5 from "../../assets/img/teethStyles2/lib5.jpg";
import lib6 from "../../assets/img/teethStyles2/lib6.jpg";
import lib7 from "../../assets/img/teethStyles2/lib7.jpg";
import lib8 from "../../assets/img/teethStyles2/lib8.jpg";
import lib9 from "../../assets/img/teethStyles2/lib9.jpg";
import lib10 from "../../assets/img/teethStyles2/lib10.jpg";
import lib111 from "../../assets/img/teethStyles2/lib111.jpg";
import lib12 from "../../assets/img/teethStyles2/lib12.jpg";
import lib13 from "../../assets/img/teethStyles2/lib13.jpg";
import lib14 from "../../assets/img/teethStyles2/lib14.jpg";
import lib15 from "../../assets/img/teethStyles2/lib15.jpg";
import lib16 from "../../assets/img/teethStyles2/lib16.jpg";
import lib17 from "../../assets/img/teethStyles2/lib17.jpg";
import lib18 from "../../assets/img/teethStyles2/lib18.jpg";
import lib19 from "../../assets/img/teethStyles2/lib19.jpg";
import lib20 from "../../assets/img/teethStyles2/lib20.jpg";
import lib21 from "../../assets/img/teethStyles2/lib21.jpg";
import lib222 from "../../assets/img/teethStyles2/lib222.jpg";
import lib23 from "../../assets/img/teethStyles2/lib23.jpg";
import lib24 from "../../assets/img/teethStyles2/lib24.jpg";
import lib25 from "../../assets/img/teethStyles2/lib25.jpg";
import lib26 from "../../assets/img/teethStyles2/lib26.jpg";
import lib27 from "../../assets/img/teethStyles2/lib27.jpg";
import lib28 from "../../assets/img/teethStyles2/lib28.jpg";
import lib29 from "../../assets/img/teethStyles2/lib29.jpg";
import lib30 from "../../assets/img/teethStyles2/lib30.jpg";
import lib1_png from "../../assets/img/teethStyles2/lib1.png";
import lib2_png from "../../assets/img/teethStyles2/lib2.png";
import lib3_png from "../../assets/img/teethStyles2/lib3.png";
import lib4_png from "../../assets/img/teethStyles2/lib4.png";
import lib5_png from "../../assets/img/teethStyles2/lib5.png";
import lib6_png from "../../assets/img/teethStyles2/lib6.png";
import lib7_png from "../../assets/img/teethStyles2/lib7.png";
import lib8_png from "../../assets/img/teethStyles2/lib8.png";
import lib9_png from "../../assets/img/teethStyles2/lib9.png";
import lib10_png from "../../assets/img/teethStyles2/lib10.png";
import lib11_png from "../../assets/img/teethStyles2/lib11.png";
import lib12_png from "../../assets/img/teethStyles2/lib12.png";
import lib13_png from "../../assets/img/teethStyles2/lib13.png";
import lib14_png from "../../assets/img/teethStyles2/lib14.png";
import lib15_png from "../../assets/img/teethStyles2/lib15.png";
import lib16_png from "../../assets/img/teethStyles2/lib16.png";
import lib17_png from "../../assets/img/teethStyles2/lib17.png";
import lib18_png from "../../assets/img/teethStyles2/lib18.png";
import lib19_png from "../../assets/img/teethStyles2/lib19.png";
import lib20_png from "../../assets/img/teethStyles2/lib20.png";
import lib21_png from "../../assets/img/teethStyles2/lib21.png";
import lib22_png from "../../assets/img/teethStyles2/lib22.png";
import lib23_png from "../../assets/img/teethStyles2/lib23.png";
import lib24_png from "../../assets/img/teethStyles2/lib24.png";
import lib25_png from "../../assets/img/teethStyles2/lib25.png";
import lib26_png from "../../assets/img/teethStyles2/lib26.png";
import lib27_png from "../../assets/img/teethStyles2/lib27.png";
import lib28_png from "../../assets/img/teethStyles2/lib28.png";
import lib29_png from "../../assets/img/teethStyles2/lib29.png";
import lib30_png from "../../assets/img/teethStyles2/lib30.png";

const toothStyles = [
  {
    pic1: lib1,
    pic2: lib1_png,
    style: "Style 0.0",
    shape: "D",
    abrasion: 1,
  },
  {
    pic1: lib2,
    pic2: lib2_png,
    style: "Style 0.1",
    shape: "G",
    abrasion: 1,
  },
  {
    pic1: lib3,
    pic2: lib3_png,
    style: "Style 0.2",
    shape: "P",
    abrasion: 1,
  },
  {
    pic1: lib4,
    pic2: lib4_png,
    style: "Style 0.3",
    shape: "S",
    abrasion: 1,
  },
  {
    pic1: lib5,
    pic2: lib5_png,
    style: "Style 0.4",
    shape: "U",
    abrasion: 1,
  },
  {
    pic1: lib6,
    pic2: lib6_png,
    style: "Style 1.0",
    shape: "V",
    abrasion: 1,
  },
  {
    pic1: lib7,
    pic2: lib7_png,
    style: "Style 1.1",
    shape: "O",
    abrasion: 1,
  },
  {
    pic1: lib8,
    pic2: lib8_png,
    style: "Style 1.2",
    shape: "T",
    abrasion: 1,
  },
  {
    pic1: lib9,
    pic2: lib9_png,
    style: "Style 1.3",
    shape: "D",
    abrasion: 2,
  },
  {
    pic1: lib10,
    pic2: lib10_png,
    style: "Style 1.4",
    shape: "G",
    abrasion: 2,
  },
  {
    pic1: lib111,
    pic2: lib11_png,
    style: "Style 2.0",
    shape: "O",
    abrasion: 2,
  },
  {
    pic1: lib12,
    pic2: lib12_png,
    style: "Style 2.1",
    shape: "P",
    abrasion: 2,
  },
  {
    pic1: lib13,
    pic2: lib13_png,
    style: "Style 2.2",
    shape: "S",
    abrasion: 2,
  },
  {
    pic1: lib14,
    pic2: lib14_png,
    style: "Style 3.0",
    shape: "T",
    abrasion: 2,
  },
  {
    pic1: lib15,
    pic2: lib15_png,
    style: "Style 3.1",
    shape: "U",
    abrasion: 2,
  },
  {
    pic1: lib16,
    pic2: lib16_png,
    style: "Style 3.2",
    shape: "V",
    abrasion: 2,
  },
  {
    pic1: lib17,
    pic2: lib17_png,
    style: "Style 3.3",
    shape: "D",
    abrasion: 3,
  },
  {
    pic1: lib18,
    pic2: lib18_png,
    style: "Style 4.0",
    shape: "G",
    abrasion: 3,
  },
  {
    pic1: lib19,
    pic2: lib19_png,
    style: "Style 4.1",
    shape: "O",
    abrasion: 3,
  },
  {
    pic1: lib20,
    pic2: lib20_png,
    style: "Style 4.2",
    shape: "P",
    abrasion: 3,
  },
  {
    pic1: lib21,
    pic2: lib21_png,
    style: "Style 5.0",
    shape: "S",
    abrasion: 3,
  },
  {
    pic1: lib222,
    pic2: lib22_png,
    style: "Style 5.1",
    shape: "T",
    abrasion: 3,
  },
  {
    pic1: lib23,
    pic2: lib23_png,
    style: "Style 5.2",
    shape: "U",
    abrasion: 3,
  },
  {
    pic1: lib24,
    pic2: lib24_png,
    style: "Style 5.3",
    shape: "V",
    abrasion: 3,
  },
  {
    pic1: lib25,
    pic2: lib25_png,
    style: "Style 5.4",
    shape: "V",
    abrasion: 4,
  },
  {
    pic1: lib26,
    pic2: lib26_png,
    style: "Style 6.0",
    shape: "V",
    abrasion: 4,
  },
  {
    pic1: lib27,
    pic2: lib27_png,
    style: "Style 6.1",
    shape: "V",
    abrasion: 4,
  },
  {
    pic1: lib28,
    pic2: lib28_png,
    style: "Style 6.2",
    shape: "V",
    abrasion: 4,
  },
  {
    pic1: lib29,
    pic2: lib29_png,
    style: "Style 6.3",
    shape: "V",
    abrasion: 4,
  },
  {
    pic1: lib30,
    pic2: lib30_png,
    style: "Style 6.4",
    shape: "V",
    abrasion: 4,
  },
];

class ToothStyles extends PureComponent {
  render() {
    const { t: _t } = this.props;
    return (
      <div className="toothStyles">
        {toothStyles.map(({ pic1, pic2, style, shape, abrasion }) => (
          <div className="item" key={style}>
            <div className="main">
              <img src={pic1} alt={style} />
            </div>
            <div className="details">
              <h5>
                {_t("Style ")}
                {style}
              </h5>
              <div className="pic">
                <img src={pic2} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

ToothStyles.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ToothStyles);
