import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import scrollToTop from "../../../modules/scrollToTop";
import toothStylesLogo from "../../../assets/img/toothStylesLogo.jpg";
import ArrowBack from "../ArrowBack";
import "./index.scss";

import ToothStyles from "../../ToothStyles";

class Library extends PureComponent {
  componentDidMount() {
    scrollToTop();
  }
  render() {
    const { t: _t } = this.props;

    return (
      <div className="library">
        <header className="header">
          <Link className="logo" to="/">
            <span className="dark">Mesh</span>
            <span className="pink">Align</span>
          </Link>
        </header>
        <section className="section">
          <ArrowBack text={_t("TO THE LIBRARIES")} path="/libraryes" />
          <h1>{_t("Teeth styles Expression")}</h1>
          <div className="content">
            <p>
              {_t(
                "The EXPRESSION dental library was developed by MeshAlign to create mockups and smile designs. This library is presented in the form of 2D templates and 3D models of teeth, which allows to accurately repeat smile design in the shape of the future teeth. Using libraries improves 3D teeth design and makes restoration results more predictable. Choose a style for the future shape of the teeth.",
              )}
            </p>
            <div className="logo">
              <div className="pic">
                <img src={toothStylesLogo} alt={_t("EXPRESSION teeth library by Pogarskiy Design ")} />
              </div>
              <h2>by Pogarskiy Design</h2>
            </div>
          </div>
          <ToothStyles />
        </section>
      </div>
    );
  }
}

Library.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Library);
