import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import scrollToTop from "../../../../modules/scrollToTop";
import ArrowBack from "../../ArrowBack";
import "./index.scss";

const ElectronicContractUa = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="classification">
      <div className="overlay" />
      <header className="header">
        <Link className="logo" to="/">
          <span className="dark">Mesh</span>
          <span className="pink">Align</span>
        </Link>
      </header>
      <section>
        <ArrowBack path="/" text="Назад на главную страницу" />
        <h2>ЕЛЕКТРОННЕ ПОВІДОМЛЕННЯ</h2>
        <p className="font-bold text-center">перед укладенням договору</p>

        <p className="font-bold">ШАНОВНИЙ ЗАМОВНИК!</p>

        <p className="f-18">
          БУДЬ ЛАСКА, УВАЖНО ОЗНАЙОМТЕСЯ ІЗ УМОВАМИ ЕЛЕКТРОННОГО ДОГОВОРУ НА ВИКОНАННЯ РОБІТ, ТЕКСТ ЯКОГО НАВЕДЕНО НИЖЧЕ
          (далі по тексту – «Договір»).
        </p>
        <p className="f-18">
          ЗВЕРАТЄМО ВАШУ УВАГУ, ЩО ДОГОВІР Є ПУБЛІЧНОЮ ОФЕРТОЮ І УКЛАДАЄТЬСЯ ВІДПОВІДНО ДО СТАТЕЙ 205, 633, 634, 638,
          639, 641, 642, 647 ЦИВІЛЬНОГО КОДЕКСУ УКРАЇНИ, А ТАКОЖ ВІДПОВІДНО ДО ЗАКОНІВ УКРАЇНИ «ПРО ЕЛЕКТРОННУ
          КОМЕРЦІЮ», ТА «ПРО АВТОРСЬКЕ ПРАВО І СУМІЖНІ ПРАВА».
        </p>
        <p className="f-18">ДАНЕ ЕЛЕКТРОННЕ ПОВІДОМЛЕННЯ Є НЕВІД’ЄМНОЮ ЧАСТИНОЮ ДОГОВОРУ.</p>

        <p className="font-bold">ПЕРЕД УКЛАДАННЯМ ДОГОВОРУ, ВАМ НАДАЄТЬСЯ НАСТУПНА ІНФОРМАЦІЯ:</p>

        <p>
          Найменування суб'єкта господарювання: фізична особа-підприємець Погарський Антон Юрійович (дата та номер
          запису в Єдиному державному реєстрі юридичних осіб, фізичних осіб-підприємців та громадських формувань:
          28.03.2018 року, № 21030000000096301).
        </p>
        <p>
          Місцезнаходження суб'єкта господарювання: 69600, Україна, Запорізька область, місто Запоріжжя, Комунарський
          район, вул. Реконструктивна, будинок 3.
        </p>
        <p>Адреса електронної пошти: meshalign@gmail.com.</p>
        <p>
          Порядок прийняття претензій: претензії приймаються в письмовому вигляді, викладені українською, англійською
          або російською мовами, шляхом їх направлення на наступну адресу електронної пошти: meshalign@gmail.com.
          Претензія розглядається протягом одного календарного місяця з моменту її надходження.
        </p>
        <p>Основні характеристики продукції: наведені за адресою https://meshalign.com/companies-list.</p>
        <p>Відомості про ліцензії у сфері господарської діяльності: ліцензуванню не підлягає.</p>
        <p>Ціни: наведені за адресою: https://meshalign.com/companies-list.</p>
        <p>Умови оплати: визначені в розділі «4» Договору.</p>
        <p>Включення податків у розрахунок вартості роботи: відсутні.</p>
        <p>
          Гарантійні зобов'язання: будь-які гарантії або гарантійні зобов’язання не надаються та не встановлюються,
          окрім тих, що визначені національним законодавством України, приписами міжнародного права та національного
          законодавства країни Замовника, як імперативні.
        </p>
        <p>Умови виконання Договору: визначені в розділі «5» Договору.</p>
        <p>
          Мінімальна тривалість Договору: триває з моменту його укладання і до моменту припинення на визначених в
          Договорі умовах.
        </p>
        <p>Період прийняття пропозицій: необмежений.</p>
        <p>Порядок розірвання Договору: визначений в розділі «10» Договору.</p>

        <p className="font-bold text-center">ДОГОВІР</p>
        <p>на виконання робіт</p>

        <p>Редакція від: 27.04.2020 року.</p>

        <p>
          Цей документ являє собою публічну пропозицію (оферту) укласти електронний Договір на виконання робіт (далі по
          тексту – «Договір»), яка адресується будь-якій фізичній особі (в тому числі, але не виключно – громадянину
          України, іноземцю, чи особі без громадянства), та яку Ви можете прийняти (акцептувати) на визначених нижче
          умовах.
        </p>

        <p>В разі прийняття (акцепту) Вами даного Договору, він укладається між:</p>

        <p>
          ФІЗИЧНОЮ ОСОБОЮ-ПІДПРИЄМЦЕМ ПОГАРСЬКИМ АНТОНОМ ЮРІЙОВИЧЕМ (далі по тексту – «Виконавець»), що діє на підставі
          запису в Єдиному державному реєстрі юридичних осіб та фізичних осіб-підприємців № 21030000000096301 від
          28.03.2018 року, місце проживання: 69600, Україна, Запорізька область, м. Запоріжжя, вулиця Реконструктивна,
          будинок 3, який публікує пропозицію укласти даний Договір, та
        </p>

        <p>ВАМИ – фізичною особою, яка акцептує оферту (далі по тексту – «Замовник»),</p>

        <p>що разом далі по тексту іменовані як «Сторони», а окремо – «Сторона».</p>

        <p>
          Вищевикладеним Виконавець виражає намір вважати себе зобов’язаним у разі прийняття Замовником пропозиції
          укласти Договір.
        </p>

        <p>
          Датою та часом (моментом) укладання даного Договору є момент вчинення Замовником дії, яка згідно із умовами
          Договору вважається повним та безумовним прийняттям пропозиції (оферти) Виконавця укласти його (пункти 3.1. та
          3.2. Договору).
        </p>

        <p>
          Місцем укладання даного Договору є місце фактичного проживання Виконавця, зазначене в даному Договорі серед
          інших контактних даних останнього.
        </p>

        <p>1. ОСНОВНІ ТЕРМІНИ ТА ВИЗНАЧЕННЯ.</p>

        <p>
          1.1. «Авторизація» – електронний процес входу Замовника до його Акаунту, під час якого відбувається перевірка
          наявності прав останнього на надання йому відповідного доступу до такого Акаунту. Для Авторизації Замовник
          повинен ввести у відповідну електронну форму свої Ідентифікатори, та підтвердити намір здійснити вхід до
          Акаунту.
        </p>
        <p>
          1.2. «Акаунт» – сукупність електронних відомостей щодо Замовника, які останній розміщує, завантажує чи вводить
          в інтернет-сервісі «Mesh Align», і які включають в себе Обліковий запис, Профіль, та Особистий кабінет.
          Складовою частиною Акаунту також є Контент. Для використання інтернет-сервісу «Mesh Align» та можливості
          здійснювати замовлення виконання робіт – потрібен Акаунт.
        </p>
        <p>
          1.3. «Акцепт» – повне та безумовне прийняття Замовником пропозиції (оферти) Виконавця укласти даний Договір на
          визначених ним умовах (пункти 3.1. та 3.2. Договору).
        </p>
        <p>1.4. «Ідентифікатори» – унікальні Логін та Пароль, які створюються особисто Замовником.</p>
        <p>
          1.5. «Інтернет» – глобальна інформаційна мережа загального доступу, яка базується на Інтернет-протоколах,
          визначених міжнародними стандартами.
        </p>
        <p>
          1.6. «Контент» – текстові (письмові) або відео матеріали, аудіо записи чи зображення, а також будь-які інші
          матеріали чи інформація, що розміщуються, завантажуються чи вводяться Замовником у будь-які розділи
          інтернет-сервісу «Mesh Align». Для цілей даного Договору, Контентом, в тому числі, але не виключно, також є
          відповідні електронні текстові повідомлення, електронні медичні картки пацієнтів, будь-які завантажені до
          інтернет-сервісу «Mesh Align» електронні комп’ютерні файли будь-якого формату, будь-які електронні цифрові
          зображення, будь-яке електронне цифрове відео, будь-яка інформація, та будь-які персональні дані будь-яких
          осіб. Контент є складовою частиною Акаунту.
        </p>
        <p>
          1.7. «Логін» – унікальний набір символів, що ідентифікує Замовника в інтернет-сервісі «Mesh Align», виражений
          у вигляді адреси електронної пошти, що знаходиться у безперешкодному та особистому використанні Замовника.
          Логін є елементом Облікового запису. Виконавець має право заборонити використання певних Логінів, а також
          встановлювати щодо них вимоги (довжина, допустимі символи, та ін.).
        </p>
        <p>
          1.8. «Обліковий запис» – сукупність розміщеної, завантаженої чи введеної до інтернет-сервісу «Mesh Align»
          інформації про Замовника.
        </p>
        <p>
          1.9. «Особистий кабінет» – розділ інтернет-сервісу «Mesh Align», доступ до якого має тільки зареєстрований
          Замовник після проходження електронної процедури Авторизації за допомогою Ідентифікаторів, та через який
          Замовник здійснює управляння Обліковим записом.
        </p>
        <p>
          1.10. «Пароль» – таємне слово або певна послідовність символів, призначена для підтвердження особи Замовника
          та його прав. Пароль використовується для захисту інформації, наявної у інтернет-сервісі «Mesh Align» від
          несанкціонованого доступу будь-яких сторонніх осіб. Пароль є елементом Облікового запису, та використовуються
          інтернет-сервісом «Mesh Align» для надання Замовнику дозволу на вхід до його Акаунту. Виконавець має право
          заборонити використання певних Паролів, а також встановлювати щодо них вимоги (довжина, допустимі символи, та
          ін.).
        </p>
        <p>
          1.11. «Письмове повідомлення» – електронний лист, що може містити в собі текстові (письмові) або відео
          матеріали, аудіо записи чи зображення, надісланий будь-якою Стороною даного Договору одна одній на відповідну
          адресу електронної пошти. Для цілей даного Договору, адресою електронної пошти Замовника є адреса, зазначена
          останнім в якості його Логіну, а адресою електронної пошти Виконавця є адреса, зазначена в даному Договорі
          серед інших контактних даних останнього. Письмове повідомлення може бути викладене українською, англійською
          або російською мовами.
        </p>
        <p>
          1.12. «Профіль» – сукупність персональних відомостей про Замовника, вказаних останнім при Реєстрації в
          інтернет-сервісі «Mesh Align» шляхом заповнення відповідної реєстраційної форми.
        </p>
        <p>
          1.13. Інтернет-сервіс «Mesh Align» – веб-сайт, розміщений в мережі Інтернет за адресою https://meshalign.com,
          за допомогою якого Замовник має можливість замовляти у Виконавця виконання робіт за даним Договором.
        </p>
        <p>
          1.14. «Реєстрація» – електронна процедура, під час якої Замовник заповнює відповідну реєстраційну форму, та
          створює у інтернет-сервісі «Mesh Align» Акаунт. Для цілей даного Договору, Реєстрація є дією Замовника, яка
          вважається повним та безумовним прийняттям пропозиції (оферти) Виконавця укласти даний Договір.
        </p>

        <p>2. ПРЕДМЕТ ДОГОВОРУ.</p>

        <p>
          2.1. В порядку та на умовах, визначених даним Договором, Виконавець зобов’язується в залежності від вибору
          Замовника виконувати для нього наступні роботи (далі по тексту – «Роботи»):
        </p>
        <p>- розробка цифрових планів ортодонтичного лікування зубів людини;</p>
        <p>
          - формування та підготовка для друку цифрових комп’ютерних файлів формату «STL», які містять в собі тривимірне
          зображення верхніх та/або нижніх зубів людини, виключно на підставі раніше розробленого Виконавцем цифрового
          плану ортодонтичного лікування;
        </p>
        <p>- створення цифрових естетичних мокапів;</p>
        <p>- підготовка цифрових дизайнів посмішки.</p>
        <p>Замовник, в свою чергу, зобов’язується оплачувати Виконавцю вартість Роботи та приймати її результат.</p>
        <p>
          2.2. Кожна окрема Робота виконуються на підставі окремого електронного замовлення, яке Замовник самостійно
          формує за допомогою відповідної електронної форми на інтернет-сервісі «Mesh Align». Кожне окреме електронне
          замовлення, яке набуло юридичної сили відповідно із приписами п. 4.1. даного Договору, є його невід’ємною
          частиною, та виконує роль відповідної специфікації.
        </p>
        <p>
          2.3. Даний Договір є договором приєднання та, згідно із ст. 634 Цивільного кодексу України, його умови
          встановлені Виконавцем. Від так, Замовник не може пропонувати свої умови щодо даного Договору.
        </p>
        <p>
          2.4. До складу даного Договору входять наступні окремо викладені електронні документи, які є його невід’ємною
          частиною:
        </p>
        <p>- Умови використання, наведені за адресою: https://meshalign.com/terms-of-use;</p>
        <p>- Політика конфіденційності, наведена за адресою: https://meshalign.com/policy.</p>

        <p>3. ПОРЯДОК УКЛАДЕННЯ ДОГОВОРУ.</p>

        <p>
          3.1. Даний Договір укладається шляхом розміщеної в мережі Інтернет пропозиції її укласти (оферти) з боку
          Виконавця, та Акцепту такої пропозиції з боку Замовника.
        </p>
        <p>
          3.2. Замовник надає свою відповідь про Акцепт пропозиції (оферти) Виконавця укласти даний Договір шляхом
          Реєстрації в інтернет-сервісі «Mesh Align», під час проходження якої Замовник підтверджує, що ознайомлений з
          умовами даного Договору, Умовами використання та Політикою конфіденційності.
        </p>
        <p>3.3. З моменту Реєстрації Замовника в інтернет-сервісі «Mesh Align» – даний Договір вважається укладеним.</p>

        <p>4. ВАРТІСТЬ РОБОТИ ТА ПОРЯДОК ЇЇ ОПЛАТИ.</p>

        <p>
          4.1. Кожного разу, коли Замовник формує нове окреме електронне замовлення, у відповідній електронній формі
          інтернет-сервісу «Mesh Align» визначається остаточна вартість Роботи, яку останній бажає замовити. Якщо
          визначна вартість Роботи влаштовує Замовника, він здійснює відповідну оплату, після чого окреме електронне
          замовлення набуває юридичної сили для Сторін. Поточна вартість кожної окремої Роботи – завжди визначена у
          відповідному розділі інтернет-сервісу «Mesh Align», із чим Замовник може попередньо ознайомитись після
          Реєстрації. Під час розрахунку остаточної вартості кожної окремої Роботи, Виконавець за своїм бажанням та на
          свій розсуд має право застосовувати та пропонувати певні знижки, про що Замовника буде проінформовано за
          допомогою інтерфейсу інтернет-сервісу «Mesh Align».
        </p>
        <p>
          4.2. Остаточна вартість Роботи, яку Замовник бажає замовити у Виконавця за даним Договором може бути
          визначена, крім іншого, у вигляді грошового еквіваленту в іноземній валюті, обраній в односторонньому порядку
          Виконавцем на його розсуд. При оплаті Замовником вартості Роботи, кінцева сума такого платежу автоматично
          визначається за поточним валютним курсом між валютою вартості і валютою платежу, що встановлений відповідною
          платіжною системою та/або кореспондуючими банками станом на день здійснення оплати. Здійснюючи оплату вартості
          Роботи, Замовник усвідомлює та погоджується із тим, що кінцева сума платежу у валюті оплати може змінюватися
          відповідно до поточного валютного курсу.
        </p>
        <p>
          4.3. Виконавець має право у будь-який час, в односторонньому порядку, без попередження Замовника та без
          попереднього погодження із ним змінювати вартість Робіт. При цьому, вартість Роботи, яка вже Замовником
          оплачена повністю або частково – зміні не підлягає.
        </p>
        <p>4.4. Оплата вартості Роботи здійснюються Замовником в наступному порядку:</p>
        <p>
          - розробка цифрових планів ортодонтичного лікування зубів людини – попередня оплата в розмірі 100%-ї вартості
          Роботи;
        </p>
        <p>
          - формування та підготовка для друку цифрових комп’ютерних файлів формату «STL», які містять в собі тривимірне
          зображення верхніх та/або нижніх зубів людини, виключно на підставі раніше розробленого Виконавцем цифрового
          плану ортодонтичного лікування – попередня оплата в розмірі 100%-ї вартості Роботи;
        </p>
        <p>
          - створення цифрових естетичних мокапів – попередня оплата в розмірі 50%-ї вартості Роботи, та остаточна
          оплата 50% вартості під час передачі результату Роботи від Виконавця до Замовника;
        </p>
        <p>
          - підготовка цифрових дизайнів посмішки – попередня оплата в розмірі 50%-ї вартості Роботи, та остаточна
          оплата 50% вартості під час передачі результату Роботи від Виконавця до Замовника.
        </p>
        <p>
          4.5. Оплата вартості Роботи здійснюється Замовником шляхом перерахування відповідної суми грошових коштів на
          розрахунковий рахунок Виконавця, за допомогою використання міжнародних банківських платіжних карт через
          відповідну платіжну систему інтернет-еквайрингу, обрану в односторонньому порядку та на розсуд Виконавця.
          Одразу після здійснення оплати вартості Роботи, Замовник отримує відповідну електронну квитанцію.
        </p>
        <p>
          4.6. Укладаючи даний Договір, Замовник підтверджує, що він обізнаний відносно наступного: Виконавець не
          вимагає від Замовника надання будь-яких відомостей про його платіжні дані та інструменти, банківські рахунки,
          платіжні картки, а також будь-яких інших відомостей, які необхідні для оплати грошових коштів. Виконавець в
          жодному разі не несе відповідальності за збереження чи протиправне використання вказаних вище та подібних
          відомостей, навіть якщо такі відомості були отримані Виконавцем випадково або через навмисні чи помилкові дії
          Замовника чи інших осіб. Відповідальність за збереження та використання відомостей, що дають змогу забезпечити
          проведення Замовником оплати – несе оператор відповідної платіжної системи.
        </p>
        <p>4.7. Використання інтернет-сервісу «Mesh Align» – є безкоштовним.</p>

        <p>5. ПОРЯДОК, СТРОКИ ТА УМОВИ ВИКОНАННЯ РОБОТИ.</p>

        <p>
          5.1. Виконавець виконує Роботу власними силами та на власному обладнанні. За необхідності, на розсуд Виконавця
          та за його бажанням, без попередньої згоди Замовника та не вступаючи у протиріччя з умовами даного Договору,
          Виконавець має право у будь-який момент передати виконання Роботи будь-якій іншій особі, залишаючись при цьому
          відповідальним в повному обсязі перед Замовником за результат такої Роботи.
        </p>
        <p>
          5.2. Кожна окрема Робота виконується Виконавцем протягом 5-ти робочих днів, з моменту сукупного настання
          наступних обставин:
        </p>
        <p>
          - здійснення Замовником попередньої оплати вартості Роботи, у визначених приписами п. 4.4. даного Договору
          розмірах;
        </p>
        <p>
          - надання з боку Замовника повного об’єму необхідних вихідних даних, які обов’язково повинні відповідати
          визначеним критеріям, згідно із приписами п. 5.3. даного Договору.
        </p>
        <p>
          Якщо Замовник здійснив попередню оплату вартості Роботи, але не надав усіх вихідних даних, або надав вихідні
          дані що не відповідають визначеним критеріям – зобов’язання Виконавця щодо виконання відповідної Роботи не
          виникає, а визначений даним пунктом Договору строк для її виконання свій перебіг не розпочинає.
        </p>
        <p>
          5.3. Виконавець приступає до виконання Роботи виключно за умови її оплати та надання з боку Замовника повного
          об’єму необхідних вихідних даних, які обов’язково повинні відповідати визначеним Виконавцем критеріям. Перелік
          необхідних вихідних даних для кожної окремої Роботи, а також критерії, яким вони повинні відповідати –
          наведено за адресою https://meshalign.com/companies-list. Якщо з боку Замовника не надано необхідного об’єму
          вихідних даних, або вони не відповідають визначеним критеріям, Виконавець повідомляє про це Замовника за
          допомогою інтерфейсу інтернет-сервісу «Mesh Align», або шляхом відправлення відповідного Письмового
          повідомлення.
        </p>
        <p>5.4. Розробка цифрових планів ортодонтичного лікування зубів людини.</p>
        <p>
          Даний вид Роботи полягає в розробці цифрового плану ортодонтичного лікування, що передбачає поетапне і
          дозоване пересування зубів певної людини. В результаті, Замовнику передається цифровий проект, який включає в
          себе:
        </p>
        <p>- зображення моделей в оклюзії до лікування і після лікування з п'яти різних ракурсів;</p>
        <p>- анімовані в п'яти різних ракурсах 3D-моделі верхньої та нижньої щелепи до і після лікування;</p>
        <p>- карту пересування зубів;</p>
        <p>- рекомендації по наявності, розташуванню і розмірам сепарацій;</p>
        <p>- рекомендації по наявності і формі аттачментів.</p>
        <p>
          За необхідності, Замовник може запросити у Виконавця безкоштовну корекцію розробленого плану ортодонтичного
          лікування, але не більше 3-х разів.
        </p>
        <p>
          Укладаючи даний Договір, Замовник усвідомлює та погоджується із наступним: Виконавець не має практичної
          можливості контролювати процес виготовлення системи елайнерів, а так само впливати на ряд інших факторів під
          час ортодонтичного лікування людини певним лікарем (фіксація активаторів, виконання сепарацій, ретенування
          зубів та ін.). З цих причин, Виконавець не несе відповідальності за якість фіксації елайнерів в ротовій
          порожнині людини, як і за кінцевий результат її ортодонтичного лікування.
        </p>
        <p>
          Виконавець зобов’язується досягти при виконанні Роботи з розробки цифрових планів ортодонтичного лікування
          наступних вимог до її результату:
        </p>
        <p>- нормалізація форми і розмірів зубних рядів;</p>
        <p>- корекція росту і розвитку щелеп;</p>
        <p>- нормалізація оклюзії (змикання зубів верхньої і нижньої щелеп).</p>
        <p>5.5. Створення цифрових естетичних мокапів.</p>
        <p>
          Будучи точним просторовим відображенням майбутньої форми передніх зубів верхньої щелепи, естетичний мокап має
          на увазі її відновлення з урахуванням оклюзійних контактів, не беручи до уваги артикуляційний рух нижньої
          щелепи. За результатами даної Роботи, Замовнику надається:
        </p>
        <p>
          - цифровий комп’ютерний файл формату «STL», який містить в собі тривимірне зображення верхньої щелепи із
          змодельованими верхніми передніми зубами.
        </p>
        <p>
          Роботи зі створення повного функціонального мокапу, який передбачає реставрацію всіх зубів верхньої і нижньої
          щелепи – Виконавцем не виконуються.
        </p>
        <p>
          Під час формування електронного замовлення, Замовник має можливість зазначити додаткові вимоги та/або
          побажання до кінцевого результату Роботи. Виконавець зобов’язується досягти при виконанні Роботи зі створення
          цифрових естетичних мокапів наступних вимог до її результату:
        </p>
        <p>- нормалізація форми та розміру зубів згідно із вимогами та побажаннями Замовника.</p>
        <p>5.6. Підготовка цифрових дизайнів посмішки.</p>
        <p>
          Дизайн посмішки – це візуальний план майбутньої форми зубів після проведення естетичної реабілітації,
          виконаний у вигляді декількох зображень, що містять в собі:
        </p>
        <p>- розмітку обличчя з контурами зубів, накладеними поверх вихідного фото людини;</p>
        <p>- контури зубів і зображення майбутніх зубів, накладених на вихідне фото;</p>
        <p>- фото-реалістичний колаж із зображенням обличчя людини і дизайну майбутніх зубів.</p>
        <p>
          Під час формування електронного замовлення, Замовник має можливість зазначити додаткові вимоги та/або
          побажання до кінцевого результату Роботи. Виконавець зобов’язується досягти при виконанні Роботи з підготовки
          цифрових дизайнів посмішки наступних вимог до її результату:
        </p>
        <p>- нормалізація форми та розміру зубів згідно із вимогами та побажаннями Замовника.</p>

        <p>6. ПОРЯДОК ПЕРЕДАЧІ РЕЗУЛЬТАТУ РОБОТИ.</p>

        <p>
          6.1. Про закінчення виконання Роботи Виконавець повідомляє Замовника шляхом направлення останньому
          відповідного Письмового повідомлення.
        </p>
        <p>
          6.2. Результати виконаної Роботи передаються Виконавцем до Замовника за допомогою інтерфейсу інтернет-сервісу
          «Mesh Align», в наступному порядку:
        </p>
        <p>
          - Виконавець завантажує до відповідного розділу інтернет-сервісу «Mesh Align» результати виконаної Роботи у
          цифровому вигляді, та повідомляє про це Замовника;
        </p>
        <p>
          - Замовник зберігає результати виконаної Роботи на будь-якому пристрої для зберігання даних, та оглядає їх
          щодо відповідності визначеним у пп. 5.4., 5.5. та 5.6. даного Договору вимогам.
        </p>
        <p>
          6.3. З моменту завантаження Виконавцем до відповідного розділу інтернет-сервісу «Mesh Align» результатів
          виконаної Роботи, та направлення Замовнику відповідного Письмового повідомлення про це – зобов’язання
          Виконавця щодо передачі Замовнику результату Роботи вважаються виконаними у повному обсязі.
        </p>

        <p>7. КЕРУЮЧЕ ЗАКОНОДАВСТВО ТА АРБІТРАЖ. ПОЗОВНА ДАВНІСТЬ.</p>

        <p>
          7.1. Сторони погоджують, що даний Договір в цілому та пов’язані із ним правовідносини між Замовником і
          виконавцем регулюються, тлумачаться і виконуються переважно у відповідності до національного законодавства
          (матеріального права) України. В окремих випадках, коли це є обов’язковим та/або неминучим, Сторони можуть
          керуватися приписами міжнародного права та/або національного законодавства країни Замовника.
        </p>
        <p>
          7.2. Сторони встановили наступне: усі спори, розбіжності чи вимоги, які виникають із даного Договору або у
          зв’язку із ним, у тому числі щодо його укладення, тлумачення, виконання, порушення, припинення чи недійсності
          (далі по тексту – «Спір»), підлягають вирішенню відповідним місцевим судом України загальної юрисдикції з
          урахуванням підсудності згідно із матеріальним та процесуальним правом України.
        </p>
        <p>
          7.3. У разі, коли це є обов’язковим та/або неминучим, Спір може бути передано на розгляд для остаточного
          вирішення до Лондонського міжнародного арбітражного суду згідно із його Регламентом, який є частиною даного
          застереження. В даному випадку Сторони визначають наступне:
        </p>
        <p>- місцем проведення арбітражного розгляду буде місто Мінськ, Республіки Білорусь;</p>
        <p>- арбітражний суд буде складатися з трьох арбітрів;</p>
        <p>- мовою арбітражного розгляду буде Російська;</p>
        <p>- арбітражний розгляд буде проводитись згідно із матеріальним правом України.</p>
        <p>
          Склад арбітрів буде призначатися в наступному порядку: одного арбітра обирає Замовник, другого – Виконавець,
          третього – секретаріат Лондонського міжнародного арбітражного суду.
        </p>
        <p>
          7.4. Сторони домовились, що в першу чергу в обов’язковому порядку будуть намагатися вирішити будь-який Спір
          шляхом до судових (до арбітражних) переговорів, щонайменше протягом 30 календарних днів з моменту отримання
          однією Стороною від іншої відповідного Письмового повідомлення, в якому повинно бути зазначено:
        </p>
        <p>- повне ім’я (найменування) та контактні дані Сторони, яка ініціює переговори;</p>
        <p>- суть претензії чи Спору;</p>
        <p>- чітке та зрозуміле формулювання відповідної вимоги.</p>
        <p>
          Додержання визначеного вище порядку до судових (до арбітражних) переговорів – є обов’язковою умовою для
          вирішення будь-яких Спорів між Сторонами. Недодержання цієї умови є підставою для відмови у відкритті
          відповідної судової (арбітражної) справи, чи припинення вже відкритої.
        </p>
        <p>
          7.5. Укладаючи даний Договір, Замовник відмовляється від права на розгляд Спорів між Сторонами у суді
          присяжних та/або від своєї участі у колективних позовах, а також погоджується із тим, що він може пред’являти
          Виконавцю будь-які претензії, вимоги та/або позови виключно в індивідуальному порядку, а не в якості позивача
          чи представника групи осіб в будь-якому представницькому або колективному розгляді.
        </p>
        <p>
          7.6. Строк позовної давності, у межах якого Замовник може звернутися до суду з вимогою до Виконавця про захист
          свого права або інтересу – становить один рік, починаючи від дня, коли Замовник довідався або міг довідатися
          про порушення свого права.
        </p>

        <p>8. ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ.</p>

        <p>
          8.1. Сторони звільняється від будь-якої відповідальності за повне чи часткове невиконання визначених даним
          Договором зобов’язань, якщо це пов’язано із виникненням обставин непереборної сили, тобто надзвичайних та
          невідворотних обставин, які об'єктивно впливають на виконання зобов'язань, передбачених умовами Договору,
          обов'язків за законодавчими і іншими нормативними актами, виникнення та/або дію яких неможливо було
          передбачити та дія яких унеможливлює виконання зобов’язань протягом певного періоду часу. У разі виникнення
          таких обставин Сторони повинні вжити усіх максимально можливих заходів, направлених на уникнення або зменшення
          впливу обставин непереборної сили на виконання відповідних зобов’язань за Договором.
        </p>
        <p>
          8.2. Під обставинами непереборної сили розуміються будь-які надзвичайні та невідворотні обставини зовнішнього,
          щодо Сторін, характеру, які виникають без їх вини, поза або всупереч їх волі чи бажання, і які не можна за
          умови вжиття звичайних для цього заходів передбачити і не можна при всій турботливості та обачності відвернути
          (уникнути), в тому числі, але не виключно:
        </p>
        <p>
          - стихійні явища природного характеру (землетруси, повені, урагани, руйнування в результаті блискавки, тощо);
        </p>
        <p>
          - лиха біологічного, техногенного та антропогенного походження (вибухи, пожежі, вихід з ладу машин й
          обладнання, масові епідемії, епізоотії, епіфітотії тощо);
        </p>
        <p>
          - обставини суспільного життя (війна, воєнні дії, блокади, громадські хвилювання, прояви тероризму, масові
          страйки та локаути, бойкоти, тощо);
        </p>
        <p>
          - видання заборонних чи обмежуючих нормативних актів органами державної влади або місцевого самоврядування;
        </p>
        <p>
          - будь-які законні або незаконні заборонні чи обмежуючі заходи органів державної влади, які унеможливлюють або
          тимчасово перешкоджають виконанню Сторонами своїх зобов’язань.
        </p>
        <p>
          8.3. Не вважаються обставинами непереборної сили недодержання своїх обов'язків контрагентом тієї Сторони, що
          не виконала зобов’язання, чи відсутність у такої Сторони необхідних коштів.
        </p>
        <p>
          8.4. Виникнення обставин непереборної сили має бути засвідчено відповідною торгово-промисловою палатою або
          іншим уповноваженим державним органом країни, на території якої такі обставини виникли.
        </p>
        <p>
          8.5. Сторона, що має намір послатися на виникнення обставин непереборної сили, зобов'язана невідкладно, із
          урахуванням можливостей технічних засобів миттєвого зв'язку та характеру існуючих перешкод, повідомити іншу
          Сторону про наявність таких обставин та про характер їх впливу на виконання зобов’язань за даним Договором.
        </p>
        <p>
          8.6. Якщо обставини непереборної сили та їх наслідки тимчасово перешкоджають виконанню зобов’язань за даним
          Договором, то їх виконання зупиняється на строк, протягом якого це є неможливим.
        </p>
        <p>
          8.7. Якщо у зв'язку із обставинами непереборної сили та їх наслідками, за які жодна із Сторін не відповідає,
          виконання зобов’язань за даним Договором є остаточно неможливим, то Договір вважається припиненим з моменту
          виникнення неможливості такого виконання.
        </p>
        <p>
          8.8. Якщо у зв'язку із обставинами непереборної сили та їх наслідками виконання зобов’язань за даним Договором
          є тимчасово неможливим, і така неможливість триває протягом 30 календарних днів та не виявляє ознак
          припинення, то даний Договір може бути розірваний в односторонньому порядку будь-якою Стороною шляхом
          направлення відповідного Письмового повідомлення про це іншій Стороні.
        </p>
        <p>
          8.9. Виконавець звільняється від будь-якої відповідальності за невиконання своїх зобов'язань за даним
          Договором, якщо таке невиконання сталося внаслідок хакерських та/або DDOS-атак (чи будь-якого іншого
          несанкціонованого доступу до комп'ютерних систем) на Інтернет-сервіс «Mesh Align».
        </p>

        <p>9. ВНЕСЕННЯ ЗМІН ДО ДОГОВОРУ.</p>

        <p>
          9.1. Виконавець має право у будь-який момент, в односторонньому порядку за власною ініціативою, та без
          погодження із Замовником і без його попередження вносити до даного Договору, та/або інших окремо викладених
          електронних документів, які є його невід’ємною частиною – будь-які оновлення, зміни чи коригування, видаляючи
          існуючі та додаючи нові умови і положення.
        </p>
        <p>
          9.2. Виконавець інформує Замовника про оновлення, зміну чи коригування даного Договору, та/або інших окремо
          викладених електронних документів, які є його невід’ємною частиною, шляхом демонстрації останньому
          відповідного електронного повідомлення під час його чергової Авторизації на інтернет-сервісі «Mesh Align».
        </p>
        <p>
          9.3. Оновлена редакція Договору, та/або інших окремо викладених електронних документів, які є його невід’ємною
          частиною, розміщується у відповідному розділі на інтернет-сервісу «Mesh Align», та набуває чинності для Сторін
          з моменту її прийняття з боку Замовника.
        </p>
        <p>
          9.4. Замовник може відмовитися від прийняття оновленої редакції Договору, та/або інших окремо викладених
          електронних документів, які є його невід’ємною частиною. В такому випадку доступ до Акаунту Замовника на
          інтернет-сервісі «Mesh Align» автоматично блокується.
        </p>

        <p>10. ПОРЯДОК РОЗІРВАННЯ ДОГОВОРУ.</p>

        <p>
          10.1. Замовник має право у будь-який момент, в односторонньому порядку за власною ініціативою розірвати даний
          Договір наступним чином:
        </p>
        <p>
          - шляхом направлення Виконавцю відповідного Письмового повідомлення із зазначенням в ньому про розірвання
          Договору. В даному випадку Договір вважається розірваним з моменту отримання Виконавцем такого Письмового
          повідомлення.
        </p>
        <p>
          - згідно із приписами п. 8.8. даного Договору. В даному випадку Договір вважається розірваним з моменту
          направлення відповідного Письмового повідомлення;
        </p>
        <p>
          - шляхом відмови від прийняття оновленої редакції даного Договору, та/або інших окремо викладених електронних
          документів, які є його невід’ємною частиною. В даному випадку Договір вважається розірваним з моменту вчинення
          такої відмови.
        </p>
        <p>
          10.2. Виконавець має право у будь-який момент, в односторонньому порядку за власною ініціативою, та без
          попередження Замовника і без виплати йому будь-яких грошових компенсацій, розірвати даний Договір, та
          заблокувати доступ Замовника до його Акаунту, наступним чином:
        </p>
        <p>
          - згідно із п. 8.8. даного Договору. В даному випадку Договір вважається розірваним з моменту направлення
          відповідного Письмового повідомлення;
        </p>
        <p>
          - в разі порушення будь-яких немайнових та/або майнових прав інтелектуальної власності (авторських прав),
          комерційну таємницю та/або знаки для товарів і послуг, які належать Виконавцю, та/або іншим правовласникам
          таких прав. В даному випадку Договір вважається розірваним з моменту блокування доступу Замовника до його
          Акаунту;
        </p>
        <p>
          - в разі виявлення факту приниження з боку Замовника честі, гідності та/або ділової репутації Виконавця. В
          даному випадку Договір вважається розірваним з моменту блокування доступу Замовника до його Акаунту;
        </p>
        <p>
          - в разі вчинення з боку Замовника будь-яких дій, направлених на пошкодження та/або злом інтернет-сервісу
          «Mesh Align», вивід його з ладу чи будь-якого іншого втручання в його роботу. В даному випадку Договір
          вважається розірваним з моменту блокування доступу Замовника до його Акаунту;
        </p>
        <p>
          - в разі будь-якого порушення будь-яких умов даного Договору, та/або окремо викладених електронних документів,
          які є його невід’ємною частиною. В даному випадку Договір вважається розірваним з моменту блокування доступу
          Замовника до його Акаунту.
        </p>
        <p>
          10.3. Через 30 календарних днів з моменту розірвання даного Договору (незалежно від того, яка саме Сторона і
          на яких саме підставах його розірвала), Виконавець може видалити Акаунт Замовника з інтернет-сервісу «Mesh
          Align», включаючи увесь розміщений, завантажений чи введений протягом всього строку використання Контент.
        </p>
        <p>
          10.4. Припинення або розірвання даного Договору не звільняє Замовника від будь-яких обов’язків, заборон та/або
          обмежень щодо інтернет-сервісу «Mesh Align», які виникли у останнього під час його дії.
        </p>

        <p>11. ПЕРСОНАЛЬНІ ДАНІ.</p>

        <p>
          11.1. Умови обробки, використання, поширення, переміщення та захисту персональних даних будь-яких фізичних
          осіб – визначаються відповідною Політикою конфіденційності, та враховують приписи національного законодавства
          України, міжнародного права, національного законодавства країни Замовника, та Генерального регламенту
          Європейського парламенту і Ради щодо захисту персональних даних.
        </p>

        <p>12. СТРОК ДІЇ ДОГОВОРУ ТА ІНШІ УМОВИ.</p>

        <p>
          12.1. Даний Договір набуває чинності з моменту його укладання та діє до моменту його припинення (розірвання).
          Можлива недійсність однієї або більше умов даного Договору – не тягне за собою недійсності всього Договору в
          цілому або окремих його частин, які залишаються чинними. Зобов’язання Сторін, яки виникли під час дії
          Договору, тобто до моменту його припинення (розірвання) – підлягають обов’язковому виконанню на його умовах.
        </p>
        <p>
          12.2. У всьому іншому, що прямо не передбачено умовами даного Договору, Сторони керуються національним
          законодавством України, або, коли це є обов’язковим чи неминучим – приписами міжнародного права та/або
          національного законодавства країни Замовника.
        </p>
        <p>
          12.3. Замовник без письмового дозволу Виконавця не має права будь-яким чином передавати повністю або частково
          свої права та/або обов’язки за даним Договором іншим особам. Виконавець має право без дозволу Замовника, та
          без його попередження, будь-яким чином передавати повністю або частково свої права та/або обов’язки за даним
          Договором іншим особам.
        </p>
        <p>12.4. Укладаючи даний Договорі, Замовник підтверджує та гарантує наступне:</p>
        <p>
          - на момент Акцепту пропозиції (оферти) Виконавця, Замовник досяг повноліття та/або є повністю дієздатною та
          правоздатною особою у розумінні національного законодавства країни Замовника. В разі, якщо Замовник є
          неповнолітньою, недієздатною та/або неправоздатною особою – то йому вже виповнилося 13 років, а його батьки чи
          законні опікуни ознайомлені із даним Договором, Умовами використання та Політикою конфіденційності;
        </p>
        <p>
          - Замовник Акцептує пропозицію (оферту) Виконавця усвідомлюючи значення своїх дій, не під впливом помилки,
          обману, насильства (фізичного та/або психічного тиску), чи тяжкої обставини;
        </p>
        <p>- укладаючи даний Договір, Замовник має намір створити відповідні правові наслідки, обумовлені ним;</p>
        <p>- умови даного Договору для Замовника є повністю зрозумілими та прийнятними;</p>
        <p>- укладання даного Договору цілком відповідає внутрішній волі Замовника.</p>
        <p>
          12.5. Укладаючи даний Договір, Замовник погоджується із наступним: в разі, якщо деякі положення, правила,
          приписи та/або вимоги даного Договору (далі по тексту – «Положення») суперечать один одному, мають між собою
          розбіжності за смислом та суттю, чи будь-які інші неузгодженості – найвищу юридичну силу буде мати саме те
          Положення, яке надає Виконавцю більше прав, привілеїв та пільг, а також є найбільш вигідним для Виконавця з
          точки зору відповідальності, обов’язків, та свободи дій. Наприклад, якщо в одному Положенні йдеться про
          обов’язок Виконавця щодо узгодження із Замовником певних дій, чи попередження останнього про їх вчинення, а в
          іншому Положенні такий обов’язок – відсутній, то найвищу юридичну силу буде мати те Положення, яке такий
          обов’язок не передбачає та/або звільняє від нього.
        </p>
        <p>
          12.6. Укладаючи даний Договір, Замовник погоджується із наступним: в разі виникнення розбіжностей щодо
          тлумачення положень даного Договору, як в цілому, так і окремих його частин, текст Договору викладений
          українською мовою – має переважну та найвищу силу.
        </p>

        <p>13. ВІДМОВА ВІД ГАРАНТІЙ ТА ОБМЕЖЕННЯ ВІДПОВІДАЛЬНОСТІ.</p>

        <p>
          13.1. Виконавець не несе відповідальності за будь-які збитки та/або шкоду, які виникли через дії Замовника,
          пов’язані із використанням інтернет-сервісу «Mesh Align», через порушення ним та/або іншими особами умов
          даного Договору, Умов використання та/або Політики конфіденційності. Виконавець в будь-якому разі не несе
          відповідальності перед Замовником за втрачений прибуток або вигоду, за непрямі збитки та ненавмисно завдані
          збитки, за збитки через втрату можливості використання інтернет-сервісу «Mesh Align» або переривання
          діяльності Замовника, за будь-яку втрату Контенту, незалежно від причин і підстав настання даних обставин.
        </p>
        <p>
          13.2. Розміщення, завантаження чи введення до інтернет-сервісу «Mesh Align» будь-якого Контенту здійснюється
          Замовником на власні розсуд та ризик, і Виконавець не несе жодної відповідальності за будь-які пошкодження
          комп'ютерної системи Замовника, втрату даних або іншу шкоду, що є результатом таких дій.
        </p>
        <p>
          13.3. Замовник не має права розміщувати, завантажувати чи вводити у будь-які розділи інтернет-сервісу «Mesh
          Align» будь-який Контент, якщо його зміст суперечить умовам даного Договору, національному законодавству
          України, міжнародному праву та/або національному законодавству країни Замовника. Якщо розміщений, завантажений
          чи введений у будь-які розділи інтернет-сервісу «Mesh Align» Контент суперечить вимогам цього пункту Договору,
          Виконавець має право самостійно видалити такий Контент з інтернет-сервісу «Mesh Align», без узгодження із
          Замовником та без його попередження, а також заблокувати доступ Замовника до його Акаунту у інтернет-сервісі
          «Mesh Align».
        </p>
        <p>
          13.4. У будь-якому випадку, в тому числі в разі доведення прямої вини Виконавця за збитки, завдані Замовнику
          та/або третім особам, граничний розмір суми відшкодування збитків, або об’єм відповідальності Виконавця перед
          Замовником, не може перевищувати розміру або об’єму оплаченої вартості відповідної Роботи.
        </p>
        <p>
          13.5. Замовник самостійно несе відповідальність за безпеку (стійкість до вгадування) обраного ним Пароля, а
          також самостійно забезпечує його конфіденційність. Замовник самостійно несе відповідальність за всі свої дії
          чи бездіяльність (включаючи їх наслідки) під час використання інтернет-сервісу «Mesh Align» під його Обліковим
          записом, включаючи випадки добровільної передачі або недотримання конфіденційності даних для доступу до
          Облікового запису Замовника третім особам на будь-яких умовах. При цьому, всі дії під час використання
          інтернет-сервісу «Mesh Align» під Обліковим записом Замовника вважаються здійсненими ним самим, за винятком
          випадку одержання Виконавцем від Замовника Письмового повідомлення про несанкціоноване використання
          інтернет-сервісу «Mesh Align» під його Обліковим записом чи про будь-яке інше порушення (підозри про
          порушення) конфіденційності його Пароля.
        </p>
        <p>
          13.6. Замовник зобов'язаний негайно повідомити Виконавця про будь-який випадок несанкціонованого (не
          дозволеного Замовником) доступу до інтернет-сервісу «Mesh Align» за допомогою Акаунту останнього та/або про
          будь-яке порушення чи підозри про порушення конфіденційності його Пароля. В цілях безпеки, Замовник
          зобов'язаний самостійно здійснювати безпечне завершення роботи у інтернет-сервісі «Mesh Align» під своїм
          Акаунтом (кнопка «Вихід») після закінчення кожної сесії з використання інтернет-сервісу «Mesh Align».
          Виконавець не несе відповідальності за можливу втрату або псування даних, а також інші наслідки будь-якого
          характеру, які можуть статися через порушення Замовником положень даного пункту.
        </p>
        <p>
          13.7. Виконавець має право у будь-який момент, в односторонньому порядку за власною ініціативою та без
          погодження із Замовником та без його попередження, вносити до інтернет-сервісу «Mesh Align» будь-які оновлення
          (вдосконалення), зміни чи коригування, в тому числі, але не виключно:
        </p>
        <p>
          - повністю змінювати чи коригувати структуру, інтерфейс та/або дизайн (зовнішній вигляд) інтернет-сервісу
          «Mesh Align»;
        </p>
        <p>- повністю змінювати чи коригувати алгоритми та/або принципи роботи інтернет-сервісу «Mesh Align»;</p>
        <p>- видаляти, додавати, повністю змінювати чи коригувати 3D формули;</p>
        <p>
          - видаляти, додавати, повністю змінювати чи коригувати будь-який функціонал інтернет-сервісу «Mesh Align»;
        </p>
        <p>
          - будь-яким чином скорочувати або розширювати об’єм функціоналу інтернет-сервісу «Mesh Align», видаляючи,
          змінюючи або додаючи окремі його частини.
        </p>
        <p>
          13.8. Виконавець має право у будь-який момент, в односторонньому порядку за власною ініціативою та без
          погодження із Замовником та без його попередження, заблокувати доступ до Акаунту останнього на підставах,
          визначених пунктом 10.2. даного Договору.
        </p>
        <p>
          13.9. Виконавець не гарантує доступність інтернет-сервісу «Mesh Align» завжди, чи у всіх географічних
          регіонах, та у будь-який час.
        </p>
        <p>
          13.10. Виконавець не гарантує доступність інтернет-сервісу «Mesh Align» з усіх пристроїв, за допомогою
          конкретного Інтернет провайдера або іншого постачальника зв’язку.
        </p>
        <p>
          13.11. Виконавець не дає жодних гарантій на те, що в інтернет-сервісі «Mesh Align» не будуть наявні помилки,
          не буде ніяких пауз та/або збоїв у його роботі, та що він буде сумісним та/або підтримуватися будь-якою
          апаратно-програмною та/або операційною системою.
        </p>

        <p>14. ІНТЕЛЕКТУАЛЬНА ВЛАСНІСТЬ ТА АВТОРСЬКІ ПРАВА.</p>

        <p>
          14.1. Інтернет-сервіс «Mesh Align» є результатом інтелектуальної діяльності та об'єктом авторських прав, які
          регулюються і захищаються національним законодавством України, приписами міжнародного права та національного
          законодавства країни Замовника. Єдиним власником виключних майнових прав інтелектуальної власності на
          інтернет-сервіс «Mesh Align» – є Виконавець. Він також є єдиним автором структури та дизайну (зовнішнього
          вигляду) інтернет-сервісу «Mesh Align».
        </p>
        <p>
          14.2. До складової алгоритму роботи інтернет-сервісу «Mesh Align» можуть входити унікальні 3D формули, за
          допомогою яких у інтернет-сервісі «Mesh Align» може бути реалізована можливість демонстрації клінічних
          випадків у тривимірному просторі. Унікальна 3D формула є персональною розробкою Виконавця та інших
          правовласників.
        </p>
        <p>
          14.3. Алгоритми роботи інтернет-сервісу «Mesh Align», включаючи унікальну 3D формулу – є комерційною
          таємницею, яку забороняється розголошувати, збирати та/або використовувати будь-яким іншим чином, на відміну
          від визначеного умовами Договору. Майнові права інтелектуальної власності на зазначену в даному пункті
          комерційну таємницю належать Виконавцю, та захищаються згідно із приписами п. 14.1. даного Договору.
        </p>
        <p>
          14.4. Замовнику забороняється копіювати, перевидавати, кадрувати, завантажувати, змінювати, поширювати,
          ліцензувати, перепроектувати або розповсюджувати інтернет-сервіс «Mesh Align» та/або окремі його частини, якщо
          це явно не дозволено Виконавцем або за законом. Замовник не має права декомпілювати, намагатися витягти або
          іншим чином використовувати вихідний код, як в цілому так і його окремі складові частини, або інші дані
          інтернет-сервісу «Mesh Align», а також створювати похідні від нього та засновані на ньому об’єкти, сайти,
          системи, програми, бази даних, тощо.
        </p>
        <p>
          14.5. За будь-яке порушення прав інтелектуальної власності та/або авторських прав Виконавця, та/або інших
          осіб, які є правовласниками таких прав, Замовника може бути притягнуто до адміністративної, цивільно-правової
          та/або кримінальної відповідальності згідно із національним законодавством України, приписами міжнародного
          права та/або національного законодавства країни Замовника.
        </p>
        <p>
          14.6. Позначення «Mesh Align» та його логотип (як зображувальний елемент), які використовуються у
          інтернет-сервісу «Mesh Align» – є знаком для товарів і послуг, права інтелектуальної власності на який
          належать Виконавцю, та захищаються згідно із приписами п. 14.1. даного Договору, у зв’язку із чим Замовнику
          забороняється:
        </p>
        <p>
          - наносити такий знак на будь-який товар, для якого знак зареєстровано, упаковку, в якій міститься такий
          товар, вивіску, пов'язану з ним, етикетку, нашивку, бирку чи інший прикріплений до товару предмет, зберігання
          такого товару із зазначеним нанесенням знака з метою пропонування для продажу, пропонування його для продажу,
          продаж, імпорт (ввезення) та експорт (вивезення);
        </p>
        <p>- застосовувати його під час пропонування та/або надання будь-якої послуги, для якої знак зареєстровано;</p>
        <p>- застосовувати його в діловій документації чи в рекламі та в мережі Інтернет.</p>

        <p className="font-bold">РЕКВІЗИТИ ТА КОНТАКТНА ІНФОРМАЦІЯ ВИКОНАВЦЯ:</p>

        <p className="font-bold">ФІЗИЧНА ОСОБА-ПІДПРИЄМЕЦЬ ПОГАРСЬКИЙ АНТОН ЮРІЙОВИЧ.</p>

        <p>
          Дата та номер запису в Єдиному державному реєстрі юридичних осіб, фізичних осіб-підприємців та громадських
          формувань: 28.03.2018 року, № 21030000000096301.
        </p>

        <p>
          Адреса (місце фактичного проживання): 69600, Україна, Запорізька область, місто Запоріжжя, Комунарський район,
          вул. Реконструктивна, будинок 3.
        </p>

        <p>Реєстраційний номер облікової картки платника податків: 2987814459.</p>

        <p>Адреса електронної пошти: meshalign@gmail.com.</p>

        <p>
          Режим роботи: з понеділка по п’ятницю кожного тижня (крім загальносвітових та українських святкових днів), з
          10 год. 00 хв. до 17 год. 00 хв.
        </p>

        <p>Тел.: + 38 097 977 39 98.</p>
      </section>
    </div>
  );
};

export default ElectronicContractUa;
