import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { Link as ScrollLink } from "react-scroll";
import i18next from "../../../i18n";
import scrollToTop from "../../../modules/scrollToTop";
import Footer from "../Footer";

import mainScreen_b2b from "../../../assets/img/mainLoginPage/mainScreen_b2b.png";
import { Facebook, Instagram, YouTube, Pinterest } from "@material-ui/icons";
import cooperation1 from "../../../assets/img/mainLoginPage/cooperation1.png";
import cooperation2 from "../../../assets/img/mainLoginPage/cooperation2.png";
import cooperation3 from "../../../assets/img/mainLoginPage/cooperation3.png";
import cooperation4 from "../../../assets/img/mainLoginPage/cooperation4.png";
import plan1 from "../../../assets/img/mainLoginPage/plan1.png";
import plan11 from "../../../assets/img/mainLoginPage/plan11.png";
import plan2 from "../../../assets/img/mainLoginPage/plan2.png";
import plan22 from "../../../assets/img/mainLoginPage/plan22.png";
import plan3 from "../../../assets/img/mainLoginPage/plan3.png";
import plan33 from "../../../assets/img/mainLoginPage/plan33.png";
import plan4 from "../../../assets/img/mainLoginPage/plan4.png";
import plan44 from "../../../assets/img/mainLoginPage/plan44.png";
import plan5 from "../../../assets/img/mainLoginPage/plan5.png";
import plan55 from "../../../assets/img/mainLoginPage/plan55.png";
import scheme1 from "../../../assets/img/mainLoginPage/scheme1.png";
import scheme2 from "../../../assets/img/mainLoginPage/scheme2.png";
import scheme3 from "../../../assets/img/mainLoginPage/scheme3.png";
import communicate from "../../../assets/img/mainLoginPage/communicate.png";
import smile1 from "../../../assets/img/mainLoginPage/smile1.png";
import smile2 from "../../../assets/img/mainLoginPage/smile2.png";
import smile3 from "../../../assets/img/mainLoginPage/smile3.png";
import aesthetic1 from "../../../assets/img/mainLoginPage/aesthetic1.jpg";
import aesthetic2 from "../../../assets/img/mainLoginPage/aesthetic2.jpg";
import aesth1 from "../../../assets/img/mainLoginPage/aesth1.png";
import aesth2 from "../../../assets/img/mainLoginPage/aesth2.png";
import lib from "../../../assets/img/mainLoginPage/lib.png";
import libs from "../../../assets/img/mainLoginPage/libs.jpg";

import { toggleContactUs } from "../../../actions";
import ContactUs from "../../Dialogs/ContactUs";
import { throttle } from "lodash";

import "./index.scss";
import LanguageSelect from "../../LanguageSelect";

class HomePage extends PureComponent {
  state = {
    inMenuOpen: false,
    isHeader: false,
    langOnMount: null,
  };

  componentDidMount() {
    this.setState({
      langOnMount: i18next.language,
    });
    window.addEventListener("scroll", this.scrollHandler, false);
    scrollToTop();
    if (window.innerWidth < 992) {
      this.setState({ isMobile: true });
    }
    this.setState({
      inMenuOpen: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.t !== prevProps.t) {
      this.setState({
        langOnMount: i18next.language,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler, false);
  }

  scrollHandler = throttle(() => {
    if (window.scrollY >= 10) {
      this.setState({ isHeader: true });
    } else {
      this.setState({ isHeader: false });
    }
  }, 100);

  toggleNav = () => {
    this.setState({
      inMenuOpen: !this.state.inMenuOpen,
    });
  };

  loadLink = (link, type) => {
    switch (type) {
      case "newTab":
        window.open(link, "_blank");
        break;
      case "download":
        window.location.assign(link);
        break;
      default:
    }
  };

  render() {
    const { t: _t, isContactUs } = this.props;
    const { inMenuOpen, isHeader, langOnMount } = this.state;
    return (
      <div className="homePage">
        <section id="main" className="mainScreen">
          <header className={`header ${isHeader ? "scroll" : ""}`}>
            <div className="container">
              <nav className="nav">
                <ScrollLink className="logo" to="main" spy={true} smooth={true} duration={500}>
                  <span className="dark">Mesh</span>
                  <span className="pink">Align</span>
                </ScrollLink>
                <ul className={inMenuOpen ? "active" : ""}>
                  <li onClick={this.toggleNav}>
                    <ScrollLink className="link" to="cooperation" spy={true} smooth={true} duration={500}>
                      {_t("Cooperation")}
                    </ScrollLink>
                  </li>
                  <li onClick={this.toggleNav}>
                    <ScrollLink className="link" to="planning" spy={true} smooth={true} duration={500}>
                      {_t("Planning aligners")}
                    </ScrollLink>
                  </li>
                  <li onClick={this.toggleNav}>
                    <ScrollLink className="link" to="communicate" spy={true} smooth={true} duration={500}>
                      {_t("Communicate")}
                    </ScrollLink>
                  </li>
                  <li onClick={this.toggleNav}>
                    <ScrollLink className="link" to="smile" spy={true} smooth={true} duration={500}>
                      {_t("Smile design")}
                    </ScrollLink>
                  </li>
                  <li onClick={this.toggleNav}>
                    <ScrollLink className="link" to="aesthetic" spy={true} smooth={true} duration={500}>
                      {_t("Aesthetic mockup")}
                    </ScrollLink>
                  </li>
                  <li className="actions">
                    <LanguageSelect />
                    <div className="socials">
                      <a href="https://www.facebook.com/meshalign/" className="social">
                        <Facebook />
                      </a>
                      <a href="https://www.instagram.com/meshalign" className="social">
                        <Instagram />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCPy2ioh4MCFFvTy-GbYgu3Q/featured?view_as=public"
                        className="social">
                        <YouTube />
                      </a>
                      <a href="https://www.pinterest.com/Mesh_Align/" className="social">
                        <Pinterest />
                      </a>
                    </div>
                    <Button className="login" component={Link} to="/login">
                      {_t("Log in")}
                    </Button>
                  </li>

                  <button className="nav-close" onClick={this.toggleNav}>
                    <span />
                    <span />
                  </button>
                </ul>
                <button className="nav-open" onClick={this.toggleNav}>
                  <span />
                  <span />
                  <span />
                </button>
              </nav>
            </div>
          </header>
          <h1>
            <span className="dark">Mesh</span>
            <span className="light">Align</span>
          </h1>
          <div className="text">
            <h2 className="pink">
              {_t("We provide a complete set of 3D models ready for printing and further manufacturing aligners")}
            </h2>
            <div className="pic">
              <img src={mainScreen_b2b} alt={_t("Development of aligners systems for dental companies")} />
            </div>
            <h2 className="white">{_t("Development of aligners systems for dental companies")}</h2>
          </div>
        </section>
        <section className="advs">
          <h2>
            {_t(
              "The company MeshAlign has provided support for the dental business to implement digital methods of orthodontic treatment.",
            )}
          </h2>
          <div className="items">
            <div className="item">
              <div className="pic">
                <img src={require("../../../assets/img/mainLoginPage/advs1.png")} alt={_t("High tech platform")} />
              </div>
              <p>{_t("High tech platform")}</p>
            </div>
            <div className="item">
              <div className="pic">
                <img
                  src={require("../../../assets/img/mainLoginPage/advs2.png")}
                  alt={_t("Extensive dental experience")}
                />
              </div>
              <p>{_t("Extensive dental experience")}</p>
            </div>
            <div className="item">
              <div className="pic">
                <img
                  src={require("../../../assets/img/mainLoginPage/advs3.png")}
                  alt={_t("High speed of order fulfillment")}
                />
              </div>
              <p>{_t("High speed of order fulfillment")}</p>
            </div>
          </div>
        </section>
        <section id="cooperation" className="cooperation">
          <h2>{_t("How to cooperate with us:")}</h2>
          <div className="items">
            <div className="item">
              <div className="icon">
                <img src={cooperation1} alt={_t("Contact us")} />
              </div>
              <p className="text">
                {_t("Contact us and get a link to register on the MeshAlign platform.")} <br />
                {_t("Register and get a personal account on our web platform.")}
              </p>
            </div>
            <div className="item">
              <div className="icon">
                <img src={cooperation2} alt={_t("Create an order")} />
              </div>
              <p className="text">
                {_t(
                  "Create an order in your personal account. Send us the initial data for the design of aligners systems: digital models of the patient’s teeth, TRG, panoramic X-rays, etc.",
                )}
              </p>
            </div>
            <div className="item">
              <div className="icon">
                <img src={cooperation3} alt={_t("Get a treatment plan")} />
              </div>
              <p className="text">
                {_t("Get the aligners orthodontic treatment plan from company specialists and confirm it.")}
              </p>
            </div>
            <div className="item">
              <div className="icon">
                <img src={cooperation4} alt={_t("Get digital models")} />
              </div>
              <p className="text">
                {_t("Get digital models fully ready for 3D printing. Start production of aligners.")}
              </p>
            </div>
          </div>
          <Button className="btn borderRed" onClick={() => this.props.toggleContactUs({ isShow: true })}>
            {_t("Contact us")}
          </Button>
        </section>
        <section id="planning" className="planning">
          <h2>{_t("How do we plan aligners:")}</h2>
          <div className="items">
            <div className="item">
              <div className="pic">
                <img src={plan1} alt={_t("We use 3D models")} />
              </div>
              <p>
                {_t(
                  "We use 3D models of the patient in occlusion to analyze the position of the teeth and determine the type of pathology",
                )}
              </p>
              <div className="pic">
                <img src={plan11} alt={_t("We use 3D models")} />
              </div>
            </div>
            <div className="item">
              <div className="pic">
                <img src={plan2} alt={_t("We use CT analysis to determine the position of roots")} />
              </div>
              <p>
                {_t(
                  "We use CT analysis to determine the position of roots. We determine the boundaries and the ability of moving teeth in the bone.",
                )}
              </p>
              <div className="pic">
                <img src={plan22} alt={_t("We use CT analysis to determine the position of roots")} />
              </div>
            </div>
            <div className="item">
              <div className="pic">
                <img src={plan3} alt={_t("We analyze the position and size of the teeth")} />
              </div>
              <p>{_t("We analyze the position and size of the teeth to determine the future dental arch")}</p>
              <div className="pic">
                <img src={plan33} alt={_t("We analyze the position and size of the teeth")} />
              </div>
            </div>
            <div className="item">
              <div className="pic">
                <img src={plan4} alt={_t("We measure dental arches")} />
              </div>
              <p>{_t("We measure dental arches to normalize the width")}</p>
              <div className="pic">
                <img src={plan44} alt={_t("We measure dental arches")} />
              </div>
            </div>
            <div className="item">
              <div className="pic">
                <img src={plan5} alt={_t("We measure TRG")} />
              </div>
              <p>
                {_t(
                  "We measure TRG in lateral projection to determine: the bite height, the inclinations of incisors and the position of the occlusal plane",
                )}
              </p>
              <div className="pic">
                <img src={plan55} alt={_t("We measure TRG")} />
              </div>
            </div>
          </div>
          <p>
            {_t(
              "We have developed our classification of orthodontic abnormalities and have developed protocols for the analysis and treatment of each type of clinical case. Each treatment plan provided by our specialists is the result of successive planned steps developed in the process of working with a large number of clinical cases. Thanks to our classification, the customer always receives a reasonable treatment plan. When creating an order, it is important to indicate the type of abnormality that the customer wants to correct. This approach will provide understanding between partners and is the key to a long-term cooperation.",
            )}
          </p>
          <Button component={Link} to="/classification" className="btn borderRed">
            {_t("View classification of malocclusion by meshalign cases corrected by meshalign")}
          </Button>
          <p className="text-center">
            {_t(
              "After going to the page with the types of diseases, you can view the necessary data set for each type of clinical case.",
            )}
          </p>
        </section>
        <section className="scheme">
          <h2>{_t("Scheme of fulfilling orders for the development of MeshAlign company-issued aligners systems:")}</h2>
          <div className="items">
            <div className="item">
              <div className="pic">
                <img src={scheme1} alt={_t("Getting planning materials")} />
              </div>
              <p>{_t("Getting planning materials")}</p>
            </div>
            <div className="item">
              <div className="pic">
                <img src={scheme2} alt={_t("3D planning of orthodontic treatment")} />
              </div>
              <p>{_t("3D planning of orthodontic treatment")}</p>
            </div>
            <div className="item">
              <div className="pic">
                <img src={scheme3} alt={_t("Creating 3D models for the manufacture of aligners")} />
              </div>
              <p>{_t("Creating 3D models for the manufacture of aligners")}</p>
            </div>
          </div>
          <div className="scheme__steps">
            <h2>{_t("Cooperation scheme:")}</h2>
            <div className="scheme__row">
              <div className="scheme__img scheme__img_left">
                <img src={require("../../../assets/img/cs1.png")} alt="" />
              </div>
              <div className="scheme__poly scheme__poly_1">
                <span>
                  {_t("Creating an order on the MeshAlign platform. Upload digital jaw scans and other information.")}
                </span>
              </div>
              <div className="scheme__pic">
                <img src={require("../../../assets/img/cs2.png")} alt="" />
              </div>
            </div>
            <div className="scheme__arrow" />
            <div className="scheme__row scheme__row_2">
              <div className="scheme__pic">
                <img src={require("../../../assets/img/cs3.png")} alt="" />
              </div>

              <div className="scheme__poly scheme__poly_2">
                <span>
                  {_t(
                    "Creating a treatment plan by specialists MeshAlign company ﻿ and transmitting ﻿the presentation to the customer in the profile of the web platform meshalign.com.",
                  )}
                </span>
              </div>
              <div className="scheme__img scheme__img_right">
                <img src={require("../../../assets/img/cs4.png")} alt="" />
              </div>
            </div>
            <div className="scheme__arrow" />
            <div className="scheme__row scheme__row_3">
              <div className="scheme__inner">
                <div className="scheme__img scheme__img_left">
                  <img src={require("../../../assets/img/cs5.png")} alt="" />
                </div>
                <div className="scheme__poly">
                  <span>{_t("Project confirmation.")}</span>
                </div>
              </div>
              <div className="scheme__inner">
                <div className="scheme__img scheme__img_left">
                  <img src={require("../../../assets/img/cs6.png")} alt="" />
                </div>
                <div className="scheme__poly">
                  <span>{_t("Sending ready-to-print files to the customer.")}</span>
                </div>
              </div>
              <div className="scheme__pic">
                <img src={require("../../../assets/img/cs7.png")} alt="" />
              </div>
            </div>
          </div>
          <p className="scheme__par">
            {_t(
              "After receiving ready-to-print models for the production of aligners, you can make them on your 3D printer in a clinic or laboratory.",
            )}
          </p>
          <p className="scheme__par">
            {_t("We have developed a manual for you with information on how you can do this.")}
          </p>
          <div
            onClick={() => {
              this.loadLink(
                require(`../../../assets/${
                  langOnMount === "ru" ? "Изготовление элайнеров" : "Manufacturing of aligners"
                }.pdf`),
                "newTab",
              );
            }}
            className="scheme__btn">
            {_t("View pdf manual")}
          </div>
        </section>
        <section id="communicate" className="communicate">
          <h2>{_t("How do we communicate with customers:")}</h2>
          <div className="flex">
            <p>
              {_t(
                "To create and store all projects, we have developed a web platform for communication with customers. You can place orders and view treatment plans using your personal account on the web platform. This approach allows to completely remove the distance between us and to cooperate at a high level without discomfort.",
              )}
            </p>
            <div className="pic">
              <img src={communicate} alt={_t("communicate")} />
            </div>
          </div>
          <p>{_t("You can create your account and start cooperation with MeshAlign contacting us")}</p>
          <Button className="btn borderRed" onClick={() => this.props.toggleContactUs({ isShow: true })}>
            {_t("Contact us")}
          </Button>
        </section>
        <section id="smile" className="smile">
          <h2>
            {_t("Additional offer:")} <span>{_t("Digital smile design")}</span>
          </h2>
          <p>
            {_t(
              "Planning a smile design is the primary tool for the preliminary analysis of a clinical case and creation of a comprehensive treatment plan. Smile Design is also a powerful motivational tool for the patient.",
            )}
          </p>
          <div className="pics">
            <div className="pic">
              <img src={smile1} alt={_t("Digital smile design")} />
            </div>
            <div className="pic">
              <img src={smile2} alt={_t("Digital smile design")} />
            </div>
            <div className="pic">
              <img src={smile3} alt={_t("Digital smile design")} />
            </div>
          </div>
          <p>
            {_t(
              "We can create a smile design of the patient to improve the results of planning and create a detailed plan for the aesthetic rehabilitation of the patient. This will help the patient to see the end result obtained after orthodontic treatment and restorations.",
            )}
          </p>
        </section>
        <section id="aesthetic" className="aesthetic">
          <h2>
            {_t("Additional offer:")} <span>{_t("Aesthetic mockup of teeth")}</span>
          </h2>
          <p>
            {_t(
              "Orthodontic treatment with aligners allows you to change the position of the teeth, but does not allow you to change the shape of the teeth. In most cases, it is necessary to carry out restorations with composite materials, veneers or crowns after orthodontic treatment to achieve the desired aesthetics. It is necessary to consider the shape of the future teeth to plan orthodontic treatment with further restoration.",
            )}
          </p>
          <div className="pics">
            <div className="pic">
              <img src={aesthetic1} alt={_t("Aesthetic mockup of teeth")} />
            </div>
            <div className="pic">
              <img src={aesthetic2} alt={_t("Aesthetic mockup of teeth")} />
            </div>
          </div>
        </section>
        <section className="aesth">
          <h2>{_t("Our specialists can develop an aesthetic mockup of the patient’s teeth")}</h2>
          <div className="pics">
            <div className="pic">
              <img src={aesth1} alt={_t("Our specialists can develop an aesthetic mockup of the patient’s teeth")} />
            </div>
            <div className="pic">
              <img src={aesth2} alt={_t("Our specialists can develop an aesthetic mockup of the patient’s teeth")} />
            </div>
          </div>
          <div className="list">
            <p>{_t("An aesthetic mockup can also be used for:")}</p>
            <p>{_t("- visualization of the desired result of aesthetic rehabilitation;")}</p>
            <p>{_t("- creation of a plastic mockup in the patient's mouth;")}</p>
            <p>{_t("- modeling of veneers or crowns;")}</p>
            <p>{_t("- creation of a template for direct composite restorations.")}</p>
          </div>
        </section>
        <section className="lib">
          <div className="content">
            <h2>
              {_t(
                "The unified tooth library developed by Colleagial Studio specialists is used for modeling of aesthetic mockups and digital smile design.",
              )}
            </h2>
            <div className="pic">
              <img src={lib} alt={_t("Expression tooth library")} />
            </div>
            <Button component={Link} to="/libraryes" className="btn borderRed">
              {_t("View library in detail")}
            </Button>
          </div>
          <div className="libs">
            <img
              src={libs}
              alt={_t(
                "The unified tooth library developed by Colleagial Studio specialists is used for modeling of aesthetic mockups and digital smile design.",
              )}
            />
          </div>
        </section>
        <Footer />
        {isContactUs && (
          <ContactUs isShow={isContactUs} onClose={() => this.props.toggleContactUs({ isShow: false })} />
        )}
      </div>
    );
  }
}

HomePage.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({ dialog }) => ({
  isContactUs: dialog.isContactUs,
});

const mapDispatchToProps = {
  toggleContactUs,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomePage));
