import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";

import * as Yup from "yup";
import { API } from "../../../modules/Api";
import Logger from "../../../modules/Logger";
import { Formik, Field, Form } from "formik";
import { TextField, Button } from "@material-ui/core";
import { SmsOutlined } from "@material-ui/icons";

class PasswordRecovery extends PureComponent {
  state = {
    recoveryStep1: false,
    emailPasswordRevovery: "",
  }
  ResetPasswordSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .required(_t("This field is required"))
        .email(_t("Invalid e-mail")),
    });
  };
  resetPassword = ({ email }) => {
    API.post("user/reset_password", { email }).then(
      response => {
        Logger.info(response);
        this.setState({
          recoveryStep1: !this.state.recoveryStep1,
          emailPasswordRevovery: email,
        });
        // this.props.onClose();
      },
      error => {
        Logger.info(error);
      },
    );
  };
  render() {
    const { t: _t } = this.props;
    const { recoveryStep1, emailPasswordRevovery } = this.state;
    return (
      <div className="form">
        <div className="title">{_t("Password Recovery")}</div>
        {recoveryStep1 === false ? (
          <div className="formik">
            <p className="instruction">
              {_t(
                "Enter the email address which was used for your account registration and the system will send you a new password to this email address",
              )}
            </p>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={this.ResetPasswordSchema()}
              onSubmit={this.resetPassword}
            >
              {({ errors }) => (
                <Form>
                  <Field name="email">
                    {({ field }) => (
                      <div className="inputWithIcon">
                        <SmsOutlined />
                        <TextField
                          type="string"
                          label="Email address"
                          margin="dense"
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email}
                          {...field}
                        />
                      </div>
                    )}
                  </Field>
                  <Button type="submit" className="btn">
                    {_t("Change")}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="formik">
            <div className="checkEmail">
              <p className="checkEmail-text bold">{_t("We have sent you an email with a new password")}</p>
              <p className="checkEmail-email">{emailPasswordRevovery}</p>
              <div className="checkEmail-text-block">
                <p className="checkEmail-text">{_t("If you did not receive the letter, just in case, check: ")}</p>
                <li className="checkEmail-text-li">{_t("there are no errors in the email address")}</li>
                <li className="checkEmail-text-li">{_t("did the letter fall into the Spam folder")}</li>
              </div>
              <p className="checkEmail-text">
                {_t(
                  "If you have any questions regarding registration or access to your  personal page, write to {{address}} we will understand and we will definitely help.",
                  { address: "meshalign@gmail.com" },
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PasswordRecovery.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(PasswordRecovery);
