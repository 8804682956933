import React from "react";
import { Link } from "react-router-dom";
import ArrowBack from "../../ArrowBack";
import "./index.scss";

const TermsOfUseEn = () => (
  <div className="classification">
    <div className="overlay" />
    <header className="header">
      <Link className="logo" to="/">
        <span className="dark">Mesh</span>
        <span className="pink">Align</span>
      </Link>
    </header>
    <section>
      <ArrowBack path="/" text="Back to home page" />
      <h2>ELECTRONIC MESSAGE</h2>
      <p className="font-bold text-center">before using the Mesh Align internet service</p>

      <p className="font-bold">DEAR CUSTOMER!</p>

      <p className="f-18">
        PLEASE GET ACQUAINTED WITH THE TERMS AND CONDITIONS (hereinafter referred to as the "Terms"), which are an
        integral part of the electronic contract for the performance of work (hereinafter referred to as the
        "Agreement"), given at: https://meshalign.com/electronic-contract.
      </p>
      <p className="f-18">
        BY ACCEPTING THE PUBLIC OFFER TO CONCLUDE THE AGREEMENT, YOU AUTOMATICALLY ACCEPT THE TERMS AND AGREE TO
        FAITHFULLY FULFILL THEM.
      </p>
      <p className="f-18">THIS ELECTRONIC MESSAGE is an integral part of the contract.</p>

      <p className="font-bold text-center">TERMS OF USE</p>
      <p>Mesh Align Internet Service</p>

      <p>04/27/2020.</p>

      <p>
        These terms of use (hereinafter referred to as the “Terms”) determine the rules for using the Mesh Align
        Internet service (hereinafter referred to as the Mesh Align Internet service). These Terms are an integral part
        of the electronic Contract for the performance of work (hereinafter referred to as the “Contract”), which is
        given at: https://meshalign.com/electronic-contract.
      </p>

      <p>1. KEY TERMS AND DEFINITIONS.</p>

      <p>
        1.1. “Authorization” is the electronic process of the Customer entering his Account, during which the latter is
        checked for the rights of the latter to provide him with appropriate access to such an Account. For
        authorization, the Customer must enter his identifiers in the appropriate electronic form and confirm their
        intention to log in to his account.
      </p>
      <p>
        1.2. “Account” is the electronic information about the Customer that the latter places, downloads or enters in
        the Mesh Align Internet service, it includes the Account, Profile, and Personal Cabinet. An integral part of the
        Account is also Content. To use the Mesh Align Internet service and to be able to carry out work orders, you
        need an Account.
      </p>
      <p>
        1.3. “Acceptance” is full and unconditional acceptance by the Customer of the Contractor’s proposal (offer) to
        conclude this Agreement on the terms and conditions indicated by the Contractor (clauses 3.1. and 3.2. of the
        Agreement).
      </p>
      <p>1.4. "Identifiers" are the unique login and password that are created personally by the Customer.</p>
      <p>
        1.5. “Internet” is the global information network of general access based on Internet protocols defined by
        international standards.
      </p>
      <p>
        1.6. “Content” means text (written) or video materials, audio recordings or images, as well as any other
        information that is posted, downloaded or entered by the Customer into any sections of the Mesh Align Internet
        service. For the purposes of this Agreement, the Content, including, but not exclusively, also includes the
        corresponding electronic text messages, electronic medical records of patients, any electronic computer files
        uploaded to the Mesh Align Internet service, any electronic digital images, any electronic digital video, any
        information, and any personal data of all persons. Content is an integral part of the Account.
      </p>
      <p>
        1.7. “Login” is a unique character set that identifies the Customer in the Mesh Align Internet service, given as
        an email address, which is in the unhindered and personal use of the Customer. Login is a part of the Account.
        The Contractor has the right to prohibit the use of certain Logins, as well as establish requirements on them
        (length, valid characters, etc.).
      </p>
      <p>
        1.8. “Account” is a combination of information about the Customer posted, uploaded or entered into the Mesh
        Align Internet service.
      </p>
      <p>
        1.9. “Personal cabinet” is a section of the Mesh Align Internet service that can only be accessed by a
        registered Customer after completing the electronic Authorization procedure using the Identifier, and through
        which the Customer manages the account.
      </p>
      <p>
        1.10. "Password" is a secret word or a specific sequence of characters designed to confirm the identity of the
        Customer and his rights. The password is used to protect the information available in the Mesh Align Internet
        service from unauthorized access by any unauthorized persons. The password is an element of the account, and is
        used by the Mesh Align Internet service to provide the Customer with permission to enter his account. The
        Contractor has the right to prohibit the use of certain Passwords, as well as establish requirements on them
        (length, valid characters, etc.).
      </p>
      <p>
        1.11. “Written notice” is an electronic letter that may include text (written) or video materials, audio
        recordings or images sent by any Party to this Agreement to each other to the appropriate email address. For the
        purposes of this Agreement, the Customer’s email address is the address indicated last as his Login, and the
        Contractor’s email address is the address specified in this Agreement among other contact details of the latter.
        A written notice may be in Ukrainian, English or Russian.
      </p>
      <p>
        1.12. «Profile» is a collection of personal information about the Customer specified by the latter when
        registering in the Mesh Align online service by filling out the appropriate registration form.
      </p>
      <p>
        1.13. Mesh Align Internet service is a website posted on the Internet at https://meshalign.com, by which the
        Customer has the opportunity to order the Contractor to perform work under this Agreement.
      </p>
      <p>
        1.14. “Registration” is an electronic procedure during which the Customer fills out the appropriate registration
        form and creates an Account in the Mesh Align Internet service. For the purposes of this Agreement, Registration
        is the Customer’s action, which is considered complete and unconditional acceptance of the Contractor’s offer to
        conclude this Agreement.
      </p>

      <p>2. GENERAL PROVISIONS.</p>

      <p>
        2.1. The Contractor carries out the current management of the Mesh Align Internet service, determines its
        structure and design (appearance), allows, suspends, restricts or blocks access to it on the basis of the
        Agreement, Terms, and Privacy Policy, which is given at: https://meshalign.com/electronic-contract,
        https://meshalign.com/terms-of-use, https://meshalign.com/policy.
      </p>
      <p>
        2.2. The Contractor provides the technical possibility to use the Mesh Align Internet service and does not
        participate in the creation of the Account, Profile, and the Customer’s Personal Cabinet.
      </p>
      <p>2.3. Registration in the Mesh Align online service is free of charge.</p>
      <p>
        2.4. The Contractor shall have the right at any time to make any updates (improvements), changes or adjustments
        to the Mesh Align Internet service, in accordance with the requirements of clause 13.7. of the Contract. Also,
        the Contractor may, at any time convenient for himself, carry out any technical work on the Mesh Align Internet
        service (for example, updating it, correcting errors, etc.), during which access to it may be temporarily
        blocked. In this case, the Contractor warns the Customer in advance about upcoming technical works of this kind,
        indicating the time of their implementation and the total duration.
      </p>
      <p>
        2.5. The Сustomer is forbidden to carry out any modifications of the Mesh Align Internet service, its structure
        and design (appearance) at his convenience or for his hardware and software environment.
      </p>
      <p>
        2.6. The Сustomer shall not use the Mesh Align Internet service if the legitimate sanctions of any country
        prohibit its use in the respective region in which the customer is located.
      </p>
      <p>2.7. Persons under the age of 13 years are not allowed to use the Mesh Align Internet service.</p>
      <p>
        2.8. If any person is a resident of the European Economic Area, he is allowed to use the Mesh Align online
        service only when he reaches the age at which such a person can consent to the personal data processing in
        accordance with the requirements of the national legislation of the respective country that is part of the
        European Economic Area.
      </p>
      <p>
        2.9. An additional fee for using the Internet to access the Mesh Align Internet service is covered by the
        Customer.
      </p>
      <p>
        2.10. By concluding the Agreement, the Customer agrees with the following: in the event that certain provisions,
        rules, regulations and / or requirements of the Agreement, the Terms and / or the Privacy Policy (hereinafter
        referred to as the "Regulation") contradict each other, or possess any disagreements in the meaning or any other
        inconsistencies, supreme legal force will be given to the Regulation that provides the Contractor with more
        rights, privileges and benefits, and is also the most beneficial for the Contractor in terms of responsibility,
        obligations and freedom of action. For example, if one Regulation refers to the Contractor’s obligation to
        coordinate certain actions with the Customer or to warn the latter about their completion, and the other
        Regulation doesn’t have such an obligation, then the Regulation that does not contain such an obligation and /
        or exempts from it shall have the highest legal effect.
      </p>
      <p>
        2.11. By concluding the Agreement, the Customer agrees with the following: in case of disagreement in the
        interpretation of these Terms, both as a whole, as well as their individual parts and provisions, the text of
        the Terms in Ukrainian is of great and high force.
      </p>

      <p>3. ACCOUNT.</p>

      <p>
        3.1. To access the Mesh Align Internet service and to use it, you need an Account, which is created by the
        Customer through Registration. In order to be able to register, it is necessary to submit an appropriate
        electronic application to the Contractor, indicating a valid email address, last name, first name and
        patronymic.
      </p>
      <p>
        3.2. Registration includes certain accreditation of the Customer for compliance with the criteria specified by
        the Contractor, which are mandatory for cooperation. Accreditation is done by the administrator of the Mesh
        Align Internet service and provides the Contractor the right to request certain information from the Customer,
        as well as any professional training documents, referring to necessary education and qualifications. Based on
        the results of the consideration of the submitted electronic application, the administrator of the Mesh Align
        Internet service reports the decision by e-mail. If a person does not meet certain criteria for cooperation, the
        Contractor may refuse the registration.
      </p>
      <p>
        3.3. In order to register the Customer needs to have a valid e-mail address, which subsequently acts as a Login
        for authorization in the Mesh Align Internet service..
      </p>
      <p>
        3.4. During Registration, the Customer is obliged to provide accurate information about his personality, namely:
        last name, first name, middle name, information about the position and place of work, profile photo (optional),
        telephone number for communication, country of residence, email address. The Customer is not required to
        disclose his identity, but the latter shall provide the Contractor with accurate and current information
        (including Registration). The customer does not have the right to impersonate who he is not in fact, and also
        shall not have the right to create accounts for other individuals.
      </p>
      <p>
        3.5. The login should consist only of the English alphabet letters, numbers and symbols of the "ASCII" code
        table.
      </p>
      <p>3.6. The password must meet the following requirements:</p>
      <p>- contain at least 6 characters;</p>
      <p>- contain only latin letters and numbers (without punctuation, spaces and other special characters);</p>
      <p>- contain at least 1 latin letter;</p>
      <p>- contain at least 1 digit.</p>
      <p>
        The password should not match the login, it is not recommended to associate it with the Customer. The password
        must be such that the Customer can remember it without writing it down on a piece of paper or to any data
        storage device.
      </p>
      <p>
        3.7. The Customer bears full and personal responsibility for any actions in the Mesh Align online service that
        are performed using his account. The Contractor has the right to block the Customer’s access to his Mesh Align
        Internet service Account, if such an Account is used in violation of these Terms, as well as on the basis and in
        the cases specified in clause 10.2 of the Agreement.
      </p>
      <p>
        3.8. To Register an Account, the Customer must be of legal age and / or be fully competent and legally capable
        person in the understanding of the national legislation of the country of the latter. In the event that the
        Customer is a minor, legally incapable and / or incompetent, he should acquaint himself with these Terms with
        his parents or legal guardians. Since the Contractor is deprived of any ability to monitor compliance by the
        Customer or his parents or legal guardians with the requirements set forth in this clause, the Contractor shall
        be exempted of any liability in the event of any negative consequences due to the use of the Mesh Align Internet
        service. In such cases only the Customer, his parents or legal guardians will be held liable.
      </p>
      <p>
        3.9. The customer shall be obliged to take all possible measures to maintain the confidentiality of his
        Password. If necessary, the Customer can replace the Password at any time. In order to prevent any unauthorized
        use of the Customer's Account, the Contractor may limit the number of attempts to enter a password during
        authorization..
      </p>
      <p>
        3.10. In the event that as a result of unauthorized access by unauthorized persons, the Customer has lost access
        to his account, he must immediately inform the Contractor by sending a written notice in order to prevent the
        destruction or damage of the Content, unauthorized transfer of control over the account, etc.
      </p>
      <p>
        3.11. The Contractor makes every effort to ensure the security of the Customer's Account, including his Content,
        but, despite this, does not guarantee that unauthorized third parties will not be able to overcome such security
        measures.
      </p>

      <p>4. CONTENT.</p>

      <p>
        4.1. The Customer bears full and personal responsibility for any Content that is posted, downloaded or entered
        by the Customer in any sections of the Mesh Align Internet service.
      </p>
      <p>
        4.2. The Customer shall not post, upload or enter into the Mesh Align Internet service any Content that violates
        any personal non-property or property rights of others, including privacy rights, intellectual property rights
        and copyrights, rights to personal data, etc., or requirements of the national legislation of Ukraine,
        international law and / or national legislation of the country of the Customer.
      </p>
      <p>
        4.3. The Contractor does not monitor and analyze the Customer’s Content, does not encourage or approve its
        content, and does not force the latter to post, download or enter such Content into the Mesh Align Internet
        service..
      </p>
      <p>
        4.4. The conditions for posting, downloading or entering into the Mesh Align Internet service, as well as for
        the processing, use, distribution and movement of Content that contains personal data of any individuals, are
        determined by the Privacy Policy and take into account the requirements of the national legislation of Ukraine,
        international law, Customer’s national legislation and the General Regulations of the European Parliament and
        the Council for the Protection of Personal Data.
      </p>
      <p>
        4.5. The Mesh Align Internet service may contain links to third-party websites, advertisers, services, special
        offers and other events or activities that are not owned and controlled by the Contractor. The Contractor does
        not endorse or assume any responsibility in relation to such third-party websites, information, materials, goods
        or services. If the Customer visits any third-party site, service or content through the Mesh Align Internet
        service, he bears full and personal responsibility for this and agrees that the Contractor would not have
        obligations to him in connection with what he used or switched to a third-party site or service. The Contractor
        disclaims all liability if such third-party websites or services:
      </p>
      <p>- violate the intellectual property rights or copyrights of any third parties;</p>
      <p>- are inaccurate, incomplete or misinforming;</p>
      <p>- are commercially unprofitable and not suitable for a specific purpose;</p>
      <p>- do not provide adequate security;</p>
      <p>- contain viruses or other objects of a destructive nature;</p>
      <p>- are slanderous or offend human dignity.</p>
      <p>
        4.6. The Contractor reserves the right, but not the obligation, at its discretion, without prior notice to the
        Customer to remove the Content or its individual elements if, in the opinion of the Contractor, their content
        violates the provisions of the Agreement, the Terms and the Privacy Policy.
      </p>
      <p>
        4.7. In the event of avoidance of the Agreement or its termination (regardless of which party and on what
        grounds it was terminated), the Contractor has the discretion, without special permission of the Customer, to
        store in the Mesh Align Internet service (to not delete) any Content that has been posted, uploaded or entered
        by the Customer into the Mesh Align Internet service during the entire period of its use. The Contractor may
        store such Content in the Mesh Align Internet service for any period of time. Content or part thereof is subject
        to removal from the Mesh Align Internet service in the event the Contractor receives a written notice requesting
        such removal.
      </p>

      <p>5. RULES OF CONDUCT.</p>

      <p>
        5.1. Using the Internet service "Mesh Align", the Customer shall comply scrupulously with the following rules of
        conduct:
      </p>
      <p>
        - do not violate the requirements of the national legislation of Ukraine, international law and / or national
        legislation of the country of the Customer when using the Mesh Align Internet service»;
      </p>
      <p>- do not use the Mesh Align Internet service for any illegal or prohibited purpose;</p>
      <p>- do not register under the name and surname of another person, impersonating him;</p>
      <p>- do not post images of other persons in your Profile as your own photo;</p>
      <p>
        - do not disrupt the work of the Mesh Align Internet service, not to use any software that damages, modifies,
        interrupts the Mesh Align Internet service and / or stops its operation, or otherwise affects its normal and
        stable functioning;
      </p>
      <p>- do not commit unauthorized access (access attempts) to the Account of other Customers;</p>
      <p>
        - do not post, download or enter into the Internet service "Mesh Align" Content that may be harmful to children;
      </p>
      <p>
        - do not post, download or enter into the Mesh Align Internet service any Content that is inappropriate,
        offensive, harassing, blasphemous, threatening, hateful, indecent, vulgar, defamatory, degrading, infringing or
        violating other people's privacy or is unacceptable for other reasons;;
      </p>
      <p>
        - do not post, download or enter into the Mesh Align Internet service Content that is illegal, or any other
        Content if the Customer does not have the right to distribute it freely;
      </p>
      <p>- do not to impersonate another person or employee of the Contractor or his representative;</p>
      <p>
        - do not try to obtain other Customers' information about their Password, data about their Account, or any other
        private and / or personal information;
      </p>
      <p>- do not use any means of payment for fraudulent purposes without the permission of the authorized owner;</p>
      <p>
        - do not use robots, crawlers or other automated devices to access the Mesh Align Internet service and / or to
        copy its materials;
      </p>
      <p>
        - do not use prohibited programs or other means for fraud, searching for vulnerabilities in the Mesh Align
        Internet service, breaking it in or making changes to it;
      </p>
      <p>
        - do not use vulnerabilities, failures and / or errors detected in the Mesh Align Internet service for illegal
        and / or mercenary purposes;
      </p>
      <p>
        - do not sell, give, exchange, lease, transfer for temporary use or by any other means not alienate your Account
        to other persons;
      </p>
      <p>
        - do not use the Mesh Align Internet service in the country where legislation prohibits the Contractor from
        performing work specified in the Agreement;
      </p>
      <p>
        - do not use the Mesh Align Internet service for political, propaganda, advertising and / or religious purposes;
      </p>
      <p>
        - do not transmit viruses, malware and spyware or any other destructive elements using the Mesh Align Internet
        service.
      </p>
      <p>
        5.2. In the event that the Customer, using the Account, violates Rules of Conduct specified in clause 5.1., but
        does not eliminate or stop such violations within a day after the Contractor sends a written notice warning for
        them to be eliminated or terminated, the Contractor shall have the right to block access to such Account for an
        unlimited time, with its subsequent removal from the Mesh Internet service Align ”, including all content posted
        or uploaded by the Customer.
      </p>
      <p>
        5.3. Using the Internet service "Mesh Align", the Customer guarantees that any of his actions do not contradict
        the national legislation of Ukraine, international law and / or the national legislation of the country of the
        Customer, do not violate any personal non-property or property rights of all persons, including rights to
        confidentiality, intellectual property rights property and copyright.
      </p>

      <p>6. DATA USAGE.</p>

      <p>
        6.1. By concluding the Agreement, the Customer agrees that the Contractor may collect, store and use any data
        about the Customer’s personal computer, or any other similar device that the latter uses to access the Mesh
        Align Internet service, including information about operating systems used on such devices, their IP addresses
        and the like.
      </p>
      <p>
        6.2. The Contractor collects, stores and uses the information specified in clause 6.1. of The Terms solely for
        improving the quality of the functionality of the Mesh Align Internet service, and for eliminating possible
        malfunctions or errors in its operation on personal computers or other similar devices of the Customer
      </p>
      <p>
        6.3. Collection, storage and use of the information, specified in clause 6.1. of the Terms, is implemented by
        the Contractor in accordance with the Privacy Policy.
      </p>
      <p>
        6.4. The Contractor has the right to take any measures that he considers necessary or appropriate to ensure
        compliance with the requirements of the Agreement, the Terms, the Privacy Policy, the national legislation of
        Ukraine, international law and / or the national legislation of the country of the Customer. The Customer
        acknowledges and agrees that the Contractor may, without obligation to the Customer, access, use, store and / or
        disclose personal information and / or the contents of the Customer's Content to law enforcement authorities,
        other public authorities and third parties in the event that the Contractor considers such actions reasonably
        necessary or appropriate, or if necessary in accordance with the national legislation of Ukraine, international
        law and / or national legislation of the country of the Customer, or if the Contractor conscientiously foresees
        that such access, use, disclosure or storage is necessary for:
      </p>
      <p>‐ enforcement of a judgment or other writ of execution;</p>
      <p>- fulfillment of any lawful order by the relevant supervising authority;</p>
      <p>‐ ensuring the implementation of the Agreement, including the investigation of any possible violation;</p>
      <p>
        ‐ identifying, preventing or otherwise responding to threats to national security, fraud or other offenses, or
        technical malfunctions;
      </p>
      <p>
        ‐ protection of the rights, property or safety of the Contractor, other Customers, third parties, society or the
        state, as required by the national legislation of Ukraine, international law and / or national legislation of
        the country of the Customer.
      </p>

      <p>7. TECHNICAL SUPPORT.</p>

      <p>
        7.1. The Contractor provides technical support for the Mesh Align Internet service on issues related to
        functionality, and operational features. Technical support is an activity carried out by the Contractor to the
        extent established to ensure the functioning of the Mesh Align Internet service, including information and
        consulting support regarding its use.
      </p>
      <p>
        7.2. The customer has the right to contact the Contractor's technical support service without paying an
        additional fee.
      </p>
      <p>
        7.3. In order to be able to provide high-quality, timely and effective support, the Contractor has the right to
        require the Customer to provide any information relating to the data of his Account, Profile, Personal Cabinet,
        technical characteristics of his personal computer, or any other similar device that the latter uses to access
        the "Mesh Align" Internet service .
      </p>
      <p>
        7.4. If errors are detected when using the Mesh Align Internet service, the Contractor shall take measures to
        correct them as soon as possible. The Customer agrees that the exact determination of the time period for
        eliminating the error cannot be set in advance, since the Program interacts closely with other third-party
        programs, operating systems and hardware resources of various computers and the operability and elimination of
        problems may depend not only on the Contractor.
      </p>

      <p>8. TO COPYRIGHT HOLDERS.</p>

      <p>
        8.1. If any individual (regardless of citizenship, country of residence, location, race, political views and
        religion) or legal entity (regardless of ownership, legal structure, place of registration) considers that any
        posted, uploaded or entered into Mesh Align Internet Service Content violates his personal non-property or
        property rights, including privacy rights, intellectual property rights and copyrights, rights to personal data,
        etc., such a person, or his duly authorized representative, may at any time contact the Contractor with a
        corresponding written request.
      </p>
      <p>
        8.2. The written appeal shall be presented primarily in Ukrainian, English or Russian, and sent to the
        Contractor at the following email address: meshalign@gmail.com. A written appeal must include:
      </p>
      <p>
        - full name or the name of the person who sent the written request, his contact details (email address for
        correspondence, full phone number, etc.);
      </p>
      <p>
        - if the written request was sent by an authorized representative, it should include copies of documents (or URL
        links to them) that confirm his authority to act on behalf of the owner of the violated rights;
      </p>
      <p>
        - a detailed description of the challenged Content that violates the rights; if possible - the URL links which
        allow to find the above mentioned Content on the Mesh Align Internet service, or other circumstances by which
        such Content can be found and / or identified;
      </p>
      <p>- information about how and what rights violates the Content that is being challenged;</p>
      <p>- information about the person to whom the violated rights belong;</p>
      <p>
        - copies of any official documents (or URL links to them) confirming the ownership of the violated rights to the
        relevant person;
      </p>
      <p>
        - and understandable wording of the relevant requirement to eliminate and / or terminate violations of rights.
      </p>
      <p>
        8.3. If necessary, for a proper and objective consideration of the written appeal, the Contractor has the right
        to request or require from the person who sent it any additional information and / or additional documents.
      </p>
      <p>
        8.4. If feasible, and apart from force majeure circumstances, the Contractor shall make every effort to ensure
        that the written appeal is considered within ten business days from the date of its receipt. The Contractor
        shall send a message with the results of such a review in writing to the e-mail address of the person who sent
        the written request, or shall inform her of this by telephone, if there is a full telephone number by which such
        a person can be contacted.
      </p>

      <p>9. PROMOTIONAL MATERIAL.</p>

      <p>
        9.1. Using the "Mesh Align" Internet service, the Customer acknowledges, does not deny and agrees with the
        following: The Contractor has the right to use the Mesh Align Internet service interface and functionality to
        demonstrate any advertising materials to the Customer at any time..
      </p>
      <p>
        9.2. By concluding the Agreement, the Customer gives his unconditional consent to demonstrate any advertising
        materials to the latter on the Mesh Align Internet service, without paying the Customer remuneration and / or
        compensation to the Customer from the Contractor or other persons.
      </p>

      <p>10. COMMERCIAL USE.</p>

      <p>
        10.1. The Customer is prohibited from using the Mesh Align Internet service for commercial purposes on the
        general terms set forth in the Agreement, these Terms and the Privacy Policy
      </p>
      <p>
        10.2. If the Customer wishes, on his own behalf, or on behalf of other persons, to distribute certain commercial
        information using the Mesh Align Internet service and / or to offer certain goods, services or work for
        commercial purposes, he may contact the Contractor with a corresponding written notice, setting out his requests
        and suggestions in this regard.
      </p>
      <p>
        10.3. Based on the results of consideration of the Customer's written request, the Contractor may allow the
        commercial use of the Mesh Align Internet service solely on the basis and on the terms of a written
        (non-electronic) agreement concluded separately between the Customer and the Contractor, which has no relation
        to the Agreement, these Terms and Privacy policy.
      </p>

      <p className="font-bold">DETAILS AND CONTACT INFORMATION OF THE CONTRACTOR:</p>
      <p className="font-bold">INDIVIDUAL ENTREPRENEUR POGARSKYI ANTON YURIEVICH</p>
      <p>
        Date and number of entries in the Unified State Register of Legal Entities, Individual Entrepreneurs and Public
        Associations: 28.03.2018, № 21030000000096301.
      </p>
      <p>
        Address (place of actual residence): 69600, Ukraine, Zaporizhzhya Region, Zaporizhzhya city, Kommunarsky
        district, ul. Reconstructive, 3.
      </p>
      <p>Taxpayer registration card registration number: 2987814459.</p>
      <p>Email address: meshalign@gmail.com</p>
      <p>
        Opening hours: from Monday to Friday every week (except for global and Ukrainian holidays), from 10 o’clock. 00
        minutes until 5 p.m. 00 minutes"
      </p>
      <p>Tel.: + 38 097 977 39 98</p>
    </section>
  </div>
);

export default TermsOfUseEn;
