import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";
import "./index.scss";

const ArrowBack = ({ path, text }) => {
  const { t: _t } = useTranslation();

  return (
    <Link className="arrowBack" to={path}>
      <ChevronLeft className="icon" />
      <span>{text ? text : _t("Back")}</span>
    </Link>
  );
};

export default ArrowBack;
