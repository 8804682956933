import React from "react";
import { Link } from "react-router-dom";
import ArrowBack from "../../ArrowBack";
import "./index.scss";

const Policy = () => (
  <div className="classification">
    <div className="overlay" />
    <header className="header">
      <Link className="logo" to="/">
        <span className="dark">Mesh</span>
        <span className="pink">Align</span>
      </Link>
    </header>
    <section>
      <ArrowBack path="/" text="Back to home page" />
      <h2>ELECTRONIC MESSAGE</h2>
      <p className="font-bold text-center">before using the Mesh Align internet service</p>

      <p className="font-bold">DEAR CUSTOMER!</p>

      <p className="f-18">
        PLEASE READ CAREFULLY THE PRIVACY POLICY (hereinafter referred to as the "Policy"), WHICH IS AN INTEGRAL PART OF
        THE ELECTRONIC PERFORMANCE AGREEMENT (hereinafter referred to as the "Contract"), GIVEN AT:
        https://meshalign.com/electronic-contract.
      </p>
      <p className="f-18">
        By ACCEPTING A PUBLIC OFFER TO CONCLUDE THE AGREEMENT, YOU AUTOMATICALLY ACCEPT THE PRIVACY POLICY AND AGREE TO
        ABIDE BY IT.
      </p>
      <p className="f-18">THIS ELECTRONIC MESSAGE IS AN INTEGRAL PART OF THE CONTRACT.</p>

      <p className="font-bold text-center">PRIVACY POLICY</p>

      <p>April 27, 2020</p>

      <p>
        This Privacy Policy (hereinafter referred to as the “Policy”) determines the conditions for the placement,
        download and / or inclusion in any sections of the Mesh Align Internet service (hereinafter referred to as the
        Mesh Align Internet service) of personal data of individuals or other confidential information, as well as the
        conditions for the processing, use, dissemination, movement and protection of such data and information. This
        Policy is an integral part of the electronic Contract for the performance of work (hereinafter referred to as
        the “Contract”), which is given at: https://meshalign.com/electronic-contract.
      </p>

      <p>1. KEY TERMS AND DEFINITIONS.</p>

      <p>
        1.1. “Authorization” is the electronic process of the Customer entering his Account, during which the latter is
        checked for the rights of the latter to provide him with appropriate access to such an Account. For
        authorization, the Customer must enter their identifiers in the appropriate electronic form and confirm their
        intention to log in to their account.
      </p>
      <p>
        1.2. “Account” is the electronic information about the Customer that the latter places, downloads or enters in
        the Mesh Align Internet service, and which include the Account, Profile, and Personal Account. An integral part
        of the Account is also Content. To use the Mesh Align Internet service and to be able to carry out work orders,
        you need an Account.
      </p>
      <p>
        1.3. “Acceptance” is full and unconditional acceptance by the Customer of the Contractor’s proposal (offer) to
        conclude this Agreement on the terms and conditions indicated by the Contractor (clauses 3.1. and 3.2. of the
        Agreement).
      </p>
      <p>1.4. "Identifiers" are unique login and password that are created personally by the Customer.</p>
      <p>
        1.5. “Internet” is a global information network of general access based on Internet protocols defined by
        international standards.
      </p>
      <p>
        1.6. “Content” means text (written) or video materials, audio recordings or images, as well as any other
        information that is posted, downloaded or entered by the Customer into any sections of the Mesh Align Internet
        service. For the purposes of this Agreement, the Content, including, but not exclusively, also includes the
        corresponding electronic text messages, electronic medical records of patients, any electronic computer files
        uploaded to the Mesh Align Internet service, any electronic digital images, any electronic digital video, any
        information, and any personal data of all persons. Content is an integral part of the Account.
      </p>
      <p>
        1.7. “Login” is a unique character set that identifies the Customer in the Mesh Align Internet service, given as
        an email address, which is in the unhindered and personal use of the Customer. Login is a part of the Account.
        The Contractor has the right to prohibit the use of certain Logins, as well as establish requirements on them
        (length, valid characters, etc.).
      </p>
      <p>
        1.8. “Account” is a combination of information about the Customer posted, uploaded or entered into the Mesh
        Align Internet service.
      </p>
      <p>
        1.9. “Personal cabinet” is a section of the Mesh Align Internet service that can only be accessed by a
        registered Customer after completing the electronic Authorization procedure using the Identifier, and through
        which the Customer manages the account.
      </p>
      <p>
        1.10. "Password" is a secret word or a specific sequence of characters designed to confirm the identity of the
        Customer and his rights. The password is used to protect the information available in the Mesh Align Internet
        service from unauthorized access by any unauthorized persons. The password is an element of the account, and is
        used by the Mesh Align Internet service to provide the Customer with permission to enter his account. The
        Contractor has the right to prohibit the use of certain Passwords, as well as establish requirements on them
        (length, valid characters, etc.).
      </p>
      <p>
        1.11. “Written notice” is an electronic letter that may include text (written) or video materials, audio
        recordings or images sent by any Party to this Agreement to each other to the appropriate email address. For the
        purposes of this Agreement, the Customer’s email address is the address indicated last as his Login, and the
        Contractor’s email address is the address specified in this Agreement among other contact details of the latter.
        A written notice may be in Ukrainian, English or Russian.
      </p>
      <p>
        1.12. «Profile» is a collection of personal information about the Customer specified by the latter when
        registering in the Mesh Align online service by filling out the appropriate registration form.
      </p>
      <p>
        1.13. Mesh Align Internet service is a website posted on the Internet at https://meshalign.com, by which the
        Customer has the opportunity to order the Contractor to perform work under this Agreement.
      </p>
      <p>
        1.14. “Registration” is an electronic procedure during which the Customer fills out the appropriate registration
        form and creates an Account in the Mesh Align Internet service. For the purposes of this Agreement, Registration
        is the Customer’s action, which is considered complete and unconditional acceptance of the Contractor’s offer to
        conclude this Agreement.
      </p>

      <p>2. GENERAL PROVISIONS.</p>

      <p>
        1. The Contractor respects each person's rights to confidentiality of information, realizes the importance of
        their observance, and undertakes to take all possible legal, organizational and technical measures to protect
        such information from unauthorized and / or illegal use.
      </p>
      <p>2. This Policy defines the following:</p>
      <p>- confidential information that the Contractor collects;</p>
      <p>- for what purposes the Contractor collects confidential information;</p>
      <p>- how the Contractor retains confidential information;</p>
      <p>- how long the Contractor retains confidential information;</p>
      <p>- how the Contractor uses confidential information;</p>
      <p>- how the Contractor disseminates confidential information;</p>
      <p>- how the Contractor discloses confidential information;</p>
      <p>- the rights of the Customer and other individuals (people) to confidential information;</p>
      <p>- message about «cookies».</p>
      <p>
        3. For the purposes of this Policy, confidential information refers to any information about a particular person
        that may be collected, stored, used, distributed, transmitted and disclosed in the manner specified by him, with
        his consent, and in accordance with the conditions stipulated by him.
      </p>
      <p>4. For the purposes of this Policy, the confidential information includes:</p>
      <p>
        - personal data of a certain person (information or data collection about an individual that is identified or
        can be specifically identified, for example: last name, first name, middle name, passport or identification card
        number, date of birth, place of birth, place of residence, place of registration, information about residence
        permit, phone number, race and nationality, biological gender, political views, religion, sexual orientation,
        biometric data - physical, physiological or behavioral signs of an individual with the help of which it is
        possible to uniquely identify a person, image of a human face, fingerprints, voice recording, data on the
        physical or mental state of a person’s health, medical tests and conclusions, genetic data - inherited or
        acquired genetic characteristics of an individual, provide unique information about physiology or health status,
        relevant biological samples, medical reports, medical examinations and / or studies etc.);
      </p>
      <p>- information about the Account and Password of the Customer;</p>
      <p>
        - information that is automatically transmitted to the Mesh Align Internet service using cookies (for example:
        email address, IP address and connection parameters, date and time of visit to Internet resources, history and
        parameters of such visits, name of browsers, etc. )
      </p>
      <p>
        5. By concluding the Agreement, the Customer confirms that he is aware of the following: posting, downloading or
        inclusion to the Mesh Align Internet service, as well as processing, use, distribution and movement by the
        Customer of Content that contains any confidential information about certain people, including their personal
        data is carried out exclusively and directly under the personal responsibility of the Customer, during which the
        latter must adhere to the requirements of Ukraine's national legislation, international law, national
        legislation of the country of the Customer and the European Parliament's General Regulations and the Data
        Protection Council.
      </p>

      <p>
        1. The customer must independently inform a certain person about why the customer needs confidential
        information, how it will be collected, stored, used, distributed, transmitted and disclosed in the Mesh Align
        Internet service, who exactly and how will have access to it via the Mesh Align Internet service. Thus, the
        Customer must independently obtain permission from a certain person to collect, use, distribute, transmit and
        disclose confidential information and / or personal data about him using the Mesh Align Internet service.
        Providing the Contractor through the Mesh Align Internet service any confidential information and / or personal
        data in relation to other persons (people) - the Customer guarantees that he has received appropriate permission
        from such persons (people) to collect, use, distribute, transmit and disclose confidential information and / or
        personal data on it using the Mesh Align Internet service.
      </p>
      <p>
        2. The Contractor does not require, does not initiate and does not control the placement, downloading or
        inclusion into the Program by the Customer of any Content that composes confidential information and / or
        personal data of a particular person, does not affect the content and integrity of such information, and also
        does not know and cannot know whether such information is obtained legally and / or with the consent of the
        person concerned, and whether it violates the rights and interests of third parties protected by law, the
        requirements of the national legislation of Ukraine, international law and national legislation of the country
        and of the European Parliament and the General Regulations for the Protection of Personal Data Council.
      </p>
      <p>
        5. By concluding the Agreement, the Customer provides the Contractor with unconditional consent to the
        collection, storage, use, distribution, transfer and disclosure of confidential information about the Customer,
        solely on the terms, in the order, and within the framework of this policy. At the same time, the Customer
        confirms that the conditions and procedure set forth in this Policy for the collection, use, distribution,
        transfer and disclosure of confidential information - satisfy the latter, as determined by himself, and all
        confidential information about him - is provided to them voluntarily, without any coercion on the part of the
        Contractor and / or third parties, not under the influence of fraud and / or error, and without any physical and
        / or psychological pressure.
      </p>

      <p>1. WHAT KIND OF PERSONAL DATA THE CONTRACTOR COLLECTS</p>

      <p>
        1.1. In order to be able to use the Mesh Align Internet service, as a whole, and its individual sections, as
        well as to be able to provide appropriate technical support and improve the performance of the Mesh Align
        Internet service, the Contractor may invite the Customer to provide some confidential information about himself.
        At the same time, the Contractor guarantees that he will collect only that confidential information that is
        necessary for certain, specific, explicit and legal purposes.
      </p>
      <p>
        1.2. The customer has the right to decide whether to provide the Contractor with confidential information about
        himself or not. It may happen that in case of refusal to provide certain confidential information the Customer
        will not be able to use the Mesh Align Internet service and / or its individual sections. The need to provide
        certain confidential information about the Customer, as well as the corresponding consequences in case the
        latter refuses to provide it, can be indicated directly in the Mesh Align Internet service..
      </p>
      <p>
        1.3. By concluding the Agreement, the Customer confirms that he is aware of the following: The Contractor does
        not require the Customer to provide any information about his payment data and tools, bank accounts, payment
        cards, as well as any other information necessary to pay money. The Contractor shall in no case be liable for
        the safety or unlawful use of the above mentioned or similar information, even if such information was received
        by the Contractor accidentally or due to intentional or erroneous actions of the Licensee.
      </p>
      <p>1.4. The Contractor may collect the following confidential information about the Customer:</p>
      <p>- E-mail address</p>
      <p>- surname, name and patronymic;</p>
      <p>- name of the position and / or place of work;</p>
      <p>- telephone number for communication;</p>
      <p>- country of location;</p>
      <p>
        - a digital photograph showing the face of the Customer, or which contains any other image by which you can
        identify the face of the Customer.
      </p>
      <p>
        3.5. The Contractor may collect the following confidential information about other persons (people) that is
        provided by the Customer when using the Mesh Align Internet service, subject to the requirements of clauses
        2.5., 2.6. and 2.7. of this Policy:
      </p>
      <p>- surname, name and patronymic;</p>
      <p>- name of the position and / or place of work;</p>
      <p>- place of residence; </p>
      <p>- phone number;</p>
      <p>- passport data;</p>
      <p>- insurance number;</p>
      <p>
        - a digital photograph showing the face, or which contains any other image by which such a face can be
        identified;
      </p>
      <p>
        - any electronic computer (digital) files that contain the results of a medical examination and / or examination
        of a certain person (person), as a whole, and individual parts of the body (for example, teleroentgenogram, CT
        scan, orthopantomography, digital three-dimensional image of the upper and / or lower jaw, digital
        three-dimensional image of the skull, ultrasound, magnetic resonance imaging, etc.);
      </p>
      <p>
        - any other confidential information and / or personal data regarding a specific person (person) that the
        Customer places, uploads or enters into the Mesh Align Internet service.
      </p>

      <p>2. FOR WHAT PURPOSES THE CONTRACTOR COLLECTS PERSONAL DATA</p>

      <p>
        2.1. Confidential information about the Customer is provided to the Contractor for the possibility of creating
        an Account (including an account) of the Customer, with which the latter uses the Mesh Align Internet service,
        and manages the account, as well as:
      </p>
      <p>
        - the possibility of receiving from the Customer orders for the implementation of the relevant Work under the
        Agreement, the direct implementation of such Work and the transfer of its results to the Customer;
      </p>
      <p>
        - the possibility of identifying the Customer’s identity in the case of providing technical or information and
        consulting support regarding the use of the Mesh Align Internet service.
      </p>

      <p>
        2.2. Confidential information about other persons that is provided by the Customer when using the Mesh Align
        Internet service, subject to the requirements of clauses 2.5., 2.6. and 2.7. of this Policy, may be collected by
        the Contractor solely for the purpose of identifying certain individuals (people) and their clinical diseases,
        in order to be able to perform certain Work that the Customer ordered under the Contract.
      </p>

      <p>3. HOW PERSONAL DATA IS STORED BY THE CONTRACTOR.</p>

      <p>
        3.1. Confidential information about the Customer, or about another person (person) posted, uploaded or entered
        into the Mesh Align Internet service by the Customer is stored in the relevant computer files, which are an
        integral part of the Mesh Align Internet service that is located on external servers in the form of a SaaS
        version.
      </p>
      <p>
        3.2. The Contractor uses “cloud” server data processing technologies, according to which the physical location
        of computer files, which are an integral part of the Mesh Align Internet service, can change dynamically from
        time to time, which provides increased protection of such data from unauthorized and / or illegal use. The
        current location of such files, which contain confidential information of the Customer, or another person, can
        be found directly from the Contractor, by sending a written notice with the appropriate request.
      </p>
      <p>
        3.3. Pursuant to the requirements of the national legislation of Ukraine, international law and the national
        legislation of the Customer’s country and the General Rules of the European Parliament and the Council for the
        Protection of Personal Data, the Contractor undertakes to conclude appropriate agreements on the processing of
        confidential information with the relevant cloud server service providers, which the Contractor will engage for
        "Mesh Align» data processing service.
      </p>
      <p>
        3.4. The Contractor uses the HTTPS data transfer protocol in the Mesh Align Internet service, which supports
        data protection during transportation by encrypting information in accordance with SSL and TLS standards,
        designed to provide a more secure connection between the server and the Customer.
      </p>

      <p>4. HOW LONG DOES THE CONTRACTOR STORE PERSONAL INFORMATION.</p>

      <p>
        4.1. Confidential information about the Customer, or about another person (person) is maintained by the
        Contractor during the entire time the Mesh Align Internet service is functioning.
      </p>
      <p>
        4.2. Confidential information about the Customer, or another person may be deleted from the Mesh Align Internet
        service at any time, based on the requirements of this Policy or the Terms of Use.
      </p>

      <p>5. HOW DOES THE CONTRACTOR USE PERSONAL INFORMATION.</p>

      <p>
        5.1. The Contractor uses confidential information exclusively to ensure the operability of the Mesh Align
        Internet service and the convenience of its use by the Customer, in accordance with the requirements of clause
        4.1. and 4.2. of this Policy, as well as for the ability to fulfill the obligations of the Parties under the
        Agreement.
      </p>

      <p>1. DISSEMINATION OF PERSONAL INFORMATION.</p>

      <p>
        1.1. Confidential information about the Customer or another individual posted, uploaded or entered into the Mesh
        Align Internet service by the Customer remains in the Mesh Align Internet service and is not distributed in any
        way, including on the Internet, or through other third-party resources or other third-party software.
      </p>

      <p>6. DISCLOSURE OF PERSONAL INFORMATION.</p>

      <p>
        6.1. The Contractor may disclose confidential information only if required by the national legislation of
        Ukraine, international law, the national legislation of the country of the Customer, and the General Rules of
        the European Parliament and the Council for the Protection of Personal Data. In any other cases - the Contractor
        under no circumstances and in any situation does not disclose confidential information to unauthorized persons.
      </p>

      <p>7. THE RIGHTS OF THE CONTRACTOR AND OTHER INDIVIDUALS (PEOPLE) REGARDING PERSONAL INFORMATION.</p>

      <p>
        1. The Contractor respects the right of every person to the inviolability of his private life, and the ability
        to control the circulation of personal data about himself.
      </p>
      <p>
        1. Depending on the requirements of the national legislation of Ukraine, international law, the national
        legislation of the country of the Customer or the country of a particular individual (person) and the General
        Rules of the European Parliament and the Council for the Protection of Personal Data, the Customer and any other
        individual (person) has the right to access, rectification and / or the removal of confidential information
        about himself.
      </p>
      <p>
        2. The Contractor requires that the request of the Customer or any other individual for access, correction and /
        or deletion of confidential information shall satisfy the current national legislation of Ukraine, international
        law, the national legislation of the country of the Customer, and the General Rules of the European Parliament
        and the Council for the Protection of Personal Data . In addition, the Contractor requires that such person
        provides sufficient information in order to verify his identity and to make sure that the applicant is a subject
        or legally authorized person for the requested confidential information.
      </p>
      <p>3. The customer or any other individual has the right:</p>
      <p>
        - to receive clear, clear and understandable information about how the Contractor uses confidential information;
      </p>
      <p>- to receive a copy of his own personal confidential data for free;</p>
      <p>- to correct or supplement their personal confidential information for the purpose of use;</p>
      <p>- to delete confidential information about himself from the Mesh Align Internet service.</p>

      <p>1. COOKIES NOTIFICATION MESSAGE.</p>

      <p>
        11.1. Using the Internet service "Mesh Align", the Customer agrees with the following procedure for the use of
        cookies by the Contractor. If the Customer does not agree with the following procedure for the use of cookies by
        the Contractor, he must disable the appropriate cookie settings in the browser that he plans to use for the Mesh
        Align Internet service, or completely refuse to use it.{" "}
      </p>

      <p>
        ATTENTION! Disabling the appropriate browser cookie settings may result in the decrease of quality of using the
        Mesh Align Internet service.
      </p>
      <p>11.2. What are «cookies»?</p>
      <p>
        Cookies are small text files that are stored in the browser of a specific computer or mobile phone after
        visiting any websites. Cookies are widely used to maintain websites or to enhance the user's experience, as well
        as to provide certain information to website owners.
      </p>
      <p>11.3. How to enable or disable «cookies»?</p>
      <p>
        At any time, the Customer in the settings of his browser that he plans to use for the Mesh Align Internet
        service, may allow the use of all cookies, set warnings about the use (storage) of cookies in the corresponding
        browser of the corresponding device, or completely prohibit the use of cookies. In the settings of a specific
        browser, you can prohibit any cookies or only certain cookies. Since all browsers are different from each other,
        more detailed information on cookie settings is given in the corresponding section of a specific browser.
        Changing browser settings and cookie settings depends on the browser. If necessary, the Customer can refer to
        browser Help section.
      </p>
      <p>11.4. Why does the Contractor use cookies?</p>
      <p>
        Cookies are used by the Contractor solely to improve the convenience and quality of the Customer’s use of the
        Mesh Align Internet service through appropriate browsers.
      </p>
      <p>11.5. Managing cookies using browser settings</p>
      <p>
        Most browsers are configured to automatically use cookies. If the Customer does not want the website to save
        cookies on his device, you need to change your browser settings so that you receive warnings every time you try
        to save cookies of a certain type. The customer can also change the settings to prevent the browser from
        receiving most cookies or only some third-party cookies.{" "}
      </p>
      <p>11.6. What cookies does the Contractor use?</p>
      <p>Cookies used by the Contractor can be divided into the following categories:</p>
      <p>- cookies that allow to identify the Customer;</p>
      <p>
        - cookies that are necessary to improve the efficiency of the Mesh Align Internet service (not allowing to
        identify the identity of the Customer)
      </p>
      <p>- cookies that are used to expand functionality (may contain personal data provided by the Customer)</p>
      <p>
        - cookies that are associated with the display of targeted advertising and advertisements using the interface of
        the Mesh Align Internet service, by device identifier or IP address (they can collect personal data).{" "}
      </p>
      <p>11.7. Cookie storage duration. </p>
      <p>
        Session cookies (temporary) stored on the Customer’s device until he leaves the Mesh Align Internet service.
      </p>
      <p>Persistent cookies remain on the Customer’s device for a longer period or until they are manually deleted.</p>

      <p className="font-bold">DETAILS AND CONTACT INFORMATION OF THE CONTRACTOR:</p>
      <p className="font-bold">INDIVIDUAL ENTREPRENEUR POGARSKYI ANTON YURIEVICH</p>
      <p>
        Date and number of entries in the Unified State Register of Legal Entities, Individual Entrepreneurs and Public
        Associations: 28.03.2018, № 21030000000096301.
      </p>
      <p>
        Address (place of actual residence): 69600, Ukraine, Zaporizhzhya Region, Zaporizhzhya city, Kommunarsky
        district, ul. Reconstructive, 3.
      </p>
      <p>Taxpayer registration card registration number: 2987814459.</p>
      <p>Email address: meshalign@gmail.com</p>
      <p>
        Opening hours: from Monday to Friday every week (except for global and Ukrainian holidays), from 10 o’clock. 00
        minutes until 5 p.m. 00 minutes"
      </p>
      <p>Tel.: + 38 097 977 39 98.</p>
    </section>
  </div>
);

export default Policy;
