import React from "react";
import { Link } from "react-router-dom";
import ArrowBack from "../../ArrowBack";
import "./index.scss";

const PolicyRu = () => (
  <div className="classification">
    <div className="overlay" />
    <header className="header">
      <Link className="logo" to="/">
        <span className="dark">Mesh</span>
        <span className="pink">Align</span>
      </Link>
    </header>
    <section>
      <ArrowBack path="/" text="Назад на главную страницу" />
      <h2>ЭЛЕКТРОННОЕ СООБЩЕНИЕ</h2>
      <p className="font-bold text-center">ПЕРЕД ИСПОЛЬЗОВАНИЕМ ИНТЕРНЕТ-СЕРВИСА «MESH ALIGN»</p>

      <p className="font-bold">УВАЖАЕМЫЙ ЗАКАЗЧИК!</p>

      <p className="f-18">
        ПОЖАЛУЙСТА, ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ С НИЖЕСЛЕДУЮЩЕЙ ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ (ДАЛЕЕ ПО ТЕКСТУ -
        «ПОЛИТИКА»), КОТОРАЯ ЯВЛЯЕТСЯ НЕОТЪЕМЛЕМОЙ ЧАСТЬЮ ЭЛЕКТРОННОГО ДОГОВОРА НА ВЫПОЛНЕНИЕ РАБОТ (ДАЛЕЕ ПО ТЕКСТУ -
        «ДОГОВОР»), УКАЗАННЫЙ ПО АДРЕСУ: https://meshalign.com/electronic-contract.
      </p>
      <p className="f-18">
        ПРИНИМАЯ (АКЦЕПТУЯ) ПУБЛИЧНОЕ ПРЕДЛОЖЕНИЕ ЗАКЛЮЧИТЬ ДОГОВОР, ВЫ АВТОМАТИЧЕСКИ ПРИНИМАЕТЕ ПОЛИТИКУ И СОГЛАСИЕ
        ДОБРОСОВЕСТНО И НЕУКЛОННО ЕЕ ВЫПОЛНЯТЬ.
      </p>
      <p className="f-18">ДАННОЕ ЭЛЕКТРОННОЕ СООБЩЕНИЕ ЕСТЬ НЕОТЪЕМЛЕМОЙ ЧАСТЬЮ ДОГОВОРА.</p>

      <p className="font-bold text-center">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</p>

      <p>Редакция от: 27.04.2020 года.</p>

      <p>
        Данной Политикой конфиденциальности (далее по тексту - «Политика») определяются условия размещения, загрузки
        и/или введения в любых разделах интернет-сервиса «Mesh Align» (далее по тексту - интернет-сервис «Mesh Align»)
        персональных данных любых физических лиц или другой конфиденциальной информации, а также условия обработки,
        использования, распространения, перемещения и защиты таких данных и информации. Данная Политика является
        неотъемлемой частью электронного Договора на выполнение работ (далее по тексту - «Договор»), который указан по
        адресу: https://meshalign.com/electronic-contract.
      </p>

      <p>1. Основные термины и определения.</p>

      <p>
        1.1. «Авторизация» - электронный процесс входа Заказчика в его Аккаунт, во время которого происходит проверка
        наличия прав последнего на предоставление ему соответствующего доступа к такому Аккаунту. Для авторизации
        Заказчик должен ввести в соответствующую электронную форму свои идентификаторы, и подтвердить намерение
        осуществить вход в свой аккаунт.
      </p>
      <p>
        1.2. «Аккаунт» - совокупность электронных сведений о Заказчике, которые последний размещает, загружает или
        вводит в интернет-сервисе «Mesh Align», и которые включают в себя Учетную запись, Профиль и Личный кабинет.
        Составной частью Аккаунта является также Контент. Для использования интернет-сервиса «Mesh Align» и возможности
        осуществлять заказ на выполнение работ - нужен Аккаунт.
      </p>
      <p>
        1.3. «Акцепт» - полное и безусловное принятие Заказчиком предложения (оферты) Исполнителя заключить настоящий
        Договор на определенных им условиях (пункты 3.1. И 3.2. Договора).
      </p>
      <p>1.4. «Идентификаторы» - уникальные логин и пароль, которые создаются лично Заказчиком.</p>
      <p>
        1.5. «Интернет» - глобальная информационная сеть общего доступа, основанная на Интернет-протоколах, определенных
        международными стандартами.
      </p>
      <p>
        1.6. «Контент» - текстовые (письменные) или видео материалы, аудиозаписи или изображения, а также любые другие
        материалы или информация, которая размещается загружается или вводится Заказчиком в любые разделы
        интернет-сервиса «Mesh Align». Для целей настоящего Договора, Контентом, в том числе, но не исключительно, также
        есть соответствующие электронные текстовые сообщения, электронные медицинские карты пациентов, любые загруженные
        в интернет-сервис «Mesh Align» электронные компьютерные файлы любого формата, любые электронные цифровые
        изображения, любое электронное цифровое видео, любая информация, и любые персональные данные всех лиц. Контент
        является составной частью Аккаунта.
      </p>
      <p>
        1.7. «Логин» - уникальный набор символов, который идентифицирует Заказчика в интернет-сервисе «Mesh Align»,
        выраженный в виде адреса электронной почты, находится в беспрепятственном и личном использовании Заказчика. Имя
        является элементом учетной записи. Исполнитель имеет право запретить использование определенных Логинов, а также
        устанавливать по ним требования (длина, допустимые символы и др.).
      </p>
      <p>
        1.8. «Учетная запись» - совокупность размещенной, загруженной или введенной в интернет-сервис «Mesh Align»
        информации о Заказчика.
      </p>
      <p>
        1.9. «Личный кабинет» - раздел интернет-сервиса «Mesh Align», доступ к которому имеет только зарегистрированный
        Заказчик после прохождения электронной процедуры авторизации с помощью идентификатора, и через который Заказчик
        осуществляет управление учетной записью.
      </p>
      <p>
        1.10. "Пароль" - тайное слово или определенная последовательность символов, предназначенных для подтверждения
        личности Заказчика и его прав. Пароль используется для защиты информации, имеющейся в интернет-сервисе «Mesh
        Align» от несанкционированного доступа любых посторонних лиц. Пароль является элементом учетной записи, и
        используется интернет-сервисом «Mesh Align» для предоставления Заказчику разрешения на вход в его аккаунт.
        Исполнитель имеет право запретить использование определенных Паролей, а также устанавливать по ним требования
        (длина, допустимые символы и др.).
      </p>
      <p>
        1.11. «Письменное уведомление» - электронное письмо, может включать в себя текстовые (письменные) или видео
        материалы, аудиозаписи или изображения, оставленный любой Стороной настоящего Договора друг другу на
        соответствующий адрес электронной почты. Для целей настоящего Договора, адресом электронной почты Заказчика
        является адрес, указанный последним в качестве его Логина, а адресом электронной почты Исполнителя является
        адрес, указанный в настоящем Договоре среди других контактных данных последнего. Письменное уведомление может
        быть изложено на украинском, английском или русском языках.
      </p>
      <p>
        1.12. «Профиль» - совокупность персональных сведений о Заказчике, указанных последним при Регистрации в
        интернет-сервисе «Mesh Align» путем заполнения соответствующей регистрационной формы.
      </p>
      <p>
        1.13. Интернет-сервис «Mesh Align» - веб-сайт, размещенный в сети Интернет по адресу https://meshalign.com, с
        помощью которого Заказчик имеет возможность заказывать у Исполнителя выполнения работ по настоящему Договору.
      </p>
      <p>
        1.14. «Регистрация» - электронная процедура, во время которой Заказчик заполняет соответствующую регистрационную
        форму, и создает в интернет-сервисе «Mesh Align» Аккаунт. Для целей настоящего Договора, Регистрация является
        действием Заказчика, которая считается полным и безусловным принятием предложения (оферты) Исполнителя заключить
        настоящий Договор.
      </p>

      <p>2. Общие положения.</p>

      <p>
        2.1. Исполнитель уважает права каждого человека на конфиденциальность информации о ней, осознает важность их
        соблюдения, и обязуется принимать все возможные правовые, организационные и технические меры по защите такой
        информации от несанкционированного и / или противоправного использования.
      </p>
      <p>2.2. Данная Политика определяет следующее:</p>
      <p>- которую Исполнитель собирает конфиденциальную информацию;</p>
      <p>- для каких целей Исполнитель собирает конфиденциальную информацию;</p>
      <p>- как Исполнитель сохраняет конфиденциальную информацию;</p>
      <p>- как долго Исполнитель сохраняет конфиденциальную информацию;</p>
      <p>- как Исполнитель использует конфиденциальную информацию;</p>
      <p>- как Исполнитель распространяет конфиденциальную информацию;</p>
      <p>- как Исполнитель раскрывает конфиденциальную информацию;</p>
      <p>- права Заказчика и других физических лиц (людей) по конфиденциальной информации;</p>
      <p>- сообщение о файлах «маркеры».</p>
      <p>
        2.3. Для целей данной Политики, под конфиденциальной информацией понимается любая информация об определенном
        человеке, которая может собираться, храниться, использоваться, распространяться, передаваться и раскрываться в
        определенном им порядке, с его согласия, и в соответствии с предусмотренными ею условиями.
      </p>
      <p>2.4. Для целей данной Политики, в состав конфиденциальной информации входит:</p>
      <p>
        - персональные данные по определенному человеку (сведения или совокупность сведений о физическом лице, которое
        идентифицировано или может быть конкретно идентифицировано, например: фамилия, имя, отчество, номер паспорта или
        идентификационного удостоверения, дата рождения, место рождения, место жительства, место регистрации, информация
        о прописке, номер телефона, раса и национальность, биологический пол, политические взгляды, вероисповедание,
        сексуальная ориентация, биометрические данные - физические, физиологические или поведенческие признаки
        физического лица, с помощью которых возможно однозначно идентифицировать человека, изображение человеческого
        лица, отпечатки пальцев, запись голоса, данные о физическом или психическом состоянии здоровья человека,
        медицинские анализы и выводы, генетические данные - унаследованные или приобретенные генетические признаки
        физического лица, предоставляют уникальную информацию о физиологии или состояние здоровья, соответствующие
        биологические образцы, медицинские заключения, результаты медицинских обследований и/или исследований и т.д.);
      </p>
      <p>- информация об Учетной записи, Пароле Заказчика;</p>
      <p>
        - информация, которая автоматически передается интернет-сервисом «Mesh Align» с помощью файлов "cookie,»
        (например: адрес электронной почты, IP-адрес и параметры соединения, дата и время посещения интернет ресурсов,
        история и параметры таких посещений, название браузеров , тому подобное).
      </p>
      <p>
        2.5. Заключая Договор, Заказчик подтверждает, что он осведомлен относительно следующего: размещение, загрузки
        или введение в интернет-сервис «Mesh Align», а также обработка, использование, распространение и перемещение
        Заказчиком Контента, который содержит в себе любую конфиденциальную информацию по определенным людям, включая их
        персональные данные - осуществляется исключительно и непосредственно под персональную ответственность Заказчика,
        во время которого последний должен придерживаться предписаний национального законодательства Украины,
        международного права, национального законодательства страны Заказчика и Генерального регламента Европейского
        парламента и Совета по защите персональных данных.
      </p>
      <p>
        2.6. Заказчик должен самостоятельно проинформировать определенного человека о том, для чего именно Заказчику
        нужна конфиденциальная информация, каким образом она будет собираться, храниться, использоваться,
        распространяться, передаваться и раскрываться в интернет-сервисе «Mesh Align», кто именно и каким образом к ней
        будет иметь доступ с помощью интернет-сервиса «Mesh Align». И так, Заказчик должен самостоятельно получить от
        определенного человека разрешение на сбор, использование, распространение, передачу и раскрытие конфиденциальной
        информации и/или персональных данных по ней с помощью интернет-сервиса «Mesh Align». Предоставляя Исполнителю
        через интернет-сервис «Mesh Align» любую конфиденциальную информацию и/или персональные данные в отношении
        других лиц (людей) - Заказчик гарантирует, что получил от таких лиц (людей) соответствующее разрешение на сбор,
        использование, распространение, передачу и раскрытие конфиденциальной информации и/или персональных данных по
        ней с помощью интернет-сервиса «Mesh Align».
      </p>
      <p>
        2.7. Исполнитель не требует, не инициирует и не контролирует размещение, загрузки или введение в Программу
        Заказчиком любого Контента, который составляет конфиденциальную информацию и/или персональные данные по
        определенному человеку, не влияет на содержание и целостность такой информации, а также не знает и не может
        знать – получена такая информация законно и/или с согласия соответствующего человека, и нарушает она охраняемые
        законом права и интересы третьих лиц, предписания национального законодательства Украины, международного права и
        национального законодательства страны Заказчика, и Генерального регламента Европейского парламента и Совета по
        защите персональных данных.
      </p>
      <p>
        2.8. Заключая Договор, Заказчик предоставляет Исполнителю безусловное согласие на сбор, хранение, использование,
        распространение, передачу и раскрытие конфиденциальной информации о Заказчике, исключительно на условиях, в
        порядке, и в рамках данной политики. При этом, Заказчик подтверждает, что изложенные в данной Политике условия и
        порядок по сбору, использованию, распространению, передачи и раскрытия конфиденциальной информации - устраивают
        последнего в полном объеме, как определенные им самим, а вся конфиденциальная информация о нем - предоставляется
        им добровольно, без всякого принуждения со стороны Исполнителя и/или третьих лиц, не под влиянием обмана и/или
        ошибки, и без какого-либо физического и/или психологического давления.
      </p>

      <p>3. КАКУЮ ИСПОЛНИТЕЛЬ СОБИРАЕТ КОНФИДЕНЦИАЛЬНУЮ ИНФОРМАЦИЮ.</p>

      <p>
        3.1. Для возможности использования интернет-сервиса «Mesh Align», как в целом, так и отдельных его разделов, а
        также для возможности оказывать соответствующую техническую поддержку и осуществлять улучшение работы
        интернет-сервиса «Mesh Align», Исполнитель может предложить Заказчику предоставить некоторую конфиденциальную
        информацию о себе. При этом Исполнитель гарантирует, что будет собирать только ту конфиденциальную информацию,
        которая необходима для определенных, конкретных, явных и законных целей.
      </p>
      <p>
        3.2. Заказчик вправе решать - предоставлять Исполнителю конфиденциальную информацию о себе или не предоставлять.
        При этом может случиться так, что в случае отказа от предоставления определенной конфиденциальной информации о
        себе - Заказчик не сможет использовать интернет-сервис «Mesh Align» и / или отдельные ее разделы. Необходимость
        предоставления определенной конфиденциальной информации о Заказчике, а также соответствующие последствия в
        случае отказа со стороны последнего от ее предоставления - могут указываться непосредственно в интернет-сервисе
        «Mesh Align».
      </p>
      <p>
        3.3. Заключая Договор, Заказчик подтверждает, что он осведомлен относительно следующего: Исполнитель не требует
        от Заказчика предоставления каких-либо сведений о его платежных данных и инструментах, банковских счетах,
        платежных карточках, а также любых других сведений, необходимых для оплаты денежных средств. Исполнитель ни в
        коем случае не несет ответственности за сохранность или противоправное использование указанных выше и подобных
        сведений, даже если такие сведения были получены Исполнителем случайно или из-за умышленных или ошибочных
        действий Лицензиата.
      </p>
      <p>3.4. Исполнитель может собирать следующую конфиденциальную информацию о Заказчика:</p>
      <p>- адрес электронной почты;</p>
      <p>- фамилия, имя и отчество;</p>
      <p>- наименование занимаемой должности и/или места работы;</p>
      <p>- номер телефона для связи;</p>
      <p>- страна нахождения;</p>
      <p>
        - цифровая фотография, на которой изображено лицо Заказчика, или на которой содержится любое другое изображение,
        по которому можно идентифицировать лицо Заказчика.
      </p>
      <p>
        3.5. Исполнитель может собирать следующую конфиденциальную информацию о других лицах (людях), которая
        предоставляется Заказчиком при использовании интернет-сервиса «Mesh Align», с учетом предписаний пунктов 2.5.,
        2.6. и 2.7. данной Политики:
      </p>
      <p>- фамилия, имя и отчество;</p>
      <p>- наименование занимаемой должности и / или места работы;</p>
      <p>- место жительства;</p>
      <p>- номер телефона;</p>
      <p>- биологический пол;</p>
      <p>- паспортные данные;</p>
      <p>- номер страхования;</p>
      <p>
        - цифровая фотография, на которой изображено лицо, или на которой содержится любое другое изображение, которым
        такое лицо можно идентифицировать;
      </p>
      <p>
        - любые электронные компьютерные (цифровые) файлы, которые содержат в себе результаты медицинского обследования
        и/или исследования определенного лица (человека), как в целом, так и отдельных частей тела (например
        телерентгенограмма, компьютерная томография, ортопантомограмма , цифровое трехмерное изображение верхней и/или
        нижней челюсти, цифровое трехмерное изображение черепа, ультразвуковое исследование, магнитно-резонансная
        томография и т.д.);
      </p>
      <p>
        - любую другую конфиденциальную информацию и/или персональные данные относительно определенного лица (человека),
        которую Заказчик размещает, загружает или вводит в интернет-сервис «Mesh Align».
      </p>

      <p>4. ДЛЯ КАКИХ ЦЕЛЕЙ ИСПОЛНИТЕЛЬ СОБИРАЕТ КОНФИДЕНЦИАЛЬНУЮ ИНФОРМАЦИЮ.</p>

      <p>
        4.1. Конфиденциальная информация о Заказчике предоставляется Исполнителю для возможности создания Аккаунта (в
        том числе учетной записи) Заказчика, с помощью которого последний использует интернет-сервис «Mesh Align», и
        осуществляет управление учетной записью, а также для:
      </p>
      <p>
        - возможности приема от Заказчика заказов на выполнение соответствующей работы по Договору, непосредственного
        выполнения такой работы и передачи ее результатов Заказчику;
      </p>
      <p>
        - возможности идентификации личности Заказчика в случае предоставления технической или
        информационно-консультационной поддержки по вопросам использования интернет-сервиса «Mesh Align».
      </p>
      <p>
        4.2. Конфиденциальная информация о других лицах (людях), которая предоставляется Заказчиком при использовании
        интернет-сервиса «Mesh Align», с учетом предписаний пунктов 2.5., 2.6. и 2.7. данной Политики, может собираться
        Исполнителем исключительно с целью идентификации определенных лиц (людей) и их клинических заболеваний, для
        возможности выполнения определенной работы, которую заказал Заказчик по Договору.
      </p>

      <p>5. КАК ЗАКАЗЧИК СОХРАНЯЕТ КОНФИДЕНЦИАЛЬНУЮ ИНФОРМАЦИЮ.</p>

      <p>
        5.1. Размещенная, загруженная ли введенная в интернет-сервис «Mesh Align» Заказчиком конфиденциальная информация
        о Заказчике, или другом лице (человеке) - хранится в соответствующих компьютерных файлах, которые являются
        составной частью интернет-сервиса «Mesh Align», который в свою очередь размещается на внешних серверах в виде
        SaaS-версии.
      </p>
      <p>
        5.2. Исполнитель использует «облачные» серверные технологии обработки данных, по которым физическое расположение
        компьютерных файлов, которые являются составной частью интернет-сервиса «Mesh Align» - может время от времени и
        динамически изменяться, что обеспечивает повышенную защиту таких данных от несанкционированного и/или
        противоправного использования. О текущем местоположении таких файлов, в которых содержится конфиденциальная
        информация Заказчика, или другого лица (человека), можно узнать непосредственно от Исполнителя, путем
        направления письменного уведомления с соответствующим запросом.
      </p>
      <p>
        5.3. Во исполнение предписаний национального законодательства Украины, международного права и национального
        законодательства страны Заказчика и Генерального регламента Европейского парламента и Совета по защите
        персональных данных, Исполнитель обязуется заключать соответствующие договоры по обработке конфиденциальной
        информации с соответствующими поставщиками услуг «облачных» серверных технологий, которые будут привлекаться
        Исполнителем для обработки данных интернет-сервиса «Mesh Align».
      </p>
      <p>
        5.4. Исполнитель использует в работе интернет-сервиса «Mesh Align» протокол передачи данных «HTTPS», который
        поддерживает защиту данных при транспортировке с помощью шифрования информации в соответствии со стандартами SSL
        и TLS, предназначенные для обеспечения более безопасного соединения между сервером и Заказчиком.
      </p>

      <p>6. КАК ДОЛГО ИСПОЛНИТЕЛЬ ХРАНИТ КОНФИДЕНЦИАЛЬНУЮ ИНФОРМАЦИЮ.</p>

      <p>
        6.1. Конфиденциальная информация о Заказчике, или другом лице (человеке) сохраняется Исполнителем в течение
        всего времени функционирования интернет-сервиса «Mesh Align».
      </p>
      <p>
        6.2. Конфиденциальная информация о Заказчике, или другом лице (человеке) может быть удалена с интернет-сервиса
        «Mesh Align» в любое время, на основании предписаний данной Политики или Условий использования.
      </p>

      <p>7. КАК ИСПОЛНИТЕЛЬ ИСПОЛЬЗУЕТ КОНФИДЕНЦИАЛЬНУЮ ИНФОРМАЦИЮ.</p>

      <p>
        7.1. Исполнитель использует конфиденциальную информацию исключительно для обеспечения работоспособности
        интернет-сервиса «Mesh Align» и удобства его использования Заказчиком, согласно предписаниям п. 4.1. и 4.2.
        данной Политики, а также для возможности выполнять обязательства Сторон по Договору.
      </p>

      <p>8. КАК ИСПОЛНИТЕЛЬ РАСПРОСТРАНЯЕТ КОНФИДЕНЦИАЛЬНУЮ ИНФОРМАЦИЮ.</p>

      <p>
        8.1. Размещенная, загруженная ли введенная в интернет-сервис «Mesh Align» Заказчиком конфиденциальная информация
        о Заказчике, или другом лице (человеке) - остается в интернет-сервисе «Mesh Align», и никоим образом не
        распространяется и не распространяется сетью Интернет, либо через другие сторонние ресурсы или иным сторонним
        программным обеспечением.
      </p>

      <p>9. КАК ИСПОЛНИТЕЛЬ РАСКРЫВАЕТ КОНФИДЕНЦИАЛЬНУЮ ИНФОРМАЦИЮ.</p>

      <p>
        9.1. Исполнитель может раскрыть конфиденциальную информацию только в том случае, если того требует национальное
        законодательство Украины, международное право, национальное законодательство страны Заказчика, и Генеральный
        регламент Европейского парламента и Совета по защите персональных данных. В любых других случаях - Исполнитель
        не при каких обстоятельствах и ни в коем случае не раскрывает конфиденциальную информацию посторонним лицам.
      </p>

      <p>10. ПРАВА ЗАКАЗЧИКА И ДРУГИХ ФИЗИЧЕСКИХ ЛИЦ (ЛЮДЕЙ) НА КОНФИДЕНЦИАЛЬНУЮ ИНФОРМАЦИЮ.</p>

      <p>
        10.1. Исполнитель уважает право каждого человека на неприкосновенность его частной жизни, и возможность
        контролировать оборот персональных данных о себе.
      </p>
      <p>
        10.2. В зависимости от предписаний национального законодательства Украины, международного права, национального
        законодательства страны Заказчика или страны определенного физического лица (человека) и Генерального регламента
        Европейского парламента и Совета по защите персональных данных, Заказчик и любое другое физическое лицо
        (человек) имеет право на доступ, исправление и/или удаления конфиденциальной информации о себе.
      </p>
      <p>
        10.3. Исполнитель требует, чтобы запрос Заказчика или любого другого физического лица (человека) по доступу,
        исправлению и/или удалению конфиденциальной информации - удовлетворял действующее национальное законодательство
        Украины, международное право, национальное законодательство страны Заказчика, и Генеральный регламент
        Европейского парламента и Совета по защите персональных данных. Кроме того, Исполнитель требует, чтобы такое
        лицо предоставило достаточную информацию, позволяющую подтвердить его личность и убедиться, что заявитель
        является субъектом или юридически уполномоченным лицом по запрашиваемой конфиденциальной информации.
      </p>
      <p>10.4. Заказчик или любое другое физическое лицо (человек) имеют право:</p>
      <p>
        - получать четкую, ясную и понятную информацию о том, как Исполнитель использует конфиденциальную информацию;
      </p>
      <p>- получить копию своих личных конфиденциальных данных бесплатно;</p>
      <p>- на исправление или дополнение их личной конфиденциальной информации в целях использования;</p>
      <p>- на удаление из интернет-сервиса «Mesh Align» конфиденциальной информации о себе.</p>

      <p>1. Уведомления о файлах «СOOKIE».</p>

      <p>
        11.1. Используя интернет-сервис «Mesh Align», Заказчик соглашается с приведенным ниже порядком использования
        Исполнителем файлов «cookie». Если Заказчик не согласен с приведенным ниже порядком использования Исполнителем
        файлов «cookie» - он должен отключить соответствующие настройки по файлам «cookie» в браузере, с помощью
        которого планирует использовать интернет-сервис «Mesh Align», или полностью отказаться от его использования.
      </p>
      <p>
        ВНИМАНИЕ! Отключение соответствующих настроек по файлам «cookie» в браузере, с помощью которого Заказчик
        планирует использовать интернет-сервис «Mesh Align» - может привести к снижению качества его использования.
      </p>
      <p>11.2. Что такое файлы «cookie»?</p>
      <p>
        Файлы «cookie» - это небольшие текстовые файлы, которые хранятся в браузере определенного компьютера или
        мобильного телефона после посещения любых сайтов. Файлы «cookie» широко применяются для поддержания работы
        веб-сайтов или для повышения качества опыта пользователя, а также для предоставления определенной информации
        владельцам веб-сайтов.
      </p>
      <p>11.3. Как можно разрешить или запретить использование файлов «cookie»?</p>
      <p>
        В любой момент, Заказчик в настройках своего браузера, с помощью которого он планирует использовать
        интернет-сервис «Mesh Align», может разрешить использование всех файлов «cookie», установить предупреждения об
        использовании (сохранение) файлов «cookie» в соответствующем браузере соответствующего устройства или вообще
        запретить использование файлов «cookie». В настройках определенного браузера можно запретить любые файлы
        «cookie» или только определенные файлы «cookie». Поскольку все браузеры отличаются друг от друга, более
        подробная информация о настройке для файлов «cookie» приведена в соответствующем разделе определенного браузера.
        Порядок изменения настроек браузера и параметров использования файлов «cookie» зависит от используемого
        браузера. При необходимости Заказчик может обратиться к справке определенного браузера.
      </p>
      <p>11.4. Для чего Исполнитель использует файлы «cookie»?</p>
      <p>
        Файлы «cookie» используются Исполнителем исключительно для повышения удобства и качества использования
        Заказчиком интернет-сервиса «Mesh Align» через соответствующие браузеры.
      </p>
      <p>11.5. Управление файлами «cookie» с помощью настроек браузера.</p>
      <p>
        Настройка большинства браузеров предусматривают автоматическое получение использования файлов «cookie». Если
        Заказчик не хочет, чтобы сайт сохранял файлы «cookie» на его устройстве, необходимо изменить настройки браузера
        так, чтобы получать предупреждения при каждой попытке сохранения файлов «cookie» определенного типа. Заказчик
        также можете изменить настройки так, чтобы запретить браузеру получать большинство файлов «cookie» или только
        некоторые сторонние из них.
      </p>
      <p>11.6. Какие вложения «cookie» использует Исполнитель?</p>
      <p>Файлы «cookie», которые используются Исполнителем, можно разделить на следующие категории:</p>
      <p>- которые позволяют идентифицировать личность Заказчика;</p>
      <p>
        - необходимых для повышения эффективности работы интернет-сервиса «Mesh Align» (не позволяющие идентифицировать
        личность Заказчика)
      </p>
      <p>
        - используемые для расширения функциональных возможностей (могут содержать персональные данные, предоставляемые
        Заказчиком)
      </p>
      <p>
        - связанные с показом целевой рекламы и рекламных объявлений с помощью интерфейса интернет-сервиса «Mesh Align»,
        по идентификатору устройства или IP-адресу (могут собирать персональные данные).
      </p>
      <p>11.7. Продолжительность хранения файлов «cookie».</p>
      <p>
        Сеансовые файлы «cookie» (временные), хранящиеся на устройстве Заказчика до того момента, когда он оставляет
        интернет-сервис «Mesh Align».
      </p>
      <p>
        Постоянные файлы «cookie», остаются на устройстве Заказчика на более длительный срок или до момента их ручного
        удаления.
      </p>
      <p className="font-bold">РЕКВИЗИТЫ И КОНТАКТНАЯ ИНФОРМАЦИЮ ИСПОЛНИТЕЛЯ:</p>
      <p className="font-bold">ФИЗИЧЕСКОЕ ЛИЦО-ПРЕДПРИНИМАТЕЛЬ ПОГАРСКИЙ АНТОН ЮРЬЕВИЧ.</p>

      <p>
        Дата и номер записи в Едином государственном реестре юридических лиц, физических лиц-предпринимателей и
        общественных формирований: 28.03.2018 года, № 21030000000096301.
      </p>

      <p>
        Адрес (место фактического проживания): 69600, Украина, Запорожская область, город Запорожье, Коммунарский район,
        ул. Реконструктивная, дом 3.
      </p>

      <p>Регистрационный номер учетной карточки налогоплательщика: 2987814459.</p>

      <p>Адрес электронной почты: meshalign@gmail.com.</p>

      <p>
        Режим работы: с понедельника по пятницу каждую неделю (кроме общемировых и украинских праздничных дней), с 10
        час. 00 мин. до 17 час. 00 мин.
      </p>

      <p>Тел .: + 38 097 977 39 98.</p>
    </section>
  </div>
);

export default PolicyRu;
