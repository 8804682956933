import React, { PureComponent } from "react";
import i18next from "../../i18n";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import "./index.scss";

class LanguageSelect extends PureComponent {
  state = {
    language: "en",
  };
  componentDidMount() {
    if (i18next.language) {
      this.setState({ language: i18next.language });
    }
  }
  changeLanguage = e => {
    this.setState(
      {
        language: e.target.value,
      },
      () => {
        i18next.changeLanguage(this.state.language);
      },
    );
  };

  render() {
    const { language } = this.state;
    return (
      <FormControl className="navLang">
        <Select className="select" value={language} onChange={this.changeLanguage}>
          <MenuItem value={"en"} className="option">
            English (EN)
          </MenuItem>
          <MenuItem value={"ru"} className="option">
            Русский (РУС)
          </MenuItem>
          {/*<MenuItem value={"uk"} className="option">*/}
          {/*  Український (УКР)*/}
          {/*</MenuItem>*/}
        </Select>
      </FormControl>
    );
  }
}

export default LanguageSelect;
