import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { toggleTermsOfUse, login } from "../../actions";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import TermsOfUse from "../../components/Dialogs/TermsOfUse";
import { API } from "../../modules/Api";
import Logger from "../../modules/Logger";
// import ThanksForRegister from "./ThanksForRegister";

class RegistrationPage extends PureComponent {
  state = {
    errorEmail: "",
    email: "",
    errors: [],
  };

  componentDidMount() {
    const token = this.props.match.params.token;
    const { t: _t } = this.props;
    if (!token) {
      this.setState({ isLoaded: true });
    } else {
      API.get("user/create/" + token).then(
        (response) => {
          Logger.info(response);
          const { email } = response.data;
          this.setState({ isLoaded: true, email });
        },
        (error) => {
          Logger.info(error);
          this.setState({ isLoaded: true, errors: [_t("Ссылка регистрации не найдена")] });
          if (this.props.isAuth) {
            this.props.history.push("/profile");
          } else {
            this.props.history.push("/login");
          }
        },
      );
    }
  }

  SignUpSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      name: Yup.string().required(_t("Required")),
      clinic: Yup.string().required(_t("Required")),
      phone_number: Yup.number()
        .typeError(_t("That doesn't look like a phone number"))
        .positive(_t("A phone number can't start with a minus"))
        .required(_t("Required")),
      country: Yup.string().required(_t("Required")),
      email: Yup.string().email(_t("Invalid email")).required(_t("Required")),
      password: Yup.string()
        .required(_t("Required"))
        .min(8, _t("Password is too short - should be 8 chars minimum."))
        .matches(/^[a-zA-Z0-9]*$/, _t("Password must contain only latin letters and numbers.")),
      passwordConfirmation: Yup.string()
        .required(_t("Passwords must match"))
        .oneOf([Yup.ref("password"), null], _t("Passwords must match")),
      acceptTerms: Yup.bool().oneOf([true], _t("Accept Terms of Use & Contract & Policy is required")),
    });
  };

  signUp = (form) => {
    // console.log("form", form);
    let registerData = {
      name: form.name,
      clinic: form.clinic,
      phone_number: form.phone_number,
      country: form.country,
      email: form.email,
      password: form.password,
    };
    API.post(`/user/create/${this.props.match.params.token}`, registerData).then(() => {
      this.props.login(this.state.email, form.password, () => {
        this.props.history.push("/profile");
      });
    });
  };

  render() {
    const { t: _t, isTermsOfUse } = this.props;
    if (!this.state.isLoaded) {
      return <div style={{ textAlign: "center", margin: 10 }}>{_t("Загрузка данных регистрации...")}</div>;
    }

    return (
      <div className="formLayout">
        <div className="form">
          <TermsOfUse isShow={isTermsOfUse} onClose={() => this.props.toggleTermsOfUse({ isShow: false })} />
          <div className="title">{_t("Doctor registration")}</div>
          {this.state.errors.length === 0 && (
            <div className="formik formik_reg">
              <Formik
                initialValues={{
                  name: "",
                  clinic: "",
                  phone_number: "",
                  country: "",
                  email: this.state.email,
                  password: "",
                  passwordConfirmation: "",
                  acceptTerms: false,
                }}
                validationSchema={this.SignUpSchema}
                // validateOnChange={false}
                // validateOnBlur={false}
                onSubmit={(values) => {
                  this.signUp(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Field name="name">
                      {({ field }) => (
                        <TextField
                          type="string"
                          label="Doctor's Name"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={errors.name && touched.name}
                          {...field}
                        />
                      )}
                    </Field>
                    {errors.name && touched.name ? <div className="formik-error">{errors.name}</div> : null}
                    <Field name="clinic">
                      {({ field }) => (
                        <TextField
                          type="string"
                          label="Clinic/Lab"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={errors.clinic && touched.clinic}
                          // helperText={errors.clinic}
                          {...field}
                        />
                      )}
                    </Field>
                    {errors.clinic && touched.clinic ? <div className="formik-error">{errors.clinic}</div> : null}
                    <Field name="country">
                      {({ field }) => (
                        <TextField
                          type="string"
                          label="Country"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={errors.country && touched.country}
                          // helperText={errors.country}
                          {...field}
                        />
                      )}
                    </Field>
                    {errors.country && touched.country ? <div className="formik-error">{errors.country}</div> : null}
                    <Field name="phone_number">
                      {({ field }) => (
                        <TextField
                          type="string"
                          label="Tel/Mob"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={errors.phone_number && touched.phone_number}
                          // helperText={errors.phone_number}
                          {...field}
                        />
                      )}
                    </Field>
                    {errors.phone_number && touched.phone_number ? (
                      <div className="formik-error">{errors.phone_number}</div>
                    ) : null}
                    <Field name="email">
                      {({ field }) => (
                        <TextField
                          type="email"
                          label="Email Address"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={errors.email && touched.email}
                          // helperText={errors.email || this.state.errorEmail}
                          InputProps={{
                            readOnly: true,
                          }}
                          {...field}
                        />
                      )}
                    </Field>
                    {errors.email && touched.email ? <div className="formik-error">{errors.email}</div> : null}
                    <Field name="password">
                      {({ field }) => (
                        <TextField
                          type="password"
                          label="Password"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={errors.password && touched.password}
                          // helperText={errors.password}
                          {...field}
                        />
                      )}
                    </Field>
                    {errors.password && touched.password ? <div className="formik-error">{errors.password}</div> : null}
                    <Field name="passwordConfirmation">
                      {({ field }) => (
                        <TextField
                          type="password"
                          label="Password again"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={errors.passwordConfirmation && touched.passwordConfirmation}
                          // helperText={errors.passwordConfirmation}
                          {...field}
                        />
                      )}
                    </Field>
                    {errors.passwordConfirmation && touched.passwordConfirmation ? (
                      <div className="formik-error">{errors.passwordConfirmation}</div>
                    ) : null}
                    <div className="terms">
                      <Field name="acceptTerms">
                        {({ field }) => (
                          <FormControl error={!!errors.acceptTerms} component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    name="acceptTerms"
                                    checked={!!field.value}
                                    // value="condition"
                                    {...field}
                                  />
                                }
                                label={<span className="span">{_t("I agree to the terms")}</span>}
                              />
                              <div style={{ display: "flex" }}>
                                <Button className="btn" component={Link} target="_blank" to="/electronic-contract">
                                  {_t("Contract")}
                                </Button>
                                <Button className="btn terms-btn" component={Link} target="_blank" to="/terms-of-use">
                                  {_t("Terms of Use")}
                                </Button>
                                <Button className="btn" component={Link} target="_blank" to="/policy">
                                  {_t("Policy")}
                                </Button>
                              </div>
                              {!!errors.acceptTerms && <FormHelperText>{errors.acceptTerms}</FormHelperText>}
                            </FormGroup>
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <Button type="submit" className="btn">
                      {_t("Register")}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    );
  }
}

RegistrationPage.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({ dialog, login }) => ({
  isTermsOfUse: dialog.isTermsOfUse,
  isAuth: login.isAuth,
});

const mapDispatchToProps = {
  toggleTermsOfUse,
  login,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(RegistrationPage)));
