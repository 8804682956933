import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import scrollToTop from "../../../modules/scrollToTop";
import toothStylesLogo2 from "../../../assets/img/toothStylesLogo2.png";
import ArrowBack from "../ArrowBack";
import "./index.scss";

import ToothStyles2 from "../../ToothStyles2";

class Library extends PureComponent {
  componentDidMount() {
    scrollToTop();
  }
  render() {
    const { t: _t } = this.props;

    return (
      <div className="library">
        <header className="header">
          <Link className="logo" to="/">
            <span className="dark">Mesh</span>
            <span className="pink">Align</span>
          </Link>
        </header>
        <section className="section">
          <ArrowBack text={_t("TO THE LIBRARIES")} path="/libraryes" />
          <h1>{_t("Teeth styles Pogarskiy Design")}</h1>
          <div className="content">
            <p>
              {_t(
                "Pogarskiy Design Teeth Library developed to make mockaps and smile designs. Library contains 30 styles. This library is presented in the form of 2D styles and 3D models of teeth, which allows to accurately repeat smile design in the shape of teeth. These styles are used to select the future shape of the teeth when creating orders on the platform meshalign.com.")}
            </p>
            <div className="logo">
              <div className="pic">
                <img src={toothStylesLogo2} alt="" />
              </div>
            </div>
          </div>
          <ToothStyles2 />
        </section>
      </div>
    );
  }
}

Library.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Library);
