import React, { useEffect } from "react";
import scrollToTop from "../../../../modules/scrollToTop";
import Logo from "../../../Logo";
import ArrowBack from "../../ArrowBack";
import "./index.scss";

const ElectronicContractEn = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="classification">
      <div className="overlay" />
      <header className="header">
        <Logo />
      </header>
      <section>
        <ArrowBack path="/" text="Back to home page" />
        <h2>ELECTRONIC MESSAGE</h2>
        <p className="font-bold text-center">before concluding the Contract</p>
        <p className="font-bold">DEAR CUSTOMER!</p>
        <p className="f-18">
          PLEASE READ CAREFULLY THE TERMS AND CONDITIONS OF THE ELECTRONIC CONTRACT FOR PERFORMANCE OF WORK, THE TEXT OF
          WHICH IS GIVEN BELOW (HEREINAFTER REFERRED TO AS THE «CONTRACT»).
        </p>
        <p className="f-18">
          PLEASE NOTE THAT THE CONTRACT IS A PUBLIC OFFER AND FITS IN ACCORDANCE WITH ARTICLES 205, 633, 634, 638, 639,
          641, 642, 647 OF THE CIVIL CODE OF UKRAINE, AND ALSO UNDER THE LAW OF «E-COMMERCE», «COPYRIGHT AND RELATED
          RIGHTS».
        </p>
        <p className="f-18">THIS ELECTRONIC MESSAGE IS AN INTEGRAL PART OF THE CONTRACT.</p>
        <p className="font-bold">
          BEFORE CONCLUDING THE CONTRACT YOU SHALL BE PROVIDED WITH THE FOLLOWING INFORMATION:
        </p>
        <p>
          Name of the company: individual entrepreneur Anton Pogarskyi (date and number of entries in the Unified State
          Register of Legal Entities, Individual Entrepreneurs and Public Associations: 03/28/2018, No.
          21030000000096301).
        </p>
        <p>
          Location of the enterprise: 69600, Ukraine, Zaporizhia region, Zaporizhia city, Kommunarskyi district, ul.
          Reconstructivnaya 3.
        </p>
        <p>Email address: meshalign@gmail.com.</p>
        <p>
          Claims procedure: claims are accepted in writing, set forth in Ukrainian, English or Russian, by sending them
          to the following email address: meshalign@gmail.com. The claim shall be dealt with within a period of one
          month.
        </p>
        <p>Key product features: listed at https://meshalign.com/companies-list.</p>
        <p>Information on the license in the field of economic activity: not subject to licensing.</p>
        <p> Prices are listed at: https://meshalign.com/companies-list.</p>
        <p>Terms of payment: defined in section “4” of the Agreement.</p>
        <p>Inclusion of taxes in the calculation of the cost of work: none</p>
        <p>
          Warranties: any warranties or warranties are not provided and are not established, except as defined by the
          national legislation of Ukraine, the requirements of international law and the national legislation of the
          country of the Customer, as mandatory
        </p>
        <p>Conditions for the performance of the Agreement: defined in section “5” of the Contract.</p>
        <p>
          Minimum duration of the contract: lasts from the moment of its conclusion to the moment of termination on the
          conditions specified in the contract.
        </p>
        <p>Period for acceptance of proposals: unlimited.</p>
        <p>Termination of the Agreement: defined in section '10' of the Contract.</p>

        <p className="font-bold text-center">CONTRACT</p>
        <p>before concluding the Contract</p>
        <p>27.04.2020</p>
        <p>
          This document is a public offer to conclude an electronic сontract for the performance of work (hereinafter
          referred to as the “Contract”), which is addressed to an individual (including, but not exclusively, a citizen
          of Ukraine, a foreigner or a stateless person), and which you can accept on the following conditions.
        </p>
        <p>Should you accept this Contract, it should be concluded between:</p>
        <p>
          POGARSKYI ANTON YURIEVICH, an individual entrepreneur (hereinafter referred to as the “Contractor”), acting on
          the basis of the entry in the Unified State Register of Legal Entities and Individual Entrepreneurs No.
          21030000000096301 of 03/28/2018, residence: 69600, Ukraine, Zaporizhzhya Region, Zaporozhye, ul.
          Reconstructivnaya 3, which publishes the proposal to conclude this Contract, and
        </p>
        <p>YOU - an individual who accepts the offer (hereinafter referred to as the “Customer”),</p>
        <p>together hereinafter referred to as the “Parties”, and separately - the “Party”.</p>
        <p>
          By the foregoing, the Contractor expresses his intention to consider himself obligated if the Customer accepts
          the offer to conclude the Contract.
        </p>
        <p>
          The date and time (moment) of conclusion of this Contract is the moment the Customer performs an action, which
          under the terms of the Contract is considered complete and unconditional acceptance of the Contractor's offer
          (offer) to conclude it (clauses 3.1. And 3.2. of the Contract).
        </p>
        <p>
          The place of conclusion of this Contract is the place of actual residence of the Contractor, indicated in this
          Contract among other contact details of the latter.
        </p>

        <h2>1. KEY TERMS AND DEFINITIONS</h2>
        <p>
          1.1. «“Authorization” is the electronic process of the Customer entering his Account, during which the latter
          is checked for the rights of the latter to provide him with appropriate access to such an Account. For
          authorization, the Customer must enter his identifiers in the appropriate electronic form and confirm his
          intention to log in to their account.
        </p>
        <p>
          1.2. “Account” is the electronic information about the Customer that the latter places, downloads or enters in
          the Mesh Align Internet service, it includes the Account, Profile, and Personal Cabinet. An integral part of
          the Account is also Content. To use the Mesh Align Internet service and to be able to carry out work orders,
          you need an Account.
        </p>
        <p>
          1.3. “Acceptance” is full and unconditional acceptance by the Customer of the Contractor’s proposal (offer) to
          conclude this Contract on the terms and conditions indicated by the Contractor (clauses 3.1. and 3.2. of the
          Contract).
        </p>
        <p>1.4. “Identifiers” are the unique login and password that are created personally by the Customer.</p>
        <p>
          1.5. “Internet” is the global information network of general access based on Internet protocols defined by
          international standards.
        </p>
        <p>
          1.6. “Content” means text (written) or video materials, audio recordings or images, as well as any other
          information that is posted, downloaded or entered by the Customer into any sections of the Mesh Align Internet
          service. For the purposes of this Contract, the Content, including, but not exclusively, also includes the
          corresponding electronic text messages, electronic medical records of patients, any electronic computer files
          uploaded to the Mesh Align Internet service, any electronic digital images, any electronic digital video, any
          information, and any personal data of all persons. Content is an integral part of the Account.
        </p>
        <p>
          1.7. “Login” is a unique character set that identifies the Customer in the Mesh Align Internet service, given
          as an email address, which is in the unhindered and personal use of the Customer. Login is a part of the
          Account. The Contractor has the right to prohibit the use of certain Logins, as well as establish requirements
          on them (length, valid characters, etc.).
        </p>
        <p>
          1.8. “Account” is a combination of information about the Customer posted, uploaded or entered into the Mesh
          Align Internet service.
        </p>
        <p>
          1.9. “Personal cabinet” is a section of the Mesh Align Internet service that can only be accessed by a
          registered Customer after completing the electronic Authorization procedure using the Identifier, and through
          which the Customer manages the account.
        </p>
        <p>
          1.10. “Password” is a secret word or a specific sequence of characters designed to confirm the identity of the
          Customer and his rights. The password is used to protect the information available in the Mesh Align Internet
          service from unauthorized access by any unauthorized persons. The password is an element of the account, and
          is used by the Mesh Align Internet service to provide the Customer with permission to enter his account. The
          Contractor has the right to prohibit the use of certain Passwords, as well as establish requirements on them
          (length, valid characters, etc.).
        </p>
        <p>
          1.11. “Written notice” is an electronic letter that may include text (written) or video materials, audio
          recordings or images sent by any Party to this Contract to each other to the appropriate email address. For
          the purposes of this Contract, the Customer’s email address is the address indicated last as his Login, and
          the Contractor’s email address is the address specified in this Contract among other contact details of the
          latter. A written notice may be in Ukrainian, English or Russian.
        </p>
        <p>
          1.12. «Profile» is a collection of personal information about the Customer specified by the latter when
          registering in the Mesh Align online service by filling out the appropriate registration form.
        </p>
        <p>
          1.13. Mesh Align Internet service is a website posted on the Internet at https://meshalign.com, by which the
          Customer has the opportunity to order the Contractor to perform work under this Contract.
        </p>
        <p>
          1.14. “Registration” is an electronic procedure during which the Customer fills out the appropriate
          registration form and creates an Account in the Mesh Align Internet service. For the purposes of this
          Contract, Registration is the Customer’s action, which is considered complete and unconditional acceptance of
          the Contractor’s offer to conclude this Contract.
        </p>

        <h2>2. SUBJECT OF THE CONTRACT.</h2>
        <p>
          2.1. In the manner and under the conditions defined by this Contract, the Contractor undertakes, depending on
          the choice of the customer, to perform the following work for him (hereinafter referred to as the “Work”):
        </p>
        <p>- development of digital plans for orthodontic treatment of human teeth;</p>
        <p>
          - the formation and preparation for printing of digital computer files of the “STL” format, which contain a
          three-dimensional image of the upper and / or lower teeth of a person, solely on the basis of a digital plan
          of orthodontic treatment previously developed by the Contractor;
        </p>
        <p>- creation of digital aesthetic mockups;</p>
        <p>- preparation of digital smile designs.</p>
        <p>2.2. The Customer, in turn, undertakes to pay the Contractor the cost of the Work and accept its result.</p>
        <p>
          2.3. Each individual Work is carried out on the basis of a separate electronic order, which the Customer
          independently generates using the appropriate electronic form on the Mesh Align Internet service. Each
          individual electronic order that has acquired legal force in accordance with the requirements of clause 4.1.
          of this Contract, is its integral part, and performs the role of the relevant specification.
        </p>
        <p>
          2.4. This Contract is an accession agreement and, in accordance with Art. 634 of the Civil Code of Ukraine,
          its conditions are established by the Contractor. Consequently, the Customer cannot offer his own terms under
          this Contract.
        </p>
        <p>
          2.5. The structure of this Contract includes the following separately set out electronic documents, that are
          its integral part:
        </p>
        <p>- The Terms of use given at: https://meshalign.com/terms-of-use;</p>
        <p>- Privacy Policy, located at: https://meshalign.com/policy.</p>

        <h2>3. THE PROCEDURE FOR CONCLUDING THE AGREEMENT</h2>
        <p>
          3.1. This Contract is concluded by placing on the Internet an offer to conclude it on the part of the
          Contractor, and acceptance of such an offer on the part of the Customer.
        </p>
        <p>
          3.2. The customer gives his answer about the acceptance of the Contractor’s proposal to conclude this Contract
          by Registering in the Mesh Align Internet service, upon completion of which the Customer confirms that he is
          familiar with the terms of this Contract, the Terms of Use and the Privacy Policy.
        </p>
        <p>
          3.3. From the moment of Customer's Registration in the Mesh Align Internet service this Contract is considered
          concluded.
        </p>

        <h2>4. THE COST OF WORK AND THE PROCEDURE OF ITS PAYMENT</h2>
        <p>
          4.1. Each time the Customer forms a new separate electronic order, the final cost of the Work that the latter
          wishes to order is determined in the appropriate electronic form of the Mesh Align Internet service. If the
          cost of the Work suits the Customer, he makes the payment, after which a separate electronic order takes legal
          force for the parties. The current cost of each individual Work is always determined in the corresponding
          section of the Mesh Align Internet service, with which the Customer can preliminarily familiarize himself with
          the Registration. When calculating the final cost of each individual Work, the Contractor, at his will and
          discretion, has the right to apply and offer certain discounts, of which the Customer shall be informed
          through the interface of the Mesh Align Internet service.
        </p>
        <p>
          4.2. The final cost of the Work that the Customer wishes to order from the Contractor under this Contract may
          be determined, inter alia, in the form of a monetary equivalent in foreign currency, unilaterally selected by
          the Contractor at his discretion. When the Customer pays the cost of the Work, the final amount of such a
          payment is automatically determined at the current exchange rate between the currency of value and the
          currency of payment established by the corresponding payment system and / or corresponding banks as of the day
          of payment. When paying for the cost of the Work, the Customer realizes and agrees that the final payment
          amount in the payment currency may change in accordance with the current exchange rate.
        </p>
        <p>
          4.3. The Contractor has the right at any time unilaterally, without warning the Customer and without prior
          agreement with him, to change the cost of the Work. At the same time, the cost of the Work that has already
          been paid by the Customer in full or in part is not subject to change.
        </p>
        <p>4.4. Payment of the work cost is carried out by the customer in the following order:</p>
        <p>
          - development of digital plans for orthodontic treatment of human teeth - an advance payment of 100% of the
          work cost;
        </p>
        <p>
          - the formation and preparation for printing of digital computer files of the “STL” format, that contain a
          3-dimensional image of the upper and / or lower teeth of a person, solely on the basis of the digital plan of
          orthodontic treatment previously developed by the Contractor - an advance payment of 100% of the work cost;
        </p>
        <p>
          - creation of digital aesthetic mockups - an advance payment in the amount of 50% of the cost of the Work, and
          final payment of 50% of the cost when transferring the result of the Work from the Contractor to the Customer;
        </p>
        <p>
          - preparation of digital smile designs - prepayment in the amount of 50% of the cost of the Work, and final
          payment of 50% of the cost when transferring the result of the Work from the Contractor to the Customer.
        </p>
        <p>
          4.5. Payment of the cost of the Work is carried out by the Customer by transferring the appropriate amount of
          money to the account of the Contractor, through the use of international bank payment cards through the
          appropriate Internet acquiring payment system, selected unilaterally and at the discretion of the Contractor.
          Immediately after payment of the cost of the Work, the Customer receives an appropriate electronic receipt.
        </p>
        <p>
          4.6. By concluding this Contract, the Customer confirms that he is aware of the following: The Contractor does
          not require the Customer to provide any information about his payment data and tools, bank accounts, payment
          cards, as well as any other information necessary to pay money. The Contractor shall in no case be liable for
          the safety or unlawful use of the above mentioned and similar information, even if such information was
          received by the Contractor accidentally or due to intentional or erroneous actions of the Customer or other
          persons. Responsibility for the safety and use of information that allows the Customer to make payment is
          borne by the operator of the relevant payment system.
        </p>
        <p>4.7. Using the Internet service 'Mesh Align' is free.</p>

        <h2>5. ORDER, TERMS AND CONDITIONS FOR THE PERFORMANCE OF WORK.</h2>
        <p>
          5.1. The contractor performs the work by himself and on his own equipment. If necessary, at the discretion of
          the Contractor and at his request, without the prior consent of the Customer and without conflict with the
          terms of this Contract, the Contractor has the right to transfer the work to any other person at any time,
          while remaining fully responsible to the customer for the result of such work.
        </p>
        <p>
          5.2. Each individual Work is performed by the Contractor within 5 working days from the moment of the
          aggregate occurrence of the following circumstances:
        </p>
        <p>
          - the implementation by the Customer of the prepayment of the cost of the Work, in the amounts determined by
          the requirements of clause 4.4. of the Contract;
        </p>
        <p>
          - provision by the Customer of the full amount of the necessary initial data, which must necessarily meet
          certain criteria, in accordance with the requirements of clause 5.3. of the Contract.
        </p>
        <p>
          If the Customer prepaid the cost of the Work, but did not provide all the source data, or provided the source
          data that does not meet certain criteria, the Contractor’s obligations to perform the corresponding Work do
          not arise, and the deadline for this performance specified in this contract does not start.
        </p>
        <p>
          5.3. The Contractor proceeds to perform the Work only subject to its payment and the provision by the Customer
          of the full amount of the necessary source data, which must necessarily meet the criteria specified by the
          Contractor. The list of necessary input data for each individual Work, as well as the criteria by which they
          must meet, are given at https://meshalign.com/companies-list. If the Customer does not provide the necessary
          amount of initial data, or they do not meet certain criteria, the Contractor shall notify the Customer thereof
          through the interface of the Mesh Align Internet service or by sending a written notice.
        </p>
        <p>5.4. Development of digital plans for orthodontic treatment of human teeth.</p>
        <p>
          This type of work consists in the development of a digital plan for orthodontic treatment, which provides for
          a phased and metered movement of the teeth of a certain person. As a result, the Customer is provided with a
          digital project, which includes:
        </p>
        <p>- images of models in occlusion before treatment and after treatment from five different angles;</p>
        <p>- 3D models of the upper and lower jaw animated in five different angles before and after treatment;</p>
        <p>- a map of the movement of teeth;</p>
        <p>- recommendations on the presence, location and size of separations;</p>
        <p>- recommendations on the presence and form of attachments.</p>
        <p>
          If necessary, the Customer may request from the Contractor a free correction of the developed orthodontic
          treatment plan, but no more than 3 times.
        </p>
        <p>
          By concluding this Contract, the Customer understands and agrees with the following: The Contractor does not
          have the practical possibility to control the manufacturing process of the system of aligners, as well as to
          influence a number of other factors during orthodontic treatment of a person by a specific doctor (fixation of
          activators, separation, retention of teeth, etc.). For these reasons, the Contractor is not responsible for
          the quality of fixation of the aligners in the human oral cavity, as well as for the final result of his
          orthodontic treatment.
        </p>
        <p>
          The Contractor undertakes to achieve the following requirements for the result of Work in the development of
          digital plans for orthodontic treatment:
        </p>
        <p>- normalization of the shape and size of the dentition;</p>
        <p>- correction of growth and development of the jaws;</p>
        <p>- normalization of occlusion (closure of the teeth of the upper and lower jaws).</p>
        <p>5.5. Creation of digital aesthetic mockups.</p>
        <p>
          Being an accurate spatial reflection of the future shape of the front teeth of the upper jaw, the aesthetic
          mockup implies its restoration taking into account the occlusal contacts, apart from the articulation movement
          of the lower jaw. According to the results of this Work, the Customer is provided with:
        </p>
        <p>
          - digital computer 'STL' file, that contains a 3-dimensional image of the upper jaw with simulated upper front
          teeth.
        </p>
        <p>
          Work on the creation of a full functional mockup, which provides for the restoration of all teeth of the upper
          and lower jaw, is not performed by the Contractor.
        </p>
        <p>
          When forming an electronic order, the Customer has the opportunity to add additional requirements and / or
          wishes for the final result of the Work. The Contractor undertakes to achieve the following requirements for
          its result when performing the Work on creating digital aesthetic mockups:
        </p>
        <p>
          - normalization of the shape and size of the teeth according to the requirements and wishes of the Customer.
        </p>
        <p>5.6. Preparation of digital smile designs.</p>
        <p>
          The smile design is a visual plan of the future shape of the teeth after aesthetic rehabilitation, made in the
          form of several images containing:
        </p>
        <p>
          - marking of the face with the contours of the teeth, superimposed on top of the original photo of the person;
        </p>
        <p>- contours of teeth and images of future teeth superimposed on the original photo;</p>
        <p>- photo-realistic collage depicting a person’s face and the design of future teeth.</p>
        <p>
          When forming an electronic order, the Customer has the opportunity to note additional requirements and / or
          wishes for the final result of the Work. The Contractor undertakes to achieve the following requirements for
          its result when performing the Work on the preparation of digital designs of a smile:
        </p>
        <p>- normalization of the shape and size of teeth according to the requirements and wishes of the Customer.</p>

        <h2>6. THE PROCEDURE FOR THE WORK RESULT TRANSMITTING.</h2>
        <p>
          6.1. The Contractor informs the Customer about the completion of the Work by sending the latter a written
          notice.
        </p>
        <p>
          6.2. The results of the work performed are transmitted by the Contractor to the Customer using the interface
          of the Mesh Align Internet service, in the following order:
        </p>
        <p>
          - The Contractor uploads the results of the performed Work in digital form to the appropriate section of the
          Mesh Align Internet service, and reports this to the Customer;
        </p>
        <p>
          - The Customer saves the results of the work performed on any data storage device, and inspects its compliance
          with the ones specified in paragraphs. 5.4., 5.5. and 5.6. of this Contract requirements.
        </p>
        <p>
          From the moment the Contractor downloads to the appropriate section of the Mesh Align Internet service the
          results of the completed Work, and sends a written notification to the Customer about it - the Contractor’s
          obligations to transmit the result of the Work to the Customer are considered complete.
        </p>

        <h2>7. THE GOVERNING LAW AND ARBITRATION. STATUTE OF LIMITATIONS.</h2>
        <p>
          7.1. The parties agree that this Contract as a whole and the legal relationship between the Customer and the
          contractor are regulated, interpreted and implemented primarily in accordance with the national legislation
          (substantive law) of Ukraine. In some cases, when this is mandatory and / or inevitable, the Parties may be
          guided by the requirements of international law and / or national legislation of the country of the Customer.
        </p>
        <p>
          7.2. The parties have established the following: all disputes, disagreements or claims arising from or in
          connection with this Contract, including its conclusion, interpretation, execution, violation, termination or
          invalidity (hereinafter referred to as “the Dispute”), shall be resolved by the relevant local court of
          Ukraine of general jurisdiction, taking into account the jurisdiction of the substantive and procedural law of
          Ukraine.
        </p>
        <p>
          7.3. Should this be mandatory and / or inevitable, the Dispute may be referred to the London International
          Arbitration Court for final decision in accordance with its Rules of Procedure, which is a part of this
          warning. In this case, the Parties determine the following:
        </p>
        <p>- the place of the arbitration shall be the city of Minsk, the Republic of Belarus;</p>
        <p>- the arbitration court shall consist of three arbitrators;</p>
        <p>- the language of the arbitration shall be Russian;</p>
        <p>- arbitration proceedings shall be conducted in accordance with the substantive law of Ukraine.</p>
        <p>
          The composition of the arbitrators will be appointed in the following order: one arbitrator is selected by the
          Customer, the second - by the Contractor, the third is chosen by the secretariat of the London International
          Arbitration Court.
        </p>
        <p>
          7.4. The Parties agreed that they will first of all try to resolve any Dispute through judicial (arbitration)
          negotiations, at least within 30 calendar days from the point of receipt by one Party of the corresponding
          message, which should indicate:
        </p>
        <p>- full name and contact details of the Party initiating the negotiations;</p>
        <p>- substance of the claim or Dispute;</p>
        <p>- clear and understandable formulation of the relevant requirements.</p>
        <p>
          Compliance with the procedure specified above in judicial (arbitration) negotiations is a prerequisite for
          resolving any disputes between the Parties. Failure to comply with this condition shall be grounds for refusal
          to open a corresponding judicial (arbitration) case, or to terminate an already opened case.
        </p>
        <p>
          7.5. By concluding this Contract, the Customer waives the right to consider disputes between the Parties in a
          jury and / or from his participation in class actions, and also agrees that he can present to the Contractor
          any claims, requirements and / or claims exclusively on an individual basis, and not as the plaintiff or
          representative of a group of persons in any representative or collective examination.
        </p>
        <p>
          7.6. The limitation period within which the Customer can apply to the court with a claim to the Contractor to
          protect his right or interest is one year, starting from the day when the Customer has learned or could have
          learned about the violation of his right.")}
        </p>

        <h2>8. FORCE MAJEURE.</h2>
        <p>
          8.1. The parties are exempted from any liability for full or partial failure to fulfill obligations, if this
          is due to the occurrence of force majeure circumstances, that is, extraordinary and inevitable circumstances
          affecting the fulfillment of obligations stipulated by the terms of the Contract, obligations under
          legislative and other regulatory acts, the occurrence and / or action which was foreseen and whose action
          makes it impossible to fulfill obligations for a certain period of time. Should such circumstances occur, the
          Parties should take all possible measures aimed at avoiding or reducing the influence of force majeure
          circumstances on the fulfillment of the relevant obligations under the Contract.
        </p>
        <p>
          8.2. Force majeure is understood as any extraordinary and inevitable circumstances of an external nature that
          arise without the fault of the Parties, outside or contrary to their will or desire, and which cannot be
          foreseen or prevented including but not limited to:
        </p>
        <p>- natural disasters (earthquakes, floods, hurricanes, destruction due to lightning, etc.);</p>
        <p>
          - disasters of biological, technogenic and anthropogenic origin (explosions, fires, failure of machinery and
          equipment, mass epidemics, epizootics, epiphytotics, etc.);
        </p>
        <p>
          - circumstances of public life (war, hostilities, blockades, public unrest, manifestations of terrorism, mass
          strikes and lockouts, boycotts, etc.);
        </p>
        <p>
          - the publication of prohibitive or restrictive regulatory acts by state authorities or local self-government;
        </p>
        <p>
          - any legal or illegal prohibitive or restrictive measures of state authorities that make it impossible or
          temporarily impede the fulfillment by the Parties of their obligations.
        </p>
        <p>
          8.3. The failure of the counterparty to comply with its obligations, or the lack of necessary means of such a
          Party, shall not be deemed to be force majeure.
        </p>
        <p>
          8.4. The occurrence of force majeure circumstances must be certified by the relevant Chamber of Industry and
          Commerce or other relevant Government Authority of the country where such circumstances arose.
        </p>
        <p>
          8.5. A party that intends to refer to the occurrence of force majeure circumstances is obliged to immediately,
          taking into account the capabilities of technical means of instant communication and the nature of existing
          obstacles, notify the other Party of the presence of such circumstances and the nature of their influence on
          the fulfillment of obligations under the Contract.
        </p>
        <p>
          8.6. If force majeure circumstances and their consequences temporarily impede the fulfillment of obligations
          under this Agreement, then their execution shall be suspended for a period during which this is impossible.
        </p>
        <p>
          8.7. If due to force majeure circumstances and their consequences, for which neither of the Parties is
          responsible, the fulfillment of obligations under the Contract is impossible, then the Contract shall be
          considered terminated from the moment of the impossibility of such performance.
        </p>
        <p>
          8.8. If due to force majeure circumstances and their consequences, the fulfillment of obligations under the
          Contract is temporarily impossible, and such impossibility lasts for 30 calendar days and does not show signs
          of termination, then this Contract may be unilaterally terminated by any Party by sending an appropriate
          written notice of to this other Party.
        </p>
        <p>
          8.9. The Contractor is exempted from any liability for failure to fulfill his obligations under this Contract
          if such failure occurred as a result of hacker and / or DDOS attacks (or any other unauthorized access to
          computer systems) to the Mesh Align Internet service.
        </p>

        <h2>9. AMENDMENTS TO CONTRACT.</h2>
        <p>
          9.1. The Contractor has the right at any time to unilaterally, on its own initiative, and without the consent
          of the Customer and without warning, to make any updates, changes or adjustments, deleting existing and adding
          new terms and conditions to the Contract and / or other separately set out electronic documents that are its
          integral part.
        </p>
        <p>
          9.2. The Contractor informs the Customer about updating, amending or updating this Contract, and / or other
          separately stated electronic documents, which are its integral part, by showing the latter the corresponding
          electronic message during his next authorization on the Mesh Align Internet service.
        </p>
        <p>
          9.3. The updated version of the Contract, and / or other separately stated electronic documents, which are its
          integral part, is posted in the appropriate section on the Mesh Align Internet service and comes into force
          from the moment it is accepted by the Customer.
        </p>
        <p>
          9.4. The Customer may refuse to accept the updated version of the Contract, and / or other separately set out
          electronic documents, which are its integral part. In this case, access to the Customer’s account on the Mesh
          Align Internet service is automatically blocked.
        </p>

        <h2>10. TERMINATION OF CONTRACT.</h2>
        <p>
          10.1. The customer has the right at any time unilaterally, at his own initiative, to terminate this Contract
          as follows:")}
        </p>
        <p>
          - by sending the Contractor a written notice indicating in it the termination of the Contract. In this case,
          the Contract is considered terminated from the moment the Contractor receives such a written notice.
        </p>
        <p>
          - according to the requirements of clause 8.8. of the Contract. In this case, the Contract is considered
          terminated from the moment of sending the corresponding written message;
        </p>
        <p>
          - by refusing to accept the updated version of this Contract, and / or other separately set out electronic
          documents, which are its integral part. In this case, the Contract is considered terminated from the moment of
          such a refusal.
        </p>
        <p>
          10.2. The Contractor has the right at any time unilaterally, on its own initiative, and without warning the
          Customer and without paying him any monetary compensation, to terminate this Contract and block the Customer’s
          access to his Account as follows:
        </p>
        <p>
          - according to clause 8.8. of the Contract. In this case, the Contract is considered terminated from the
          moment of sending the corresponding written message;
        </p>
        <p>
          - in case of violation of any non-property and / or property rights of intellectual property (copyright),
          trade secret and / or marks for goods and services related to the Contractor and / or other copyright holders
          of such rights. In this case, the Contract is considered terminated from the moment of blocking the Customer’s
          access to his Account;
        </p>
        <p>
          - in case of revealing the fact of humiliation by the Customer of the honor, dignity and / or business
          reputation of the Contractor. In this case, the Contract is considered terminated from the moment of blocking
          the Customer’s access to his Account;
        </p>
        <p>
          - should the Customer take any actions aimed at damaging the Mesh Align Internet service, disabling it or any
          other interference with its operation. In this case, the Contract is considered terminated from the moment of
          blocking the Customer’s access to his Account;
        </p>
        <p>
          - in case of any violation of any conditions of this Contract, and / or separately stated electronic
          documents, which are its integral part. In this case, the Contract is considered terminated from the moment of
          blocking the Customer’s access to his Account.
        </p>
        <p>
          10.3. After 30 days from the date of termination of this Contract (regardless of which party and on what
          grounds it was terminated), the Contractor can delete the Customer’s Account from the Mesh Align Internet
          service, including all content posted during the entire period of use.
        </p>
        <p>
          10.4. Termination or termination of this Contract does not relieve the Customer of any obligations,
          prohibitions and / or restrictions on the Mesh Align Internet service that arose during the latter during its
          operation.
        </p>

        <h2>11. PERSONAL DATA.</h2>
        <p>
          The conditions for the processing, use, distribution, transfer and protection of personal data of all
          individuals are determined by the relevant Privacy Policy, and take into account the requirements of the
          national legislation of Ukraine, international law, the national legislation of the country of the Customer
          and the General Rules of the European Parliament and the Council for the Protection of Personal Data.
        </p>

        <h2>12. DURATION OF THE CONTRACT AND OTHER TERMS AND CONDITIONS.</h2>
        <p>
          12.1. This Contract shall enter into force from the moment of its conclusion and is valid until its
          termination. The possible invalidity of one or more of the conditions of this Contract does not entail the
          invalidity of the entire contract as a whole or its individual parts, which remain in force. Obligations of
          the Parties that arose during the validity of the Contract, that is, until its termination, are subject to
          mandatory fulfillment on its terms.
        </p>
        <p>
          12.2. In all other respects, which is not provided for by the terms of this Contract, the Parties shall be
          guided by the national legislation of Ukraine, or, when this is mandatory or inevitable, by the provisions of
          international law and / or national legislation of the country of the Customer.
        </p>
        <p>
          12.3. The Customer without the written permission of the Contractor does not have the right to transfer in
          whole or in part his rights and / or obligations under this Contract to other persons. The Contractor has the
          right, without the permission of the Customer, and without his warning, to transfer in whole or in part his
          rights and / or obligations under this Agreement to other persons
        </p>
        <p>12.4. By concluding this Contract, the Customer confirms and guarantees the following:</p>
        <p>
          - at the time of acceptance of the Contractor’s proposal (offer), the Customer has reached the age of majority
          and / or is a fully capable person in the understanding of the national legislation of the country of the
          Customer. If the Customer is a minor, legally incompetent and / or incompetent, then he is already 13 years
          old and his parents or legal guardians are familiar with this Contract, the Terms of Use and the Privacy
          Policy;
        </p>
        <p>
          - The Customer accepts the Contractor’s offer realizing the significance of his actions, not being influenced
          by delusion, deception, violence (physical and / or mental pressure), or a difficult circumstance;
        </p>
        <p>
          - concluding the Contract, the Customer intends to create the corresponding legal consequences arising from
          it;
        </p>
        <p>- the terms of this Contract for the Customer are fully understandable and acceptable;</p>
        <p>- conclusion of this Contract is fully consistent with the internal will of the Customer.</p>
        <p>
          12.5. By concluding this Contract, the Customer agrees with the following: should some of the provisions,
          rules, regulations and / or requirements of this Contract (hereinafter referred to as the “Regulation”)
          contradict each other, have differences in meaning and essence, or any other inconsistencies - the Regulation
          that provides the Contractor with more rights, privileges and benefits, and is also most beneficial for the
          Contractor in terms of responsibility, obligations and freedom of action shall have supreme legal force. For
          example, if one Regulation refers to the obligation of the Contractor to coordinate certain actions with the
          Customer or to warn the latter about their completion, and the other Regulation does not have such an
          obligation, then the Regulation that does not provide for such an obligation shall have supreme legal force.
        </p>
        <p>
          By concluding this Contract, the Customer agrees with the following: in case of disagreement in the
          interpretation of the provisions of this Contract, as a whole, as well as its individual parts, the text of
          the Contract in Ukrainian is of great and high force.
        </p>

        <h2>13. DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY.</h2>
        <p>
          13.1. The Contractor is not responsible for any losses and / or damage arising from the actions of the
          Customer related to the use of the Mesh Align Internet service, due to violation by him and / or other persons
          of the terms of this Contract, the Terms and / or Privacy Policy. In any case, the Contractor is not liable to
          the Customer for lost profits or benefits, for indirect losses and accidentally caused damage, for losses due
          to the loss of the ability to use the Mesh Align Internet service or interruption of the Customer’s
          activities, any loss of Content, regardless of the reasons and the grounds for the onset of these
          circumstances.
        </p>
        <p>
          13.2. The placement or uploading of any Content to the Mesh Align Internet service is carried out by the
          Customer at his own discretion and risk, and the Contractor is not responsible for any damage to the
          Customer’s computer system, data loss or other harm resulting from such actions.
        </p>
        <p>
          13.3. The Customer does not have the right to post or upload any Content to any sections of the Mesh Align
          Internet service if its content contradicts the terms of this Contract, the national legislation of Ukraine,
          international law and / or the national legislation of the country of the Customer. If the Content posted or
          uploaded to any sections of the Mesh Align Internet service contradicts the requirements of this clause of the
          Contract, the Contractor has the right to independently remove such Content from the Mesh Align Internet
          service, without the consent of the Customer and without warning, and also block the Customer’s access to his
          Account in the Mesh Align Internet service.
        </p>
        <p>
          13.4. In any case, including in the case of proof of the Contractor’s direct fault for losses incurred by the
          Customer and / or third parties, the limit of the amount of compensation for losses, or the extent of the
          Contractor’s liability to the Customer, may not exceed the amount of the paid cost of the corresponding Work.
        </p>
        <p>
          13.5. The customer is solely responsible for the security (resistance to guessing) of his chosen password, and
          also independently ensures its confidentiality. The Customer is solely responsible for all his actions or
          inaction (including their consequences) when using the Mesh Align Internet service under his account,
          including cases of voluntary transfer or non-observance of confidentiality of data to access the Customer's
          account to third parties under any conditions. At the same time, all actions when using the Mesh Align
          Internet service under the Customer’s account are considered to be carried out by him, except for the case
          when the Contractor receives from the Customer a Written Notice about unauthorized use of the Mesh Align
          Internet service under his account or about any violation (suspected violation) of the confidentiality of his
          password.
        </p>
        <p>
          13.6. The Customer is obliged to immediately notify the Contractor of any case of unauthorized (not authorized
          by the Customer) access to the Mesh Align Internet service using the Account’s account and / or of any
          violation or suspicion of violation of the confidentiality of his password. For security purposes, the
          Customer is obliged to independently carry out the safe completion of work in the Mesh Align Internet service
          under his account (“Exit” button) at the end of each session of use of the Mesh Align Internet service. The
          Contractor is not responsible for the possible loss or damage of data, as well as other consequences of any
          nature that may occur due to violation by the Customer of the provisions of this paragraph
        </p>
        <p>
          13.7. The Contractor has the right at any time unilaterally, on its own initiative and without the consent of
          the Customer and without his warning, to make any updates (improvements), changes or adjustments to the Mesh
          Align Internet service, including, but not exclusively:
        </p>
        <p>
          - completely change or adjust the structure, interface and / or design (appearance) of the Mesh Align Internet
          service;
        </p>
        <p>- completely change or adjust the algorithms and / or principles of the Mesh Align Internet service;</p>
        <p>- delete, add, completely change or adjust 3D formulas;</p>
        <p>- remove, add, completely change or adjust any functionality of the Mesh Align Internet service;</p>
        <p>
          - in any way reduce or expand the functionality of the Mesh Align Internet service by deleting, modifying or
          adding parts of it.
        </p>
        <p>
          13.8. The Contractor has the right at any time unilaterally, on its own initiative and without the consent of
          the Customer and without his warning, to block access to the account of the latter on the grounds specified in
          clause 10.2. of the Contract.
        </p>
        <p>
          13.9. The Contractor does not guarantee the availability of the Mesh Align Internet service always, in all
          geographical regions, and at any time.
        </p>
        <p>
          13.10. The Contractor does not guarantee the availability of the Mesh Align Internet service from all devices,
          using a specific Internet provider or other communication provider.
        </p>
        <p>
          13.11. The Contractor makes no guarantees that there will be no errors in the Mesh Align Internet service,
          there will be no pauses and / or failures in its operation, and that it will be compatible and / or supported
          by any hardware and software and / or operating system.")}
        </p>

        <h2>14. INTELLECTUAL PROPERTY AND COPYRIGHT.</h2>
        <p>
          14.1. The Mesh Align Internet service is the result of intellectual activity and the subject of copyright,
          which are regulated and protected by the national legislation of Ukraine, the requirements of international
          law and the national legislation of the country of the Customer. The sole owner of exclusive intellectual
          property rights to the Mesh Align Internet service is the Contractor. He is also the sole author of the
          structure and design of the Mesh Align Internet service.
        </p>
        <p>
          14.2. The component of the algorithm of the Mesh Align Internet service may include unique 3D formulas, with
          the help of which the Mesh Align Internet service can be used to demonstrate clinical cases in
          three-dimensional space. The unique 3D formula is a personal development of the Contractor and other copyright
          holders.
        </p>
        <p>
          14.3. The operation algorithms of the Mesh Align Internet service, including the unique 3D formula, is a trade
          secret, which is forbidden to be disclosed and / or used in any other way, in contrast to the terms of the
          contract. The intellectual property rights to the commercial secret specified in this clause belong to the
          Contractor and are protected in accordance with the requirements of clause 14.1. of the Contract.
        </p>
        <p>
          14.4. The Customer is prohibited from copying, republishing, cropping, downloading, changing, distributing,
          licensing, redesigning or distributing the Mesh Align Internet service and / or its individual parts, unless
          this is expressly permitted by the Contractor or by law. The customer does not have the right to decompile,
          try to extract or otherwise use the source code, as a whole, as well as its individual components, or other
          data of the Mesh Align Internet service, as well as create derivative objects and objects based on it,
          websites , systems, programs, databases and the like.
        </p>
        <p>
          14.5. For any violation of the intellectual property rights and / or copyrights of the Contractor and / or
          other persons who are the owners of such rights, the Customer may be held administratively, civilly and / or
          criminally liable in accordance with the national legislation of Ukraine, the provisions of international law
          and / or national legislation of the country of the Customer.
        </p>
        <p>
          14.6. The designation “Mesh Align” and its logo (as a graphic element), which are used in the Internet service
          “Mesh Align”, is a sign for goods and services whose intellectual property rights belong to the Contractor and
          are protected in accordance with the requirements of clause 14.1. of this Contract, therefore the Customer
          shall be prohibited:
        </p>
        <p>
          - to apply such a mark on any product for which the mark is registered, packaging containing such product,
          signboard associated with it, label, patch, tag or other item attached to the product, storage of such product
          with the indicated application of the mark for the purpose of an offer for sale, offering it for sale, sale,
          import and export;
        </p>
        <p>- to apply it during the offer and / or provision of any service for which the mark is registered;</p>
        <p>- to apply it in business documents or in advertising and on the Internet.</p>
        <p className="font-bold">DETAILS AND CONTACT INFORMATION OF THE CONTRACTOR:</p>
        <p className="font-bold">INDIVIDUAL ENTREPRENEUR POGARSKYI ANTON YURIEVICH</p>
        <p>
          Date and number of entries in the Unified State Register of Legal Entities, Individual Entrepreneurs and
          Public Associations: 28.03.2018, № 21030000000096301.
        </p>
        <p>
          Address (place of actual residence): 69600, Ukraine, Zaporizhzhya Region, Zaporizhzhya city, Kommunarsky
          district, ul. Reconstructive, 3.
        </p>
        <p>Taxpayer registration card registration number: 2987814459.</p>
        <p>Email address: meshalign@gmail.com</p>
        <p>
          Opening hours: from Monday to Friday every week (except for global and Ukrainian holidays), from 10 o’clock.
          00 minutes until 5 p.m. 00 minutes"
        </p>
        <p>Tel.: + 38 097 977 39 98.</p>
      </section>
    </div>
  );
};

export default ElectronicContractEn;
