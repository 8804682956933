import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import scrollToTop from "../../../../modules/scrollToTop";
import ArrowBack from "../../ArrowBack";
import "./index.scss";

const ElectronicContractRu = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="classification">
      <div className="overlay" />
      <header className="header">
        <Link className="logo" to="/">
          <span className="dark">Mesh</span>
          <span className="pink">Align</span>
        </Link>
      </header>
      <section>
        <ArrowBack path="/" text="Назад на главную страницу" />
        <h2>ЭЛЕКТРОННОЕ СООБЩЕНИЕ</h2>

        <p className="font-bold text-center">ПЕРЕД ЗАКЛЮЧЕНИЕМ ДОГОВОРА</p>

        <p className="font-bold">УВАЖАЕМЫЙ ЗАКАЗЧИК!</p>

        <p className="f-18">
          ПОЖАЛУЙСТА, ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ С УСЛОВИЯМИ ЭЛЕКТРОННОГО ДОГОВОРА НА ВЫПОЛНЕНИЕ РАБОТ, ТЕКСТ КОТОРОГО
          ПРИВЕДЕН НИЖЕ (ДАЛЕЕ ПО ТЕКСТУ - «ДОГОВОР»).
        </p>
        <p className="f-18">
          ОБРАЩАЕМ ВАШЕ ВНИМАНИЕ, ЧТО ДОГОВОР ЯВЛЯЕТСЯ ПУБЛИЧНОЙ ОФЕРТОЙ И ЗАКЛЮЧАЕТСЯ В СООТВЕТСТВИИ СО СТАТЬЯМИ 205,
          633, 634, 638, 639, 641, 642, 647 ГРАЖДАНСКОГО КОДЕКСА УКРАИНЫ, А ТАКЖЕ В СООТВЕТСТВИИ С ЗАКОНАМИ УКРАИНЫ «ОБ
          ЭЛЕКТРОННОЙ КОММЕРЦИИ», И «ОБ АВТОРСКОМ ПРАВЕ И СМЕЖНЫЕ ПРАВА ».
        </p>
        <p className="f-18">ДАННОЕ ЭЛЕКТРОННОЕ СООБЩЕНИЕ ЯВЛЯЕТСЯ НЕОТЪЕМЛЕМОЙ ЧАСТЬЮ ДОГОВОРА.</p>

        <p className="font-bold">ПЕРЕД ЗАКЛЮЧЕНИЕМ ДОГОВОРА, ВАМ ПРЕДОСТАВЛЯЕТСЯ СЛЕДУЮЩАЯ ИНФОРМАЦИЯ:</p>

        <p>
          Наименование предприятия: физическое лицо-предприниматель Погарский Антон Юрьевич (дата и номер записи в
          Едином государственном реестре юридических лиц, физических лиц-предпринимателей и общественных формирований:
          28.03.2018 года, № 21030000000096301).
        </p>
        <p>
          Местонахождение предприятия: 69600, Украина, Запорожская область, город Запорожье, Коммунарский район, ул.
          Реконструктивная, дом 3.
        </p>
        <p>Адрес электронной почты: meshalign@gmail.com.</p>
        <p>
          Порядок принятия претензий: претензии принимаются в письменном виде, изложенные на украинском, английском или
          русском языках, путем их направления на следующий адрес электронной почты: meshalign@gmail.com. Претензия
          рассматривается в течение одного календарного месяца с момента ее поступления.
        </p>
        <p>Основные характеристики продукции: приведенные по адресу https://meshalign.com/companies-list.</p>
        <p>Сведения о лицензии в сфере хозяйственной деятельности: лицензированию не подлежит.</p>
        <p>Цены приведены по адресу: https://meshalign.com/companies-list.</p>
        <p>Условия оплаты: определены в разделе «4» Договора.</p>
        <p>Включение налогов в расчет стоимости работы: отсутствуют.</p>
        <p>
          Гарантийные обязательства: любые гарантии или гарантийные обязательства не предоставляются и не
          устанавливаются, кроме тех, которые определены национальным законодательством Украины, предписаниями
          международного права и национального законодательства страны Заказчика, как императивные.
        </p>
        <p>Условия выполнения Договора: определены в разделе «5» Договора.</p>
        <p>
          Минимальная продолжительность договора: продолжается с момента его заключения и до момента прекращения на
          определенных в договоре условиях.
        </p>
        <p>Период принятия предложений: неограничен.</p>
        <p>Порядок расторжения Договора: определен в разделе «10» Договора.</p>

        <p className="font-bold text-center">ДОГОВОР</p>
        <p>на выполнение работ</p>

        <p>Редакция от: 27.04.2020 года.</p>

        <p>
          Этот документ представляет собой публичное предложение (оферту) заключить электронный договор на выполнение
          работ (далее по тексту - «Договор»), которое адресуется физическому лицу (в том числе, но не исключительно -
          гражданину Украины, иностранцу или лицу без гражданства ), и которое Вы можете принять (акцептовать) на
          указанных ниже условиях.
        </p>

        <p>В случае принятия (акцепта) Вами настоящего Договора, он заключается между:</p>

        <p>
          ФИЗИЧЕСКИМ ЛИЦОМ-ПРЕДПРИНИМАТЕЛЕМ ПОГАРСКИМ АНТОНОМ ЮРЬЕВИЧЕМ (далее по тексту - «Исполнитель»), действующим
          на основании записи в Едином государственном реестре юридических лиц и физических лиц-предпринимателей №
          21030000000096301 от 28.03.2018 года, место жительства: 69600, Украина, Запорожская область., г. Запорожье,
          улица Реконструктивная, дом 3, который публикует предложение заключить настоящий Договор, и
        </p>

        <p>ВАМИ - физическим лицом, акцептует оферту (далее по тексту - «Заказчик»),</p>

        <p>что вместе далее по тексту именуемые «Стороны», а по отдельности - «Сторона».</p>

        <p>
          Вышеизложенным Исполнитель выражает намерение считать себя обязанным в случае принятия Заказчиком предложения
          заключить договор.
        </p>

        <p>
          Датой и временем (моментом) заключения настоящего Договора является момент совершения Заказчиком действия,
          которое по условиям Договора считаются полным и безусловным принятием предложения (оферты) Исполнителя
          заключить его (пункты 3.1. и 3.2. Договора).
        </p>

        <p>
          Местом заключения настоящего Договора является место фактического проживания Исполнителя, указанное в
          настоящем Договоре среди других контактных данных последнего.
        </p>

        <p>1. Основные термины и определения.</p>

        <p>
          1.1. «Авторизация» - электронный процесс входа Заказчика в его Аккаунт, во время которого происходит проверка
          наличия прав последнего на предоставление ему соответствующего доступа к такому Аккаунту. Для авторизации
          Заказчик должен ввести в соответствующую электронную форму свои идентификаторы, и подтвердить намерение
          осуществить вход в свой аккаунт.
        </p>
        <p>
          1.2. «Аккаунт» - совокупность электронных сведений о Заказчике, которые последний размещает, загружает или
          вводит в интернет-сервис «Mesh Align», и которые включают в себя Учетную запись, Профиль, и Личный кабинет.
          Составной частью Аккаунта является также Контент. Для использования интернет-сервиса «Mesh Align» и
          возможности осуществлять заказа на выполнение работ - нужен Аккаунт.
        </p>
        <p>
          1.3. «Акцепт» - полное и безусловное принятие Заказчиком предложения (оферты) Исполнителя заключить настоящий
          Договор на определенных им условиях (пункты 3.1. и 3.2. Договора).
        </p>
        <p>1.4. «Идентификаторы» - уникальные логин и пароль, которые создаются лично Заказчиком.</p>
        <p>
          1.5. «Интернет» - глобальная информационная сеть общего доступа, основанная на Интернет-протоколах,
          определенных международными стандартами.
        </p>
        <p>
          1.6. «Контент» - текстовые (письменные) или видео материалы, аудиозаписи или изображения, а также любые другие
          материалы или информация, помещаються, загружаются или вводятся Заказчиком в любые разделы интернет-сервиса
          «Mesh Align». Для целей настоящего Договора, Контентом, в том числе, но не исключительно, также есть
          соответствующие электронные текстовые сообщения, электронные медицинские карты пациентов, любые загруженные в
          интернет-сервис «Mesh Align» электронные компьютерные файлы любого формата, будь какие электронные цифровые
          изображения, любое электронное цифровое видео, любая информация, и любые персональные данные всех лиц. Контент
          является составной частью Аккаунт.
        </p>
        <p>
          1.7. «Логин» - уникальный набор символов, который идентифицирует Заказчика в интернет-сервисе «Mesh Align»,
          выраженный в виде адреса электронной почты, находится в беспрепятственном и личном использовании Заказчика.
          Имя является элементом учетной записи. Исполнитель имеет право запретить использование определенных Логинов, а
          также устанавливать по ним требования (длина, допустимые символы и др.).
        </p>
        <p>
          1.8. «Учетная запись» - совокупность размещенной, загруженной или введенной в интернет-сервисе «Mesh Align»
          информации о Заказчике.
        </p>
        <p>
          1.9. «Личный кабинет» - раздел интернет-сервиса «Mesh Align», доступ к которому имеет только
          зарегистрированный Заказчик после прохождения электронной процедуры авторизации с помощью идентификатора, и
          через который Заказчик осуществляет управление учетной записью.
        </p>
        <p>
          1.10. "Пароль" - тайное слово или определенная последовательность символов, предназначенная для подтверждения
          личности Заказчика и его прав. Пароль используется для защиты информации, имеющейся в интернет-сервисе «Mesh
          Align» от несанкционированного доступа любых посторонних лиц. Пароль является элементом учетной записи, и
          используются интернет-сервисом «Mesh Align» для предоставления Заказчику разрешения на вход в его аккаунт.
          Исполнитель имеет право запретить использование определенных Паролей, а также устанавливать по ним требования
          (длина, допустимые символы и др.).
        </p>
        <p>
          1.11. «Письменное уведомление» - электронное письмо, может включать в себя текстовые (письменные) или видео
          материалы, аудиозаписи или изображения, оставленный любой Стороной настоящего Договора друг другу на
          соответствующий адрес электронной почты. Для целей настоящего Договора, адресом электронной почты Заказчика
          является адрес, указанный последним в качестве его Логина, а адресом электронной почты Исполнителя является
          адрес, указанный в настоящем Договоре среди других контактных данных последнего. Письменное уведомление может
          быть изложено на украинском, английском или русском языках.
        </p>
        <p>
          1.12. «Профиль» - совокупность персональных сведений о Заказчике, указанных последним при Регистрации в
          интернет-сервисе «Mesh Align» путем заполнения соответствующей регистрационной формы.
        </p>
        <p>
          1.13. Интернет-сервис «Mesh Align» - веб-сайт, размещенный в сети Интернет по адресу https://meshalign.com, с
          помощью которого Заказчик имеет возможность заказывать у Исполнителя выполнения работ по настоящему Договору.
        </p>
        <p>
          1.14. «Регистрация» - электронная процедура, во время которой Заказчик заполняет соответствующую
          регистрационную форму, и создает в интернет-сервисе «Mesh Align» Аккаунт. Для целей настоящего Договора,
          Регистрация является действием Заказчика, которая считается полным и безусловным принятием предложения
          (оферты) Исполнителя заключить настоящий Договор.
        </p>

        <p>2. ПРЕДМЕТ ДОГОВОРА.</p>

        <p>
          2.1. В порядке и на условиях, определенных настоящим Договором, Исполнитель обязуется в зависимости от выбора
          заказчика выполнять для него следующие работы (далее по тексту - «Работы»):
        </p>
        <p>- разработка цифровых планов ортодонтического лечения зубов человека;</p>
        <p>
          - формирование и подготовка для печати цифровых компьютерных файлов формата «STL», которые содержат в себе
          трехмерное изображение верхних и/или нижних зубов человека, исключительно на основании ранее разработанного
          Исполнителем цифрового плана ортодонтического лечения;
        </p>
        <p>- создание цифровых эстетических мокап;</p>
        <p>- подготовка цифровых дизайнов улыбки.</p>
        <p>Заказчик, в свою очередь, обязуется оплачивать Исполнителю стоимость Работы и принимать ее результат.</p>
        <p>
          2.2. Каждая отдельная Работа выполняются на основании отдельного электронного заказа, Заказчик самостоятельно
          формирует с помощью соответствующей электронной формы на интернет-сервисе «Mesh Align». Каждый отдельный
          электронный заказ, который приобрел юридическую силу в соответствии с предписаниями п. 4.1. настоящего
          Договора, являются его неотъемлемой частью, и выполняет роль соответствующей спецификации.
        </p>
        <p>
          2.3. Настоящий Договор является договором присоединения и, согласно ст. 634 Гражданского кодекса Украины, его
          условия установлены Исполнителем. Поэтому, Заказчик не может предлагать свои условия по данному Договору.
        </p>
        <p>
          2.4. В состав данного Договора входят следующие отдельно изложенные электронные документы, которые являются
          его неотъемлемой частью:
        </p>
        <p>- Условия использования, указанные по адресу: https://meshalign.com/terms-of-use;</p>
        <p>- Политика конфиденциальности, указанная по адресу: https://meshalign.com/policy.</p>

        <p>3. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА.</p>

        <p>
          3.1. Настоящий Договор заключается путем размещенной в сети Интернет предложения его заключить (оферты) со
          стороны Исполнителя, и Акцепта такого предложения со стороны Заказчика.
        </p>
        <p>
          3.2. Заказчик свой ответ об Акцепте предложения (оферты) Исполнителя заключить настоящий Договор путем
          Регистрации в интернет-сервисе «Mesh Align», при прохождении которой Заказчик подтверждает, что ознакомлен с
          условиями настоящего Договора, Условиями использования и Политикой конфиденциальности.
        </p>
        <p>
          3.3. С момента Регистрации Заказчика в интернет-сервисе «Mesh Align» - настоящий Договор считается
          заключенным.
        </p>

        <p>4. СТОИМОСТЬ РАБОТЫ И ПОРЯДОК ЕЕ ОПЛАТЫ.</p>

        <p>
          4.1. Каждый раз, когда Заказчик формирует новый отдельный электронный заказ, в соответствующей электронной
          форме интернет-сервиса «Mesh Align» определяется окончательная стоимость Работы, которую последний желает
          заказать. Если определённая стоимость Работы устраивает Заказчика, он осуществляет соответствующую оплату,
          после чего отдельный электронный заказ приобретает юридическую силу для сторон. Текущая стоимость каждой
          отдельной Работы - всегда определена в соответствующем разделе интернет-сервиса «Mesh Align», с чем Заказчик
          может предварительно ознакомиться после Регистрации. При расчете окончательной стоимости каждой отдельной
          Работы, Исполнитель по своему желанию и по своему усмотрению вправе применять и предлагать определенные
          скидки, о чем Заказчик будет проинформирован посредством интерфейса интернет-сервиса «Mesh Align».
        </p>
        <p>
          4.2. Окончательная стоимость Работы, которую Заказчик желает заказать у Исполнителя по настоящему Договору
          может быть определена, помимо прочего, в виде денежного эквивалента в иностранной валюте, выбранной в
          одностороннем порядке Исполнителем по его усмотрению. При оплате Заказчиком стоимости Работы конечная сумма
          такого платежа автоматически определяется по текущему валютному курсу между валютой стоимости и валютой
          платежа, установленному соответствующей платежной системой и/или корреспондирующими банками по состоянию на
          день оплаты. Осуществляя оплату стоимости Работы, Заказчик осознает и соглашается с тем, что конечная сумма
          платежа в валюте оплаты может изменяться в соответствии с текущим валютным курсом.
        </p>
        <p>
          4.3. Исполнитель вправе в любое время в одностороннем порядке, без предупреждения Заказчика и без
          предварительного согласования с ним изменять стоимость Работ. При этом, стоимость Работы, которая уже
          Заказчиком оплачена полностью или частично - изменению не подлежит.
        </p>
        <p>4.4. Оплата стоимости Работы осуществляются Заказчиком в следующем порядке:</p>
        <p>
          - разработка цифровых планов ортодонтического лечения зубов человека - предоплата в размере 100% стоимости
          Работы;
        </p>
        <p>
          - формирование и подготовка для печати цифровых компьютерных файлов формата «STL», которые содержат в себе
          трехмерное изображение верхних и/или нижних зубов человека, исключительно на основании ранее разработанного
          Исполнителем цифрового плана ортодонтического лечения - предоплата в размере 100% стоимости работы;
        </p>
        <p>
          - создание цифровых эстетических мокап - предоплата в размере 50% стоимости Работы, и окончательная оплата 50%
          стоимости при передаче результата Работы от Исполнителя к Заказчику;
        </p>
        <p>
          - подготовка цифровых дизайнов улыбки - предоплата в размере 50% стоимости Работы, и окончательная оплата 50%
          стоимости при передаче результата Работы от Исполнителя к Заказчику.
        </p>
        <p>
          4.5. Оплата стоимости Работы осуществляется Заказчиком путем перечисления соответствующей суммы денежных
          средств на расчетный счет Исполнителя, посредством использования международных банковских платежных карт через
          соответствующую платежную систему интернет-эквайринга, выбранную в одностороннем порядке и по усмотрению
          Исполнителя. Сразу после оплаты стоимости Работы Заказчик получает соответствующую электронную квитанцию.
        </p>
        <p>
          4.6. Заключая настоящий Договор, Заказчик подтверждает, что он осведомлен относительно следующего: Исполнитель
          не требует от Заказчика предоставления каких-либо сведений о его платежных данных и инструментах, банковских
          счетах, платежных карточек, а также любых других сведений, необходимых для оплаты денежных средств.
          Исполнитель ни в коем случае не несет ответственность за сохранность или противоправное использование
          указанных выше и подобных сведений, даже если такие сведения были получены Исполнителем случайно или из-за
          умышленных или ошибочных действия Заказчика или других лиц. Ответственность за сохранность и использование
          сведений, позволяющих обеспечить проведение Заказчиком оплаты, несет оператор соответствующей платежной
          системы.
        </p>
        <p>4.7. Использование интернет-сервиса «Mesh Align» - бесплатный.</p>

        <p>5. ПОРЯДОК, СРОКИ И УСЛОВИЯ ВЫПОЛНЕНИЯ РАБОТЫ.</p>

        <p>
          5.1. Исполнитель выполняет работу собственными силами и на собственном оборудовании. При необходимости, по
          усмотрению Исполнителя и по его желанию, без предварительного согласия Заказчика и, не вступая в противоречие
          с условиями настоящего Договора, Исполнитель вправе в любой момент передать выполнение Работы любой другому
          лицу, оставаясь при этом ответственным в полном объеме перед заказчиком результата такой работы.
        </p>
        <p>
          5.2. Каждая отдельная Работа выполняется Исполнителем в течение 5-ти рабочих дней с момента совокупного
          наступления следующих обстоятельств:
        </p>
        <p>
          - осуществление Заказчиком предоплаты стоимости Работы, в определенных предписаниями п. 4.4. настоящего
          Договора размерах;
        </p>
        <p>
          - предоставление со стороны Заказчика полного объема необходимых исходных данных, которые обязательно должны
          соответствовать определенным критериям, в соответствии с предписаниями п. 5.3. дано го Договора.
        </p>
        <p>
          Если Заказчик осуществил предоплату стоимости Работы, но не предоставил всех исходных данных, или предоставил
          исходные данные не соответствующие определенным критериям - обязательства Исполнителя по выполнению
          соответствующей Работы не возникает, а определенный данным пунктом договора срок для ее выполнения свой ход не
          предпринимает.
        </p>
        <p>
          5.3. Исполнитель приступает к выполнению Работы исключительно при условии ее оплаты и предоставления со
          стороны Заказчика полного объема необходимых исходных данных, которые обязательно должны соответствовать
          определенным Исполнителем критериям. Перечень необходимых исходных данных для каждого отдельного вида Работ, а
          также критерии, которым они должны соответствовать представлены по адресу
          https://meshalign.com/companies-list. Если со стороны Заказчика не представлено необходимого объема исходных
          данных, или они не соответствуют определенным критериям, Исполнитель уведомляет об этом Заказчика посредством
          интерфейса интернет-сервиса «Mesh Align» или путем отправки письменного уведомления.
        </p>
        <p>5.4. Разработка цифровых планов ортодонтического лечения зубов человека.</p>
        <p>
          Данный вид Работы заключается в разработке цифрового плана ортодонтического лечения, предусматривающего
          поэтапное и дозированное передвижения зубов определенного человека. В результате Заказчику передается цифровой
          проект, который включает в себя:
        </p>
        <p>- изображения моделей в окклюзии до лечения и после лечения из пяти разных ракурсов;</p>
        <p>- анимированные в пяти различных ракурсах 3D модели верхней и нижней челюсти до и после лечения;</p>
        <p>- карту передвижения зубов;</p>
        <p>- рекомендации по наличию, расположению и размерам сепараций;</p>
        <p>- рекомендации по наличию и форме аттачментов.</p>
        <p>
          При необходимости Заказчик может запросить у Исполнителя бесплатную коррекцию разработанного плана
          ортодонтического лечения, но не более 3-х раз.
        </p>
        <p>
          Заключая настоящий Договор, Заказчик осознает и соглашается с последующим: Исполнитель не имеет практической
          возможности контролировать процесс изготовления системы элайнеров, а так же влиять на ряд других факторов во
          время ортодонтического лечения человека определенным врачом (фиксация активаторов, выполнение сепараций,
          ретенирование зубов и др.). По этим причинам Исполнитель не несет ответственности за качество фиксации
          элайнеров в ротовой полости человека, как и за конечный результат его ортодонтического лечения.
        </p>
        <p>
          Исполнитель обязуется достичь при выполнении Работы по разработке цифровых планов ортодонтического лечения
          следующих требований к ее результату:
        </p>
        <p>- нормализация формы и размеров зубных рядов;</p>
        <p>- коррекция роста и развития челюстей;</p>
        <p>- нормализация окклюзии (смыкания зубов верхней и нижней челюстей).</p>
        <p>5.5. Создание цифровых эстетических мокап.</p>
        <p>
          Будучи точным пространственным отражением будущей формы передних зубов верхней челюсти, эстетический мокап
          подразумевает ее восстановления с учетом окклюзионных контактов, не считая артикуляционное движение нижней
          челюсти. По результатам данной Работы, Заказчику предоставляется цифровой компьютерный файл формата «STL»,
          который содержит в себе трехмерное изображение верхней челюсти с отмоделированными верхними передними зубами.
        </p>
        <p>
          Работы по созданию полного функционального мокапа, который предусматривает реставрацию всех зубов верхней и
          нижней челюсти Исполнителем не выполняются.
        </p>
        <p>
          При формировании электронного заказа, Заказчик имеет возможность отметить дополнительные требования и/или
          пожелания к конечному результату Работы. Исполнитель обязуется достичь при выполнении Работы по созданию
          цифровых эстетических мокап следующих требований к ее результату:
        </p>
        <p>- нормализация формы и размера зубов согласно требованиям и пожеланиям Заказчика.</p>
        <p>5.6. Подготовка цифровых дизайнов улыбки.</p>
        <p>
          Дизайн улыбки - это визуальный план будущей формы зубов после проведения эстетической реабилитации,
          выполненный в виде нескольких изображений, содержащих в себе:
        </p>
        <p>- разметку лица с контурами зубов, наложенными поверх исходного фото человека;</p>
        <p>- контуры зубов и изображения будущих зубов, наложенных на исходное фото;</p>
        <p>- фото-реалистичный коллаж с изображением лица человека и дизайна будущих зубов.</p>
        <p>
          При формировании электронного заказа, Заказчик имеет возможность отметить дополнительные требования и/или
          пожелания к конечному результату Работы. Исполнитель обязуется достичь при выполнении Работы по подготовке
          цифровых дизайнов улыбки следующих требований к ее результату:
        </p>
        <p>- нормализация формы и размера зубов согласно требованиям и пожеланиям Заказчика.</p>

        <p>6. ПОРЯДОК ПЕРЕДАЧИ РЕЗУЛЬТАТА РАБОТЫ.</p>

        <p>
          6.1. Об окончании выполнения Работы Исполнитель сообщает Заказчика путем направления последнему письменного
          уведомления.
        </p>
        <p>
          6.2. Результаты проделанной Работы передаются Исполнителем к Заказчику с помощью интерфейса интернет-сервиса
          «Mesh Align», в следующем порядке:
        </p>
        <p>
          - Исполнитель загружает в соответствующий раздел интернет-сервиса «Mesh Align» результаты проделанной Работы в
          цифровом виде, и сообщает об этом Заказчику;
        </p>
        <p>
          - Заказчик сохраняет результаты выполненной Работы на любом устройстве для хранения данных, и осматривает их
          соответствия определенным в п.п. 5.4., 5.5. и 5.6. настоящего Договора требованиям.
        </p>
        <p>
          6.3. С момента загрузки Исполнителем в соответствующий раздел интернет-сервиса «Mesh Align» результатов
          выполненной Работы, и направления Заказчику письменного уведомления об этом - обязательства Исполнителя по
          передаче Заказчику результата Работы считаются выполненными в полном объеме.
        </p>

        <p>7. УПРАВЛЯЮЩЕЕ ЗАКОНОДАТЕЛЬСТВО И АРБИТРАЖ. ИСКОВАЯ ДАВНОСТЬ.</p>

        <p>
          7.1. Стороны согласовывают, что настоящий Договор в целом и связанные с ним правоотношения между Заказчиком и
          исполнителем регулируются, толкуются и выполняются преимущественно в соответствии с национальным
          законодательством (материальным правом) Украины. В отдельных случаях, когда это является обязательным и/или
          неизбежным, Стороны могут руководствоваться предписаниями международного права и/или национального
          законодательства страны Заказчика.
        </p>
        <p>
          7.2. Стороны установили следующее: все споры, разногласия или требования, возникающие из настоящего Договора
          или в связи с ним, в том числе по его заключению, толкованию, исполнению, нарушению, прекращению или
          недействительности (далее по тексту - «Спор»), подлежат разрешению соответствующим местным судом Украины общей
          юрисдикции с учетом подсудности по материальным и процессуальным правом Украины.
        </p>
        <p>
          7.3. В случае, когда это является обязательным и/или неизбежным, Спор может быть передан на рассмотрение для
          окончательного решения в Лондонский международный арбитражный суд по его Регламенту, который является частью
          данного предупреждения. В данном случае Стороны определяют следующее:
        </p>
        <p>- местом проведения арбитражного разбирательства будет город Минск, Республики Беларусь;</p>
        <p>- арбитражный суд будет состоять из трех арбитров;</p>
        <p>- языком арбитражного разбирательства будет Русский;</p>
        <p>- арбитражное разбирательство будет проводиться в соответствии с материальным правом Украины.</p>
        <p>
          Состав арбитров будет назначаться в следующем порядке: одного арбитра выбирает Заказчик, второго -
          Исполнитель, третьего - секретариат Лондонского международного арбитражного суда.
        </p>
        <p>
          7.4. Стороны договорились, что в первую очередь в обязательном порядке будут пытаться решить любой Спор путем
          до судебных (арбитражных) переговоров, по меньшей мере в течение 30 календарных дней с момента получения одной
          Стороной от другой соответствующего сообщения, в котором должно быть указано:
        </p>
        <p>- полное имя (наименование) и контактные данные Стороны инициирующей переговоры;</p>
        <p>- суть претензии или Спору;</p>
        <p>- чёткие и понятные формулировки соответствующего требования.</p>
        <p>
          Соблюдение, определенного выше порядка до судебных (в арбитражных) переговоров - является обязательным
          условием для решения любых споров между Сторонами. Несоблюдение этого условия является основанием для отказа в
          открытии соответствующей судебного (арбитражного) дела, или прекращения уже открытого.
        </p>
        <p>
          7.5. Заключая настоящий Договор, Заказчик отказывается от права на рассмотрение споров между Сторонами в суде
          присяжных и/или от своего участия в коллективных исках, а также соглашается с тем, что он может предъявлять
          Исполнителю любые претензии, требования и/или иски исключительно в индивидуальном порядке, а не в качестве
          истца или представителя группы лиц в любом представительском или коллективном рассмотрении.
        </p>
        <p>
          7.6. Срок исковой давности, в рамках которого Заказчик может обратиться в суд с требованием к Исполнителю о
          защите своего права или интереса - составляет один год, начиная со дня, когда Заказчик узнал или мог узнать о
          нарушении своего права.
        </p>

        <p>8. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ.</p>

        <p>
          8.1. Стороны освобождается от любой ответственности за полное или частичное невыполнение определенных
          настоящим Договором обязательств, если это связано с возникновением обстоятельств непреодолимой силы, то есть
          чрезвычайных и неотвратимых обстоятельств, объективно влияющих на выполнение обязательств, предусмотренных
          условиями Договора, обязанностей по законодательными и другими нормативными актами, возникновение и/или
          действие которых было предусмотреть и действие которых делает невозможным выполнение обязательств в течение
          определенного периода времени. В случае возникновения таких обстоятельств, Стороны должны принять все
          максимально возможные меры, направленные на избежание или уменьшение влияния обстоятельств непреодолимой силы
          на выполнение соответствующих обязательств по Договору.
        </p>
        <p>
          8.2. Под обстоятельствами непреодолимой силы понимаются любые чрезвычайные и неотвратимые обстоятельства
          внешнего относительно Сторон характера, которые возникают без их вины, вне или вопреки их воли или желания,
          которые нельзя при условии применения обычных для этого мероприятий предусмотреть и нельзя при всей
          заботливости и осмотрительности предотвратить (избежать), в том числе, но не исключительно:
        </p>
        <p>
          - стихийные явления природного характера (землетрясения, наводнения, ураганы, разрушения в результате молнии и
          т.д.);
        </p>
        <p>
          - бедствия биологического, техногенного и антропогенного происхождения (взрывы, пожары, выход из строя машин и
          оборудования, массовые эпидемии, эпизоотии, эпифитотии т.п.);
        </p>
        <p>
          - обстоятельства общественной жизни (война, военные действия, блокады, общественные волнения, проявления
          терроризма, массовые забастовки и локауты, бойкоты и т.д.);
        </p>
        <p>
          - издание запретительных или ограничивающих нормативных актов органами государственной власти или местного
          самоуправления;
        </p>
        <p>
          - любые законные или незаконные запретительные или ограничительные меры органов государственной власти,
          которые делают невозможным или временно препятствуют выполнению Сторонами своих обязательств.
        </p>
        <p>
          8.3. Не считаются обстоятельствами непреодолимой силы несоблюдение своих обязанностей контрагентом той
          Стороны, которая не выполнила обязательства, или отсутствие у такой Стороны необходимых средств.
        </p>
        <p>
          8.4. Возникновения обстоятельств непреодолимой силы должно быть засвидетельствовано соответствующей
          торгово-промышленной палатой или иным уполномоченным государственным органом страны, на территории которой
          такие обстоятельства возникли.
        </p>
        <p>
          8.5. Сторона, которая намерена сослаться на возникновение обстоятельств непреодолимой силы, обязана
          немедленно, с учетом возможностей технических средств мгновенной связи и характера существующих препятствий,
          уведомить другую Сторону о наличии таких обстоятельств и о характере их влияния на выполнение обязательств по
          договору.
        </p>
        <p>
          8.6. Если обстоятельства непреодолимой силы и их последствия временно препятствуют выполнению обязательств по
          настоящему Договору, то их выполнение приостанавливается на срок, в течение которого это невозможно.
        </p>
        <p>
          8.7. Если в связи с обстоятельствами непреодолимой силы и их последствиями, за которые ни одна из Сторон не
          отвечает, выполнение обязательств по настоящему Договору является окончательно невозможным, то Договор
          считается прекращенным с момента возникновения невозможности такого исполнения.
        </p>
        <p>
          8.8. Если в связи с обстоятельствами непреодолимой силы и их последствиями выполнения обязательств по
          настоящему Договору является временно невозможным, и такая невозможность продолжается в течение 30 календарных
          дней и не проявляет признаков прекращения, то настоящий Договор может быть расторгнут в одностороннем порядке
          любой Стороной путем направления соответствующего письменного уведомления об этом другой Стороне.
        </p>
        <p>
          8.9. Исполнитель освобождается от любой ответственности за невыполнение своих обязательств по настоящему
          Договору, если такое неисполнение произошло вследствие хакерских и/или DDOS-атак (или любого другого
          несанкционированного доступа к компьютерным системам) на Интернет-сервис «Mesh Align ».
        </p>

        <p>9. ИЗМЕНЕНИЯ К ДОГОВОРУ.</p>

        <p>
          9.1. Исполнитель имеет право в любой момент в одностороннем порядке по собственной инициативе, и без
          согласования с Заказчиком и без его предупреждения вносить в настоящий Договор, и/или другой отдельно
          изложенный электронный документ, который являются его неотъемлемой частью - любые обновления, изменения или
          корректировки, удаляя существующие и добавляя новые условия и положения.
        </p>
        <p>
          9.2. Исполнитель информирует Заказчика об обновлении, изменении или корректировке данного Договора, и/или
          других отдельно изложенных электронных документов, которые являются его неотъемлемой частью, путем
          демонстрации последним соответствующего электронного сообщения во время его очередной авторизации на
          интернет-сервисе «Mesh Align».
        </p>
        <p>
          9.3. Обновленная редакция Договора, и/или других отдельно изложенных электронных документов, которые являются
          его неотъемлемой частью, размещается в соответствующем разделе на интернет-сервиса «Mesh Align» и вступает в
          силу для с момента его принятия со стороны Заказчика.
        </p>
        <p>
          9.4. Заказчик может отказаться от принятия обновленной редакции Договора, и/или других отдельно изложенных
          электронных документов, которые являются его неотъемлемой частью. В таком случае доступ к аккаунту Заказчика
          на интернет-сервисе «Mesh Align» автоматически блокируется.
        </p>

        <p>10. ПОРЯДОК РАСТОРЖЕНИЯ ДОГОВОРА.</p>

        <p>
          10.1. Заказчик имеет право в любой момент в одностороннем порядке по собственной инициативе расторгнуть
          настоящий Договор следующим образом:
        </p>
        <p>
          - путем направления Исполнителю письменного уведомления с указанием в нем о расторжении Договора. В данном
          случае Договор считается расторгнутым с момента получения Исполнителем такого Письменного уведомления.
        </p>
        <p>
          - с предписаниями п. 8.8. настоящего Договора. В данном случае Договор считается расторгнутым с момента
          направления соответствующего письменного сообщения;
        </p>
        <p>
          - путем отказа от принятия обновленной редакции настоящего Договора, и/или других отдельно изложенных
          электронных документов, которые являются его неотъемлемой частью. В данном случае Договор считается
          расторгнутым с момента совершения такого отказа.
        </p>
        <p>
          10.2. Исполнитель имеет право в любой момент в одностороннем порядке по собственной инициативе, и без
          предупреждения Заказчика и без выплаты ему каких-либо денежных компенсаций, расторгнуть настоящий Договор, и
          заблокировать доступ Заказчика к его Аккаунту, следующим образом:
        </p>
        <p>
          - согласно п. 8.8. настоящего Договора. В данном случае Договор считается расторгнутым с момента направления
          соответствующего письменного сообщения;
        </p>
        <p>
          - в случае нарушения каких-либо неимущественных и/или имущественных прав интеллектуальной собственности
          (авторских прав), коммерческой тайны и/или знаков для товаров и услуг, относящихся Исполнителю и/или иным
          правообладателям таких прав. В данном случае Договор считается расторгнутым с момента блокирования доступа
          Заказчика к его Аккаунту;
        </p>
        <p>
          - в случае выявления факта унижения со стороны Заказчика чести, достоинства и/или деловой репутации
          Исполнителя. В данном случае Договор считается расторгнутым с момента блокирования доступа Заказчика к его
          Аккаунту;
        </p>
        <p>
          - в случае совершения со стороны Заказчика любых действий, направленных на повреждение и/или взлом
          интернет-сервиса «Mesh Align», вывод его из строя или любого другого вмешательства в его работу. В данном
          случае Договор считается расторгнутым с момента блокирования доступа Заказчика к его Аккаунту;
        </p>
        <p>
          - в случае любого нарушения любых условий настоящего Договора, и/или отдельно изложенных электронных
          документов, которые являются его неотъемлемой частью. В данном случае Договор считается расторгнутым с момента
          блокирования доступа Заказчика к его Аккаунту.
        </p>
        <p>
          10.3. Через 30 календарных дней с момента расторжения настоящего Договора (независимо от того, какая именно
          сторона и на каких именно основаниях его разорвала), Исполнитель может удалить Аккаунт Заказчика с
          интернет-сервиса «Mesh Align», включая весь размещенный, загруженный ли введеный в течение всего срока
          использования Контент.
        </p>
        <p>
          10.4. Прекращение или расторжение настоящего Договора не освобождает Заказчика от любых обязанностей, запретов
          и/или ограничений по интернет-сервису «Mesh Align», которые возникли у последнего при его действии.
        </p>

        <p>11. ЛИЧНЫЕ ДАННЫЕ.</p>

        <p>
          11.1. Условия обработки, использования, распространения, перемещения и защиты персональных данных всех
          физических лиц определяются соответствующей Политикой конфиденциальности, и учитывает предписания
          национального законодательства Украины, международного права, национального законодательства страны Заказчика
          и Генерального регламента Европейского парламента и Совета по защите персональных данных.
        </p>

        <p>12. СРОК ДЕЙСТВИЯ ДОГОВОРА.</p>

        <p>
          12.1. Настоящий Договор вступает в силу с момента его заключения и действует до момента его прекращения
          (расторжения). Возможная недействительность одной или более условий данного Договора - не влечет за собой
          недействительности всего договора в целом или отдельных его частей, которые остаются в силе. Обязательства
          Сторон, которые возникли во время действия Договора, то есть до момента его прекращения (расторжения) -
          подлежат обязательному исполнению на его условиях.
        </p>
        <p>
          12.2. Во всем остальном, что прямо не предусмотрено условиями настоящего Договора, Стороны руководствуются
          национальным законодательством Украины, или, когда это является обязательным или неизбежным - предписаниями
          международного права и/или национального законодательства страны Заказчика.
        </p>
        <p>
          12.3. Заказчик без письменного разрешения Исполнителя не имеет права каким-либо образом передавать полностью
          или частично свои права и/или обязанности по настоящему Договору другим лицам. Исполнитель вправе без
          разрешения Заказчика, и без его предупреждения, каким-либо образом передавать полностью или частично свои
          права и/или обязанности по настоящему Договору другим лицам.
        </p>
        <p>12.4. Заключая данный Договор, Заказчик подтверждает и гарантирует следующее:</p>
        <p>
          - на момент Акцепта предложения (оферты) Исполнителем, Заказчик достиг совершеннолетия и/или является
          полностью дееспособным и правоспособной лицом в понимании национального законодательства страны Заказчика. В
          случае, если Заказчик является несовершеннолетним, недееспособным и/или неправоспособными лицом - и ему уже
          исполнилось 13 лет, а его родители или законные опекуны ознакомлены с настоящим Договором, Условиями
          использования и Политикой конфиденциальности;
        </p>
        <p>
          - Заказчик акцептует предложение (оферту) Исполнителя осознавая значение своих действий, не под влиянием
          заблуждения, обмана, насилия (физического и/или психического давления), или тяжелого обстоятельства;
        </p>
        <p>
          - заключая настоящий Договор, Заказчик намерен создать соответствующие правовые последствия, обусловленные им;
        </p>
        <p>- условия данного Договора для Заказчика являются полностью понятными и приемлемыми;</p>
        <p>- заключение настоящего Договора полностью соответствует внутренней воле Заказчика.</p>
        <p>
          12.5. Заключая настоящий Договор, Заказчик соглашается с последующим: в случае, если некоторые положения,
          правила, предписания и/или требования настоящего Договора (далее по тексту - «Положение») противоречат друг
          другу, имеют между собой разногласия по смыслу и сути, или любые другие несогласованности - высшую юридическую
          силу будет иметь именно то Положение, которое предоставляет Исполнителю больше прав, привилегий и льгот, а
          также является наиболее выгодным для Исполнителя с точки зрения ответственности, обязанностей и свободы
          действий. Например, если в одном Положении говорится об обязанности Исполнителя по согласованию с Заказчиком
          определенных действий или предупреждения последнего об их совершении, а в другом Положении такая обязанность -
          отсутствует, то высшую юридическую силу будет иметь то Положение, которое такую обязанность не предусматривает
          и/или освобождает от неё.
        </p>
        <p>
          12.6. Заключая настоящий Договор, Заказчик соглашается с последующим: в случае возникновения разногласий в
          толковании положений настоящего Договора, как в целом, так и отдельных его частей, текст Договора изложен на
          украинском языке - имеет большую и высокую силу.
        </p>

        <p>13. ОТКАЗ ОТ ГАРАНТИЙ И ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ.</p>

        <p>
          13.1. Исполнитель не несет ответственности за любые убытки и/или ущерб, возникшие из-за действий Заказчика,
          связанные с использованием интернет-сервиса «Mesh Align», из-за нарушения им и/или другими лицами условий
          настоящего Договора, Условий и/или политики конфиденциальности. Исполнитель в любом случае не несет
          ответственности перед Заказчиком за упущенную прибыль или выгоду, за косвенные убытки и нечаянно нанесенный
          ущерб, за убытки из-за потери возможности использования интернет-сервиса «Mesh Align» или прерывания
          деятельности Заказчика, любую потерю Контента, независимо от причин и оснований наступления данных
          обстоятельств.
        </p>
        <p>
          13.2. Размещение, загрузки или введение в интернет-сервис «Mesh Align» любого Контента осуществляется
          Заказчиком на собственное усмотрение и риск, и Исполнитель не несет ответственности за любые повреждения
          компьютерной системы Заказчика, потерю данных или иной вред, что является результатом таких действий.
        </p>
        <p>
          13.3. Заказчик не имеет права размещать, загружать или вводить в любые разделы интернет-сервиса «Mesh Align»
          любой Контент, если его содержание противоречит условиям настоящего Договора, национальному законодательству
          Украины, международному праву и/или национальному законодательству страны Заказчика. Если размещенный,
          загруженный ли введенный в любые разделы интернет-сервиса «Mesh Align» Контент противоречит требованиям
          настоящего пункта Договора, Исполнитель вправе самостоятельно удалить такой Контент из интернет-сервиса «Mesh
          Align», без согласования с Заказчиком и без его предупреждения, а также заблокировать доступ Заказчика к его
          Аккаунту в интернет-сервисе «Mesh Align».
        </p>
        <p>
          13.4. В любом случае, в том числе и в случае доказательства прямой вины Исполнителя за убытки, причиненные
          Заказчику и/или третьим лицам, предельный размер суммы возмещения убытков, или объем ответственности
          Исполнителя перед Заказчиком, не может превышать размера или объема оплаченной стоимости соответствующей
          Работы.
        </p>
        <p>
          13.5. Заказчик самостоятельно несет ответственность за безопасность (устойчивость к угадыванию) выбранного им
          пароля, а также самостоятельно обеспечивает его конфиденциальность. Заказчик самостоятельно несет
          ответственность за все свои действия или бездействия (включая их последствия) при использовании
          интернет-сервиса «Mesh Align» под его учетной записью, включая случаи добровольной передачи или несоблюдение
          конфиденциальности данных для доступа к учетной записи Заказчика третьим лицам на любых условиях. При этом,
          все действия при использовании интернет-сервиса «Mesh Align» под учетной записью Заказчика считаются
          осуществленными им самим, за исключением случая получения Исполнителем от Заказчика Письменного уведомления о
          несанкционированном использовании интернет-сервиса «Mesh Align» под его учетной записью или о каком либо ином
          нарушении (подозрения о нарушении) конфиденциальности его пароля.
        </p>
        <p>
          13.6. Заказчик обязан немедленно уведомить Исполнителя о любом случае несанкционированного (не разрешенного
          Заказчиком) доступа к интернет-сервису «Mesh Align» с помощью Аккаунта последнего и/или о любом нарушении или
          подозрении о нарушении конфиденциальности его пароля. В целях безопасности, Заказчик обязан самостоятельно
          осуществлять безопасное завершение работы в интернет-сервисе «Mesh Align» под своим аккаунтом (кнопка «Выход»)
          по окончании каждой сессии по использованию интернет-сервиса «Mesh Align». Исполнитель не несет
          ответственности за возможную потерю или порчу данных, а также другие последствия любого характера, которые
          могут произойти из-за нарушения Заказчиком положений данного пункта.
        </p>
        <p>
          13.7. Исполнитель имеет право в любой момент в одностороннем порядке по собственной инициативе и без
          согласования с Заказчиком и без его предупреждения, вносить в интернет-сервиса «Mesh Align» любые обновления
          (совершенствование), изменения или корректировки, в том числе, но не исключительно:
        </p>
        <p>
          - полностью менять или корректировать структуру, интерфейс и/или дизайн (внешний вид) интернет-сервиса «Mesh
          Align»;
        </p>
        <p>- полностью менять или корректировать алгоритмы и/или принципы работы интернет-сервиса «Mesh Align»;</p>
        <p>- удалять, добавлять, полностью менять или корректировать 3D формулы;</p>
        <p>- удалять, добавлять, полностью менять или корректировать любой функционал интернет-сервиса «Mesh Align»;</p>
        <p>
          - любым образом сокращать или расширять объем функционала интернет-сервиса «Mesh Align», удаляя, изменяя или
          добавляя отдельные его части.
        </p>
        <p>
          13.8. Исполнитель имеет право в любой момент в одностороннем порядке по собственной инициативе и без
          согласования с Заказчиком и без его предупреждения, заблокировать доступ к аккаунту последнего на основаниях,
          определенных пунктом 10.2. настоящего Договора.
        </p>
        <p>
          13.9. Исполнитель не гарантирует доступность интернет-сервиса «Mesh Align» всегда, во всех географических
          регионах, и в любое время.
        </p>
        <p>
          13.10. Исполнитель не гарантирует доступность интернет-сервиса «Mesh Align» из всех устройств, с помощью
          конкретного Интернет провайдера или другого поставщика связи.
        </p>
        <p>
          13.11. Исполнитель не дает никаких гарантий на то, что в интернет-сервисе «Mesh Align" не будут имеющиеся
          ошибки, не будет никаких пауз и/или сбоев в его работе, и что он будет совместим и/или поддерживаться любой
          аппаратно-программной и/или операционной системой.
        </p>

        <p>14. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ И АВТОРСКИЕ ПРАВА.</p>

        <p>
          14.1. Интернет-сервис «Mesh Align» является результатом интеллектуальной деятельности и объектом авторских
          прав, регулируются и защищаются национальным законодательством Украины, предписаниями международного права и
          национального законодательства страны Заказчика. Единственным владельцем исключительных имущественных прав
          интеллектуальной собственности на интернет-сервис «Mesh Align» - является Исполнитель. Он также является
          единым автором структуры и дизайна (внешнего вида) интернет-сервиса «Mesh Align».
        </p>
        <p>
          14.2. К составляющей алгоритма работы интернет-сервиса «Mesh Align» могут входить уникальные 3D формулы, с
          помощью которых в интернет-сервисе «Mesh Align» может быть реализована возможность демонстрации клинических
          случаев в трехмерном пространстве. Уникальная 3D формула является персональной разработкой Исполнителя и
          других правообладателей.
        </p>
        <p>
          14.3. Алгоритмы работы интернет-сервиса «Mesh Align», включая уникальную 3D формулу - является коммерческой
          тайной, которую запрещается разглашать, собирать и/или использовать любым другим образом, в отличие от
          определенного условиями договора. Имущественные права интеллектуальной собственности на указанную в данном
          пункте коммерческую тайну принадлежат Исполнителю и защищены в соответствии с предписаниями п. 14.1.
          настоящего Договора.
        </p>
        <p>
          14.4. Заказчику запрещается копировать, переиздавать, кадрировать, загружать, изменять, распространять,
          лицензировать, перепроектировать или распространять интернет-сервис «Mesh Align» и/или отдельные его части,
          если это явно не разрешено Исполнителем или по закону. Заказчик не имеет права декомпилировать, пытаться
          извлечь или иным образом использовать исходный код, как в целом, так и его отдельные составные части, или
          другие данные интернет-сервиса «Mesh Align», а также создавать производные от него и основанные на нем
          объекты, сайты, системы, программы, базы данных и тому подобное.
        </p>
        <p>
          14.5. За любое нарушение прав интеллектуальной собственности и/или авторских прав Исполнителя и/или других
          лиц, являющихся правообладателями таких прав, Заказчик может быть привлечен к административной,
          гражданско-правовой и / или уголовной ответственности в соответствии с национальным законодательством Украины,
          предписаниями международного права и / или национального законодательства страны Заказчика.
        </p>
        <p>
          14.6. Обозначение «Mesh Align» и его логотип (как изобразительный элемент), которые используются в
          интернет-сервисе «Mesh Align» - является знаком для товаров и услуг, правом интеллектуальной собственности,
          которое принадлежат Исполнителю и защищено в соответствии с предписаниями п. 14.1. настоящего Договора, в
          связи с чем Заказчику запрещается:
        </p>
        <p>
          - наносить такой знак на любой товар, для которого знак зарегистрирован, упаковку, в которой содержится такой
          товар, вывеску, связанную с ним, этикетку, нашивку, бирку или другой прикрепленный к товару предмет, хранение
          такого товара с указанным нанесением знака с целью предложения для продажи, предложение его для продажи,
          продажа, импорт (ввоз) и экспорт (вывоз);
        </p>
        <p>- применять его во время предложения и/или предоставления любой услуги, для которой знак зарегистрирован;</p>
        <p>- применять его в деловой документации или в рекламе и в сети Интернет.</p>

        <p className="font-bold">РЕКВИЗИТЫ И КОНТАКТНАЯ ИНФОРМАЦИЮ ИСПОЛНИТЕЛЯ:</p>
        <p className="font-bold">ФИЗИЧЕСКОЕ ЛИЦО-ПРЕДПРИНИМАТЕЛЬ ПОГАРСКИЙ АНТОН ЮРЬЕВИЧ.</p>

        <p>
          Дата и номер записи в Едином государственном реестре юридических лиц, физических лиц-предпринимателей и
          общественных формирований: 28.03.2018 года, № 21030000000096301.
        </p>

        <p>
          Адрес (место фактического проживания): 69600, Украина, Запорожская область, город Запорожье, Коммунарский
          район, ул. Реконструктивная, дом 3.
        </p>

        <p>Регистрационный номер учетной карточки налогоплательщика: 2987814459.</p>

        <p>Адрес электронной почты: meshalign@gmail.com.</p>

        <p>
          Режим работы: с понедельника по пятницу каждую неделю (кроме общемировых и украинских праздничных дней), с 10
          час. 00 мин. до 17 час. 00 мин.
        </p>

        <p>Тел .: + 38 097 977 39 98.</p>
      </section>
    </div>
  );
};

export default ElectronicContractRu;
