import React, { PureComponent } from "react";
import i18next from "../../../i18n";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import ElectronicContractEn from "./ElectronicContractEn";
import ElectronicContractRu from "./ElectronicContractRu";
import ElectronicContractUa from "./ElectronicContractUa";
import "./index.scss";

class ElectronicContract extends PureComponent {
  state = {
    language: i18next.language,
  };

  componentDidMount() {
    this.languageSelect();
  }

  languageSelect = () => {
    switch (this.state.language) {
      case "en":
        return <ElectronicContractEn />;
      case "ru":
        return <ElectronicContractRu />;
      case "ua":
        return <ElectronicContractUa />;
      default:
        return null;
    }
  };

  changeDocumentLanguage = (e) => {
    this.setState({
      language: e.target.value,
    });
  };
  render() {
    return (
      <div>
        <FormControl className="languageSelect">
          <RadioGroup row onChange={this.changeDocumentLanguage} defaultValue={this.state.language}>
            <FormControlLabel value="en" control={<Radio color="primary" />} label="ENG" labelPlacement="top" />
            <FormControlLabel value="ru" control={<Radio color="primary" />} label="RU" labelPlacement="top" />
            <FormControlLabel value="ua" control={<Radio color="primary" />} label="UKR" labelPlacement="top" />
          </RadioGroup>
        </FormControl>
        {this.languageSelect()}
      </div>
    );
  }
}

export default ElectronicContract;
