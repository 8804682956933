import React from "react";
import { Route, Switch } from "react-router";
import HomePage from "../../components/LoginLayout/HomePage";
import Classification from "../../components/LoginLayout/Сlassification";
import ElectronicContract from "../../components/LoginLayout/ElectronicContract";
import Policy from "../../components/LoginLayout/Policy";
import TermsOfUse from "../../components/LoginLayout/TermsOfUse";
import Library from "../../components/LoginLayout/Library";
import LibraryExpression from "../../components/LoginLayout/LibraryExpression";
import LibraryPD from "../../components/LoginLayout/LibraryPD";
import MalocclusionPage from "../../components/Malocclusion/MalocclusionPage";

const LoginLayout = () => {
  return (
    <div className="loginLayout">
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/classification" component={Classification} />
        <Route exact path="/electronic-contract" component={ElectronicContract} />
        <Route exact path="/policy" component={Policy} />
        <Route exact path="/terms-of-use" component={TermsOfUse} />
        <Route exact path="/classification/:item" component={MalocclusionPage} />
        <Route exact path="/libraryes" component={Library} />
        <Route exact path="/libraryes/expression" component={LibraryExpression} />
        <Route exact path="/libraryes/pogarskiy-design" component={LibraryPD} />
      </Switch>
    </div>
  );
};

export default LoginLayout;
