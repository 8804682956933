import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog } from "@material-ui/core";

class DialogCookiePolicy extends PureComponent {
  render() {
    const { t: _t, onClose, isShow } = this.props;
    return (
      <Dialog fullWidth maxWidth={"md"} className="dialogDefault" open={isShow} onClose={onClose}>
        <div className="wrap">
          <h2>{_t("Cookie Policy")}</h2>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero qui temporibus earum laboriosam? Eius
            fugiat officia tempore dicta laboriosam expedita? Numquam amet sed odit, tenetur alias asperiores modi
            voluptates deleniti. Magnam, recusandae. Alias facilis inventore, provident perspiciatis at doloremque
            maiores esse beatae cum ipsam, nisi quam aliquid repellat numquam animi in, modi possimus doloribus ad rem
            consequatur natus sint! Officiis. Asperiores eaque suscipit, laborum ut repudiandae non ducimus laudantium
            saepe minima fuga aspernatur soluta blanditiis amet est, nemo molestias quo, deleniti dicta distinctio eum
            doloribus? Deserunt maxime dolorum nisi unde. Cum, ipsum facere! Officia similique perspiciatis ad at
            laborum eligendi qui velit ullam quo ipsa maxime quidem dignissimos consequatur recusandae sequi, alias
            ipsum voluptates eaque natus quisquam, molestiae voluptas? Necessitatibus! Libero sit eveniet ipsam vel
            nobis voluptas eligendi necessitatibus aperiam voluptate fuga quae debitis repudiandae exercitationem
            laudantium alias soluta quod, tempora aliquam ad dolores! Mollitia perferendis inventore minus provident
            praesentium. Nihil omnis distinctio quam, aut doloribus dolores magni sequi corporis? Facilis labore alias
            possimus cupiditate, modi dolor maxime autem in pariatur, a laudantium obcaecati ratione eaque id earum hic
            magnam? Nemo accusantium incidunt recusandae est dolorem dolores velit non deleniti perferendis error, eius
            itaque autem beatae sequi, culpa eligendi assumenda pariatur? Blanditiis odit ipsum recusandae quasi sint
            explicabo incidunt perspiciatis? Repudiandae, ea omnis! Consequatur officia nihil, ut perspiciatis
            cupiditate laboriosam maiores nobis distinctio accusantium, ab quas magnam obcaecati minus molestias
            veritatis. Expedita, quia enim. Cum amet officia illum quibusdam adipisci. Itaque, eligendi. Labore cum
            recusandae totam quo necessitatibus perferendis adipisci dolore modi, dolores, quod ipsum. Incidunt nulla
            labore eius vitae quibusdam sequi ab, nisi officiis fugit similique corrupti a totam. Maxime ipsam qui
            mollitia at doloremque aut dolor provident nisi voluptatum libero reiciendis aperiam velit incidunt
            consequatur cum nesciunt, dolorum neque temporibus nemo iste modi totam sequi. Totam, animi ab.
          </p>
        </div>
      </Dialog>
    );
  }
}

DialogCookiePolicy.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogCookiePolicy);
