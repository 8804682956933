import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import scrollToTop from "../../../modules/scrollToTop";
import ArrowBack from "../ArrowBack";
import "./index.scss";

import Malocclusion from "../../Malocclusion";

class Сlassification extends PureComponent {
  componentDidMount() {
    scrollToTop();
  }
  render() {
    const { t: _t } = this.props;

    return (
      <div className="classification">
        <div className="overlay"></div>
        <header className="header">
          <Link className="logo" to="/">
            <span className="dark">Mesh</span>
            <span className="pink">Align</span>
          </Link>
        </header>
        <section>
          <ArrowBack path="/" text={_t("Back to home page")} />
          <h1>{_t("Сlassification of malocclusion by MeshAlign")}</h1>
          <p>
            {_t(
              "On this page you can find the types of malocclusions that can be corrected by using treatment with aligners according to MeshAlign technique. By clicking on each type of abnormality, you can see a description of pathology and the necessary materials to create a treatment plan.",
            )}
          </p>
          <p>{_t("There are no pathologies in this list that are not treated by system aligners Mesh Align.")}</p>
          <p>{_t("Namely:")}</p>
          <ul>
            <li>{_t("distal cross bite;")}</li>
            <li>{_t("skeletal Angle's Class III;")}</li>
            <li>{_t("TMJ disease and high wear.")}</li>
          </ul>
          <Malocclusion />
        </section>
      </div>
    );
  }
}

Сlassification.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Сlassification);
