import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ArrowBack from "../../LoginLayout/ArrowBack";
import "./index.scss";

import { Slider, CircularProgress } from "@material-ui/core";

import { malocclusion } from "../data";

class MalocclusionPage extends PureComponent {
  state = {
    currentPhoto: 1,
    allSlides: [],
    loadingSlides: true,
  };

  changeSlide = (value) => {
    this.setState({ currentPhoto: value });
  };

  loadingImgs = (id) => {
    this.setState(
      {
        allSlides: [...this.state.allSlides, id],
      },
      () => {
        // console.log(id);
        this.setState({
          loadingSlides: false,
        });
      },
    );
  };

  render() {
    const { t: _t } = this.props;
    const { item } = this.props.match.params;
    const { currentPhoto, loadingSlides } = this.state;
    const malocclusion1 = malocclusion(_t);
    const data = malocclusion1.find((el) => el.link === item);
    const slides = data.slides ? data.slides : null;

    return (
      <div className="malocclusionPage">
        <header className="header">
          <Link className="logo" to="/">
            <span className="dark">Mesh</span>
            <span className="pink">Align</span>
          </Link>
          <ArrowBack path="/classification" text={_t("View all malocclusion")} />
        </header>
        <section>
          <div className="item" key={data.id}>
            <h2>{data.title}</h2>
            <p>{_t("Treatment imaging")}</p>
            {slides && (
              <div className="mySlider">
                <div className="pics">
                  <>
                    {loadingSlides && <CircularProgress className="progress" />}
                    {slides.map((el) => (
                      <img
                        onLoad={() => this.loadingImgs(el.id)}
                        src={el.pic}
                        alt=""
                        key={el.id}
                        className={`${currentPhoto === el.id && "active"} ${loadingSlides ? "loading" : ""}`}
                      />
                    ))}
                  </>
                </div>
                <div className="action">
                  <Slider
                    disabled={loadingSlides}
                    defaultValue={currentPhoto}
                    step={1}
                    marks
                    min={1}
                    max={slides && slides.length}
                    onChange={(e, value) => this.changeSlide(value)}
                  />
                </div>
              </div>
            )}
            <div className="dataWrap">
              <p>{_t("Necessary input data:")}</p>
              <div className="data">
                {data.necData.map((el) => (
                  <div className="pic" title={el.title} key={el.title}>
                    <img src={el.pic} alt={el.title} />
                    <p>{el.title}</p>
                  </div>
                ))}
              </div>
              {data.clarification && (
                <>
                  <p>{_t("Clarification:")}</p>
                  {data.clarification.map((el) => (
                    <p className="clarification" key={el}>
                      {el}
                    </p>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

MalocclusionPage.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(MalocclusionPage);
