import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./index.scss";

import { Dialog, Slider, Button, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";

class MalocclusionDialog extends PureComponent {
  state = {
    currentPhoto: 1,
    allSlides: [],
    loadingSlides: true,
  };

  changeSlide = value => {
    this.setState({ currentPhoto: value });
  };

  loadingImgs = id => {
    this.setState(
      {
        allSlides: [...this.state.allSlides, id],
      },
      () => {
        this.setState({
          loadingSlides: false,
        });
      },
    );
  };

  render() {
    const { t: _t, data, isShow, toggle } = this.props;
    const { currentPhoto, loadingSlides } = this.state;
    const slides = data.slides ? data.slides : null;
    return (
      <Dialog fullScreen open={isShow} onClose={toggle} className="malocclusionDialog">
        <header className="header">
          <div className="logo">
            <span className="dark">Mesh</span>
            <span className="pink">Align</span>
          </div>
          <Button onClick={toggle}>
            <Close />
          </Button>
        </header>
        <section>
          <div className="item" key={data.id}>
            <h2>{data.title}</h2>
            <p>{_t("Treatment imaging")}</p>
            {slides && (
              <div className="mySlider">
                <div className="pics">
                  <>
                    {loadingSlides && <CircularProgress className="progress" />}
                    {slides.map(el => (
                      <img
                        onLoad={() => this.loadingImgs(el.id)}
                        src={el.pic}
                        alt=""
                        key={el.id}
                        className={`${currentPhoto === el.id && "active"} ${loadingSlides ? "loading" : ""}`}
                      />
                    ))}
                  </>
                </div>
                <div className="action">
                  <Slider
                    disabled={loadingSlides}
                    defaultValue={currentPhoto}
                    step={1}
                    marks
                    min={1}
                    max={slides && slides.length}
                    onChange={(e, value) => this.changeSlide(value)}
                  />
                </div>
              </div>
            )}
            <div className="dataWrap">
              <p>{_t("Necessary input data:")}</p>
              <div className="data">
                {data.necData.map(el => (
                  <div className="pic" title={el.title} key={el.title}>
                    <img src={el.pic} alt={el.title} />
                    <p>{el.title}</p>
                  </div>
                ))}
              </div>
              {data.clarification && (
                <>
                  <p>{_t("Clarification:")}</p>
                  {data.clarification.map(el => (
                    <p className="clarification" key={el}>
                      {el}
                    </p>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
      </Dialog>
    );
  }
}

MalocclusionDialog.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(MalocclusionDialog);
