import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import scrollToTop from "../../../modules/scrollToTop";
import Expression from "../../../assets/img/Expression.png";
import PDL from "../../../assets/img/PDL.png";
import ArrowBack from "../ArrowBack";
import "./index.scss";

const Library = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const { t: _t } = useTranslation();

  return (
    <div className="library">
      <header className="header">
        <Link className="logo" to="/">
          <span className="dark">Mesh</span>
          <span className="pink">Align</span>
        </Link>
      </header>
      <section>
        <ArrowBack text={_t("TO THE HOME PAGE")} path="/" />
        <h1>{_t("Teeth libraries")}</h1>
        <div className="content">
          <p>
            {_t(
              "You are presented with the libraries developed by MeshAlign specialists to create mockups and smile designs. This library is present as 2D styles and 3D models of teeth, which allows you to accurately repeat the smile design in the shape of future teeth. Choose a style for the future shape of teeth.",
            )}
          </p>
        </div>
        <div className="content-img">
          <Button component={Link} to="/libraryes/expression">
            <img className="content-img-expression" src={Expression} alt="" />
          </Button>
          <Button component={Link} to="/libraryes/pogarskiy-design">
            <img className="content-img-pdl" src={PDL} alt="" />
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Library;
