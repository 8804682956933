import React from "react";
import ArrowBack from "../../ArrowBack";
import { Link } from "react-router-dom";
import "./index.scss";

const TermsOfUseUa = () => (
  <div className="classification">
    <div className="overlay" />
    <header className="header">
      <Link className="logo" to="/">
        <span className="dark">Mesh</span>
        <span className="pink">Align</span>
      </Link>
    </header>
    <section>
      <ArrowBack path="/" text="Назад на головну сторінку" />
      <h2>ЕЛЕКТРОННЕ ПОВІДОМЛЕННЯ</h2>
      <p className="font-bold text-center">перед використанням інтернет-сервісу «Mesh Align»</p>

      <p className="font-bold">ШАНОВНИЙ ЗАМОВНИК!</p>

      <p className="f-18">
        БУДЬ ЛАСКА, УВАЖНО ОЗНАЙОМТЕСЯ ІЗ ВИКЛАДЕНИМИ НИЖЧЕ УМОВАМИ ВИКОРИСТАННЯ (далі по тексту – «Умови»), ЯКІ Є
        НЕВІД’ЄМНОЮ ЧАСТИНОЮ ЕЛЕКТРОННОГО ДОГОВОРУ НА ВИКОНАННЯ РОБІТ (далі по тексту – «Договір»), НАВЕДЕНОГО ЗА
        АДРЕСОЮ: https://meshalign.com/electronic-contract.
      </p>
      <p className="f-18">
        ПРИЙМАЮЧИ (АКЦЕПТУЮЧИ) ПУБЛІЧНУ ПРОПОЗИЦІЮ УКЛАСТИ ДОГОВІР, ВИ АВТОМАТИЧНО ПРИЙМАЄТЕ УМОВИ, І ПОГОДЖУЄТЕСЬ
        СУМЛІННО ТА НЕУХИЛЬНО ЇХ ВИКОНУВАТИ.
      </p>
      <p className="f-18">ДАНЕ ЕЛЕКТРОННЕ ПОВІДОМЛЕННЯ Є НЕВІД’ЄМНОЮ ЧАСТИНОЮ ДОГОВОРУ.</p>

      <p className="font-bold text-center">УМОВИ ВИКОРИСТАННЯ</p>

      <p>Редакція від: 27.04.2020 року.</p>

      <p>
        Даною Політикою конфіденційності (далі по тексту – «Умови») визначаються умови розміщення, завантаження та/або
        введення до будь-яких розділів інтернет-сервісу «Mesh Align» (далі по тексту – інтернет-сервіс «Mesh Align»)
        персональних даних будь-яких фізичних осіб чи іншої конфіденційної інформації, а також умови обробки,
        використання, поширення, переміщення і захисту таких даних та інформації. Дана Політика є невід’ємною частиною
        електронного Договору на виконання робіт (далі по тексту – «Договір»), який наведено за адресою:
        https://meshalign.com/electronic-contract.
      </p>

      <p>1. ОСНОВНІ ТЕРМІНИ ТА ВИЗНАЧЕННЯ.</p>

      <p>
        1.1. «Авторизація» – електронний процес входу Замовника до його Акаунту, під час якого відбувається перевірка
        наявності прав останнього на надання йому відповідного доступу до такого Акаунту. Для Авторизації Замовник
        повинен ввести у відповідну електронну форму свої Ідентифікатори, та підтвердити намір здійснити вхід до
        Акаунту.
      </p>
      <p>
        1.2. «Акаунт» – сукупність електронних відомостей щодо Замовника, які останній розміщує, завантажує чи вводить в
        інтернет-сервісі «Mesh Align», і які включають в себе Обліковий запис, Профіль, та Особистий кабінет. Складовою
        частиною Акаунту також є Контент. Для використання інтернет-сервісу «Mesh Align» та можливості здійснювати
        замовлення виконання робіт – потрібен Акаунт.
      </p>
      <p>
        1.3. «Акцепт» – повне та безумовне прийняття Замовником пропозиції (оферти) Виконавця укласти даний Договір на
        визначених ним умовах (пункти 3.1. та 3.2. Договору).
      </p>
      <p>1.4. «Ідентифікатори» – унікальні Логін та Пароль, які створюються особисто Замовником.</p>
      <p>
        1.5. «Інтернет» – глобальна інформаційна мережа загального доступу, яка базується на Інтернет-протоколах,
        визначених міжнародними стандартами.
      </p>
      <p>
        1.6. «Контент» – текстові (письмові) або відео матеріали, аудіо записи чи зображення, а також будь-які інші
        матеріали чи інформація, що розміщуються, завантажуються чи вводяться Замовником у будь-які розділи
        інтернет-сервісу «Mesh Align». Для цілей даного Договору, Контентом, в тому числі, але не виключно, також є
        відповідні електронні текстові повідомлення, електронні медичні картки пацієнтів, будь-які завантажені до
        інтернет-сервісу «Mesh Align» електронні комп’ютерні файли будь-якого формату, будь-які електронні цифрові
        зображення, будь-яке електронне цифрове відео, будь-яка інформація, та будь-які персональні дані будь-яких осіб.
        Контент є складовою частиною Акаунту.
      </p>
      <p>
        1.7. «Логін» – унікальний набір символів, що ідентифікує Замовника в інтернет-сервісі «Mesh Align», виражений у
        вигляді адреси електронної пошти, що знаходиться у безперешкодному та особистому використанні Замовника. Логін є
        елементом Облікового запису. Виконавець має право заборонити використання певних Логінів, а також встановлювати
        щодо них вимоги (довжина, допустимі символи, та ін.).
      </p>
      <p>
        1.8. «Обліковий запис» – сукупність розміщеної, завантаженої чи введеної до інтернет-сервісу «Mesh Align»
        інформації про Замовника.
      </p>
      <p>
        1.9. «Особистий кабінет» – розділ інтернет-сервісу «Mesh Align», доступ до якого має тільки зареєстрований
        Замовник після проходження електронної процедури Авторизації за допомогою Ідентифікаторів, та через який
        Замовник здійснює управляння Обліковим записом.
      </p>
      <p>
        1.10. «Пароль» – таємне слово або певна послідовність символів, призначена для підтвердження особи Замовника та
        його прав. Пароль використовується для захисту інформації, наявної у інтернет-сервісі «Mesh Align» від
        несанкціонованого доступу будь-яких сторонніх осіб. Пароль є елементом Облікового запису, та використовуються
        інтернет-сервісом «Mesh Align» для надання Замовнику дозволу на вхід до його Акаунту. Виконавець має право
        заборонити використання певних Паролів, а також встановлювати щодо них вимоги (довжина, допустимі символи, та
        ін.).
      </p>
      <p>
        1.11. «Письмове повідомлення» – електронний лист, що може містити в собі текстові (письмові) або відео
        матеріали, аудіо записи чи зображення, надісланий будь-якою Стороною даного Договору одна одній на відповідну
        адресу електронної пошти. Для цілей даного Договору, адресою електронної пошти Замовника є адреса, зазначена
        останнім в якості його Логіну, а адресою електронної пошти Виконавця є адреса, зазначена в даному Договорі серед
        інших контактних даних останнього. Письмове повідомлення може бути викладене українською, англійською або
        російською мовами.
      </p>
      <p>
        1.12. «Профіль» – сукупність персональних відомостей про Замовника, вказаних останнім при Реєстрації в
        інтернет-сервісі «Mesh Align» шляхом заповнення відповідної реєстраційної форми.
      </p>
      <p>
        1.13. Інтернет-сервіс «Mesh Align» – веб-сайт, розміщений в мережі Інтернет за адресою https://meshalign.com, за
        допомогою якого Замовник має можливість замовляти у Виконавця виконання робіт за даним Договором.
      </p>
      <p>
        1.14. «Реєстрація» – електронна процедура, під час якої Замовник заповнює відповідну реєстраційну форму, та
        створює у інтернет-сервісі «Mesh Align» Акаунт. Для цілей даного Договору, Реєстрація є дією Замовника, яка
        вважається повним та безумовним прийняттям пропозиції (оферти) Виконавця укласти даний Договір.
      </p>

      <p>2. ЗАГАЛЬНІ ПОЛОЖЕННЯ.</p>

      <p>
        2.1. Виконавець здійснює поточне управління інтернет-сервісом «Mesh Align», визначає його структуру та дизайн
        (зовнішній вигляд), дозволяє, призупиняє, обмежує чи блокує доступ до неї на підставі Договору, даних Умов, та
        Політики конфіденційності, яка наведена за адресою: https://meshalign.com/electronic-contract,
        https://meshalign.com/terms-of-use, https://meshalign.com/policy.
      </p>
      <p>
        2.2. Виконавець надає технічну можливість використання інтернет-сервісу «Mesh Align», та не бере участі у
        формуванні (створенні) Акаунту, Облікового запису, Профілю, та Особистого кабінету Замовника.
      </p>
      <p>2.3. Реєстрація в інтернет-сервісі «Mesh Align» – є безкоштовною.</p>
      <p>
        2.4. Виконавець має право в будь-який момент вносити до інтернет-сервісу «Mesh Align» будь-які оновлення
        (вдосконалення), зміни чи коригування, згідно із приписами п. 13.7. Договору. Також, Виконавець може у будь-який
        зручний для себе час проводити будь-які технічні роботи щодо інтернет-сервісу «Mesh Align» (наприклад – його
        оновлення, виправлення помилок у роботі, тощо), під час яких доступ до нього тимчасово може бути заблокований. В
        даному випадку, Виконавець завчасно попереджує Замовника про майбутні технічні роботи такого роду, із
        зазначенням часу їх проведення та загальної тривалості.
      </p>
      <p>
        2.5. Замовнику заборонено здійснювати будь-яку модифікацію інтернет-сервісу «Mesh Align», його структури та
        дизайну (зовнішнього вигляду) під себе чи під його програмно-апаратне середовище.
      </p>
      <p>
        2.6. Замовник не може використовувати інтернет-сервіс «Mesh Align», якщо легітимні санкції будь-якої країни
        забороняють це у відповідному регіоні, в якому Замовник перебуває.
      </p>
      <p>2.7. Особам віком до 13 років використовувати інтернет-сервіс «Mesh Align» – заборонено.</p>
      <p>
        2.8. Якщо будь-яка особа є резидентом Європейської економічної зони, їй дозволяється використовувати
        інтернет-сервіс «Mesh Align» тільки при досягненні віку, в якому така особа може надати згоду на обробку
        персональних даних згідно із приписами національного законодавства відповідної країни, яка входить до складу
        Європейської економічної зони.
      </p>
      <p>
        2.9. Додаткова плата за використання мережі Інтернет для доступу до інтернет-сервісу «Mesh Align» – покривається
        за рахунок Замовника.
      </p>
      <p>
        2.10. Укладаючи Договір, Замовник погоджується із наступним: в разі, якщо деякі положення, правила, приписи
        та/або вимоги Договору, даних Умов та/або Політики конфіденційності (далі по тексту – «Положення») суперечать
        один одному, мають між собою розбіжності за смислом та суттю, чи будь-які інші неузгодженості – найвищу юридичну
        силу буде мати саме те Положення, яке надає Виконавцю більше прав, привілеїв та пільг, а також є найбільш
        вигідним для Виконавця з точки зору відповідальності, обов’язків, та свободи дій. Наприклад, якщо в одному
        Положенні йдеться про обов’язок Виконавця щодо узгодження із Замовником певних дій, чи попередження останнього
        про їх вчинення, а в іншому Положенні такий обов’язок – відсутній, то найвищу юридичну силу буде мати те
        Положення, яке такий обов’язок не передбачає та/або звільняє від нього.
      </p>
      <p>
        2.11. Укладаючи Договір, Замовник погоджується із наступним: в разі виникнення розбіжностей щодо тлумачення
        даних Умов, як в цілому, так і окремих їх частин та положень, текст Умов викладений українською мовою – має
        переважну та найвищу силу.
      </p>

      <p>3. АКАУНТ.</p>

      <p>
        3.1. Для доступу до інтернет-сервісу «Mesh Align» та його використання потрібен Акаунт, який створюється
        особисто Замовником шляхом Реєстрації. Для можливості здійснити Реєстрацію, необхідно подати Виконавцю
        відповідну електрону заявку, зазначивши у ній діючу адресу електронної пошти, прізвище, ім’я, та по батькові.
      </p>
      <p>
        3.2. Реєстрація включає в себе певну акредитацію Замовника на відповідність визначеним Виконавцем критеріям, які
        є обов’язковими для співпраці. Акредитація виконується адміністратором інтернет-сервісу «Mesh Align», та
        передбачає право Виконавця перед Реєстрацією Замовника запитувати у останнього певну інформацію та/або
        пропонувати надати деякі документи щодо рівня професійної підготовки, наявності необхідної освіти та
        кваліфікації. За результатами розгляду поданої електронної заявки, адміністратор інтернет-сервісу «Mesh Align»
        повідомляє про прийняте рішення за допомогою електронної пошти. У разі невідповідності особи визначеним
        критеріям для співпраці, Виконавець має право відмовити в Реєстрації.
      </p>
      <p>
        3.3. Для Реєстрації Замовнику необхідно мати діючу адресу електронної пошти, яка у подальшому виконує роль
        Логіну для Авторизації у інтернет-сервісі «Mesh Align».
      </p>
      <p>
        3.4. Під час Реєстрації Замовник зобов’язаний вказувати достовірні дані щодо своєї особи, а саме: прізвище,
        ім’я, та по батькові, інформація щодо займаної посади та місця роботи, фотографія Профілю (за бажанням), номер
        телефону для зв’язку, країна знаходження, адреса електронної поти. Замовник не зобов'язаний розкривати свою
        особистість, але останній повинен надати Виконавцю точну та актуальну інформацію (в тому числі Реєстраційну).
        Замовник не маєте права видавати себе за того, ким він не є насправді, а також не має права створювати Акаунти
        для інших осіб.
      </p>
      <p>3.5. Логін повинен складатися тільки з букв англійського алфавіту, цифр та символів таблиці кодів «ASCII».</p>
      <p>3.6. Пароль повинен відповідати наступним вимогам:</p>
      <p>- містити не менше 6-ти знаків;</p>
      <p>- містити тільки латинські букви і цифри (без розділових знаків, пропусків та інших спеціальних знаків);</p>
      <p>- містити мінімум 1 латинські букви;</p>
      <p>- містити мінімум 1 цифри.</p>
      <p>
        Пароль не повинен співпадати із Логіном, його не рекомендується пов’язувати із особою Замовника. Пароль має бути
        таким, щоб Замовник міг його запам’ятати, не записуючи на аркуш та/або до будь-якого пристрою для зберігання
        даних.
      </p>
      <p>
        3.7. Замовник несе повну та персональну відповідальність за будь-які дії у інтернет-сервісі «Mesh Align», що
        виконуються з використанням його Акаунту. Виконавець має право заблокувати доступ Замовника до його Акаунту у
        інтернет-сервісі «Mesh Align», якщо такий Акаунт використовується із порушенням даних Умов, а також на підставі
        і у випадках, визначених у п. 10.2 Договору.
      </p>
      <p>
        3.8. Для Реєстрації Акаунту, Замовник повинен досягти повноліття та/або бути повністю дієздатною та правоздатною
        особою у розумінні національного законодавства країни останнього. В разі, якщо Замовник є неповнолітньою,
        недієздатною та/або неправоздатною особою, він повинен ознайомитися із даними Умовами разом із його батьками чи
        законними опікунами. Оскільки Виконавець позбавлений будь-якої практичної можливості здійснювати контроль
        дотримання з боку Замовника чи його батьків або законних опікунів викладених в даному пункті вимог – Виконавець
        звільняється від будь-якої відповідальності за настання будь-яких негативних наслідків через використання
        інтернет-сервісу «Mesh Align». Така відповідальність в повному обсязі покладається особисто на Замовника чи на
        його батьків або законних опікунів.
      </p>
      <p>
        3.9. Замовник зобов’язаний вживати усіх необхідних та можливих заходів щодо конфіденційності його Паролю. За
        необхідності, Замовник у будь-який час може такий Пароль замінити. З метою запобігання будь-якого
        несанкціонованого використання Акаунту Замовника, Виконавець може обмежити кількість спроб введення Паролю під
        час Авторизації.
      </p>
      <p>
        3.10. У випадку, якщо внаслідок несанкціонованого доступу сторонніх осіб, Замовник втратив доступ до його
        Акаунту, він зобов’язаний невідкладно повідомити про це Виконавця шляхом відправлення відповідного Письмового
        повідомлення, з метою запобігання знищенню чи пошкодженню Контенту, несанкціонованого передання контролю над
        Акаунтом, тощо.
      </p>
      <p>
        3.11. Виконавець вживає усіх зусиль для забезпечення безпеки Акаунту Замовника, включаючи його Контент, але, не
        дивлячись на це, не гарантує, що несанкціоновані треті особи не зможуть подолати такі заходи безпеки.
      </p>

      <p>4. КОНТЕНТ.</p>

      <p>
        4.1. Замовник несе повну та персональну відповідальність за будь-який Контент та його зміст, що розміщується,
        завантажується чи вводиться Замовником у будь-які розділи інтернет-сервісу «Mesh Align».
      </p>
      <p>
        4.2. Замовник не має права розміщувати, завантажувати чи вводити у інтернет-сервіс «Mesh Align» Контент, зміст
        якого порушує будь-які особисті немайнові чи майнові права інших осіб, включаючи права на конфіденційність,
        права інтелектуальної власності та авторські права, права на персональні дані, тощо, чи приписи національного
        законодавства України, міжнародного права та/або національного законодавства країни Замовника.
      </p>
      <p>
        4.3. Виконавець не здійснює моніторинг та аналіз Контенту Замовника, не заохочує і не стверджує його зміст, та
        не примушує останнього до розміщення, завантаження чи введення такого Контенту до інтернет-сервісу «Mesh Align».
      </p>
      <p>
        4.4. Умови розміщення, завантаження чи введення до інтернет-сервісу «Mesh Align», а також обробки, використання,
        поширення, та переміщення Контенту, який містить в собі персональні дані будь-яких фізичних осіб – визначаються
        Політикою конфіденційності, та враховує приписи національного законодавства України, міжнародного права,
        національного законодавства країни Замовника, та Генерального регламенту Європейського парламенту і Ради щодо
        захисту персональних даних.
      </p>
      <p>
        4.5. У інтернет-сервісі «Mesh Align» можуть міститися посилання на сторонні веб-сайти, рекламодавців, сервіси,
        спеціальні пропозиції та інші заходи або діяльність, які не належать Виконавцю та не контролюються ним.
        Виконавець не підтверджує і не бере на себе будь-яку відповідальність щодо таких сторонніх веб-сайтів,
        інформації, матеріалів, товарів або послуг. Якщо Замовник переходить на будь-який сторонній веб-сайт, сервіс або
        контент через інтернет-сервіс «Mesh Align», він несе за це повну та персональну відповідальність і дає згоду на
        те, що Виконавець не матиме перед ним зобов'язань у зв'язку із тим, що він використовував або перейшов на
        сторонній веб-сайт чи сервіс. Виконавець відмовляється від будь-якої відповідальності, якщо такі сторонні
        веб-сайти або сервіси:
      </p>
      <p>- порушують права інтелектуальної власності чи авторські права будь-яких третіх сторін;</p>
      <p>- є неточними, неповними або дезінформуючими;</p>
      <p>- є комерційно невигідними й не підходять для виконання спеціальної цілі чи виконання певної мети;</p>
      <p>- не забезпечують належну захищеність;</p>
      <p>- містять віруси або інші об'єкти руйнівного характеру;</p>
      <p>- є наклепницькими або кривдять людську гідність.</p>
      <p>
        4.6. Виконавець залишає за собою право, але не обов’язок, на свій розсуд без попереднього повідомлення Замовника
        видаляти Контент або його окремі елементи, якщо на думку Виконавця їх зміст порушує приписи Договору, даних Умов
        та Політики конфіденційності.
      </p>
      <p>
        4.7. В разі припинення дії Договору чи його розірвання (незалежно від того, яка саме Сторона і на яких саме
        підставах його розірвала) Виконавець має право на свій розсуд, без спеціального дозволу Замовника, зберігати у
        інтернет-сервісі «Mesh Align» (не видаляти з нього) Контент, який було розміщено, завантажено чи уведено
        Замовником до інтернет-сервісу «Mesh Align» протягом всього строку його використання. Виконавець може зберігати
        такий Контент у інтернет-сервісі «Mesh Align» протягом будь-якого строку. Контент або його частина підлягає
        обов’язковому видаленню з інтернет-сервісу «Mesh Align» в разі отримання Виконавцем відповідного Письмового
        повідомлення із вимогою про таке видалення.
      </p>

      <p>5. ПРАВИЛА ПОВЕДІНКИ.</p>

      <p>
        5.1. Використовуючи інтернет-сервіс «Mesh Align», Замовник зобов’язаний сумлінно та неухильно дотримуватись
        наступних правил поведінки:
      </p>
      <p>
        - не порушувати приписи та вимоги національного законодавства України, міжнародного права та/або національного
        законодавства країни Замовника під час використання інтернет-сервісу «Mesh Align»;
      </p>
      <p>- не використовувати інтернет-сервіс «Mesh Align» в будь-яких незаконних чи заборонених цілях;</p>
      <p>- не Реєструватися під прізвищем та ім’ям іншої особи, видаючи себе за неї;</p>
      <p>- не розміщувати у своєму Профілі зображення інших осіб в якості власної фотографії;</p>
      <p>
        - не порушувати роботу інтернет-сервісу «Mesh Align», не використовувати будь-яке програмне забезпечення, яке
        пошкоджує, змінює, перериває інтернет-сервіс «Mesh Align», та/або припиняє його роботу, чи іншим чином впливає
        на його нормальне та стабільне функціонування;
      </p>
      <p>- не здійснювати несанкціонований доступ (спроби доступу) до Акаунту інших Замовників;</p>
      <p>
        - не розміщувати, не завантажувати та не вводити до інтернет-сервісу «Mesh Align» Контент, зміст якого може бути
        шкідливим для дітей;
      </p>
      <p>
        - не розміщувати, не завантажувати та не вводити до інтернет-сервісу «Mesh Align» Контент, який є недоречним,
        образливим, заподіючим неспокій, блюзнірським, загрозливим, повним ненависті, непристойним, вульгарним, що
        дискредитує, принижує, ущемляє, чи порушує конфіденційність інших людей або є неприпустимим з інших причин;
      </p>
      <p>
        - не розміщувати, не завантажувати та не вводити до інтернет-сервісу «Mesh Align» Контент, який є нелегальним
        (протизаконним), чи Контент, щодо якого у Замовника не має прав на його вільне розповсюдження;
      </p>
      <p>- не видавати себе за іншу особу, чи за співробітника Виконавця або його представника;</p>
      <p>
        - не намагатися отримати від інших Замовників відомості щодо їх Паролю, даних щодо їх Акаунту, чи будь-яку іншу
        приватну та/або персональну інформацію;
      </p>
      <p>
        - не користуватися будь-якими засобами оплати в шахрайських цілях, та без дозволу уповноваженого володільця;
      </p>
      <p>
        - не використовувати роботів, програм-обхідників чи інших автоматизованих пристроїв для доступу до
        інтернет-сервісу «Mesh Align», та/або для копіювання його матеріалів;
      </p>
      <p>
        - не використовувати заборонені програми або інші засоби для шахрайства, пошуку вразливостей у інтернет-сервісі
        «Mesh Align», його злому чи внесення до нього змін;
      </p>
      <p>
        - не використовувати виявлені в роботі інтернет-сервісу «Mesh Align» вразливості, збої та/або помилки у
        протиправних та/або корисливих цілях;
      </p>
      <p>
        - не продавати, не дарувати, не обмінювати, на здавати в оренду, не передавати у тимчасове користування чи
        будь-яким іншим способом не відчужувати свій Акаунт іншим особам;
      </p>
      <p>
        - не використовувати інтернет-сервіс «Mesh Align» країні, на території якої законодавство забороняє Виконавцю
        виконувати визначені Договором Роботи;
      </p>
      <p>
        - не використовувати інтернет-сервіс «Mesh Align» для політичних, пропагандистських, рекламних та/або релігійних
        цілей;
      </p>
      <p>
        - не передавати за допомогою інтернет-сервісу «Mesh Align» віруси, зловмисне та шпіонське програмне забезпечення
        чи будь-які інші елементи руйнівного характеру.
      </p>
      <p>
        5.2. В разі, якщо Замовник використовуючи Акаунт порушує визначені в п. 5.1. даних Умов правила поведінки, та не
        усуває чи не припиняє такі порушення протягом однієї доби після відправлення Виконавцем відповідного Письмового
        повідомлення із попередженням про їх усунення чи припинення, Виконавець має право заблокувати доступ до такого
        Акаунту на необмежений час, із подальшим його видаленням з інтернет-сервісу «Mesh Align», включаючи увесь
        розміщений, завантажений чи введений Замовником Контент.
      </p>
      <p>
        5.3. Використовуючи інтернет-сервіс «Mesh Align», Замовник гарантує, що будь-які його дії не суперечать
        національному законодавству України, міжнародному праву та/або національному законодавству країни Замовника, не
        порушують будь-які особисті немайнові чи майнові права будь-яких осіб, включаючи права на конфіденційність,
        права інтелектуальної власності та авторські права.
      </p>

      <p>6. ВИКОРИСТАННЯ ДАНИХ.</p>

      <p>
        6.1. Укладаючи Договір, Замовник погоджується із тим, що Виконавець може збирати, зберігати та використовувати
        будь-які дані щодо персонального комп’ютера Замовника, чи будь-якого іншого подібного пристрою, яким останній
        користується для доступу до інтернет-сервісу «Mesh Align», включаючи дані про використовувані на таких пристроях
        операційні системи, про їх IP-адреси, тощо.
      </p>
      <p>
        6.2. Виконавець збирає, зберігає та використовує визначені в п. 6.1. Умов дані виключно для покращення якості
        функціоналу інтернет-сервісу «Mesh Align», та для усунення можливих збоїв чи помилок в її роботі на відповідних
        персональних комп’ютерах або інших подібних пристроях Замовника.
      </p>
      <p>
        6.3. Збирання, зберігання та використання визначених в п. 6.1. Умов даних здійснюється Виконавцем згідно із
        Політикою конфіденційності.
      </p>
      <p>
        6.4. Виконавець має право вживати у будь-який час будь-яких заходів, які вважає обґрунтовано необхідними або
        доречними для забезпечення дотримання вимог Договору, даних Умов, Політики конфіденційності, національного
        законодавства України, міжнародного права та/або національного законодавства країни Замовника. Замовник визнає
        та погоджуєтеся із тим, що Виконавець може без зобов'язань перед Замовником отримувати доступ, використовувати,
        зберігати та/або розкривати персональну інформацію та/або зміст Контенту Замовника правоохоронним органам, іншим
        органам державної влади та третім особам у разі, якщо Виконавець вважає такі дії обґрунтовано необхідними або
        доречними, або якщо це необхідно відповідно до національного законодавства України, міжнародного права та/або
        національного законодавства країни Замовника, або якщо Виконавець сумлінно передбачає, що подібний доступ,
        використання, розкриття або зберігання обґрунтовано необхідні для:
      </p>
      <p>‐ виконання судового рішення або іншого виконавчого документу;</p>
      <p>- виконання будь-якого правомірного припису з боку відповідного контролюючого державного органу;</p>
      <p>‐ забезпечення виконання Договору, включаючи розслідування будь-якого можливого порушення;</p>
      <p>
        ‐ виявлення, запобігання або іншого реагування на загрози національній безпеці, шахрайство чи інші
        правопорушення, або технічні несправності;
      </p>
      <p>
        ‐ захисту прав, власності або безпеки Виконавця, інших Замовників, третіх осіб, суспільства або держави, як того
        вимагає або передбачає національне законодавство України, міжнародне право та/або національне законодавство
        країни Замовника.
      </p>

      <p>7. ТЕХНІЧНА ПІДТРИМКА.</p>

      <p>
        7.1. Виконавець здійснює технічну підтримку інтернет-сервісу «Mesh Align» з питань, пов'язаних з
        функціональністю, особливостями його експлуатації. Технічна підтримка являє собою заходи, що здійснюються
        Виконавцем у встановлених ним межах та обсягах для забезпечення функціонування інтернет-сервісу «Mesh Align»,
        включаючи інформаційно-консультаційну підтримку з питань його використання.
      </p>
      <p>
        7.2. Замовник має право звертатися в службу технічної підтримки Виконавця без виплати додаткової винагороди.
      </p>
      <p>
        7.3. Для можливості надання якісної, своєчасної та результативної технічної підтримки Виконавець має право
        вимагати від Замовника надання будь-якої інформації, що стосується даних його Акаунту, Особистого кабінету,
        Профілю, Облікового запису, технічних характеристик його персонального комп’ютера, чи будь-якого іншого
        подібного пристрою, яким останній користується для доступу до інтернет-сервісу «Mesh Align».
      </p>
      <p>
        7.4. Якщо при використанні інтернет-сервісу «Mesh Align» будуть виявлені помилки, Виконавець вживатиме заходів
        для їх виправлення в максимально короткі строки. Замовник погоджується, що точне визначення строку усунення
        помилки не може бути встановлено наперед, оскільки Програма тісно взаємодіє з іншими програмами сторонніх
        розробників, операційними системами і апаратними ресурсами різних комп’ютерів, і працездатність і час усунення
        проблем може залежати не тільки від Виконавця.
      </p>

      <p>8. ПРАВОВЛАСНИКАМ.</p>

      <p>
        8.1. Якщо будь-яка фізична особа (незалежно від громадянства, країни проживання, місця перебування, расової
        приналежності, політичних поглядів та віросповідання) або юридична особа (незалежно від форми власності,
        організаційно-правової структури, місця реєстрації) вважає, що будь-який розміщений, завантажений чи уведений до
        інтернет-сервісу «Mesh Align» Контент, порушує будь-які її особисті немайнові чи майнові права, включаючи права
        на конфіденційність, права інтелектуальної власності та авторські права, права на персональні дані, тощо – така
        особа, або її належним чином уповноважений представник може в будь-який час звернутися до Виконавця із
        відповідним письмовим зверненням.
      </p>
      <p>
        8.2. Письмове звернення має бути викладене переважно українською, англійською або російською мовами, та
        направлено Виконавцю на наступну адресу електронної пошти: meshalign@gmail.com. Письмове звернення обов’язково
        повинно в собі містити:
      </p>
      <p>
        - П.І.Б. або найменування особи, що направила письмове звернення, її контактні дані (адресу електронної пошти
        для листування, повний номер телефону, тощо);
      </p>
      <p>
        - в разі, якщо письмове звернення направлене уповноваженим представником – копії документів (або URL-посилання
        на них), які підтверджують його повноваження діяти від імені власника порушених прав;
      </p>
      <p>
        - детальний опис оскаржуваного Контенту, який порушує права, за можливістю – URL-посилання, за яким його
        розміщено, завантажено чи введено до інтернет-сервісу «Mesh Align», або інші обставини, за якими такий Контент
        можна знайти та/або ідентифікувати;
      </p>
      <p>- інформацію про те, яким саме чином та які саме оскаржуваний Контент права порушує;</p>
      <p>- інформацію про особу, якій порушені права належать;</p>
      <p>
        - копії будь-яких офіційних документів (або URL-посилання на них), які підтверджують належність порушених прав
        відповідній особі;
      </p>
      <p>- чітке та зрозуміле формулювання відповідної вимоги щодо усунення та/або припинення порушення прав.</p>
      <p>
        8.3. В разі необхідності, для належного та об’єктивного розгляду відповідного письмового звернення, Виконавець
        має право запитувати чи вимагати у особи, яка його направила, надання додаткової інформації та/або додаткових
        документів.
      </p>
      <p>
        8.4. За наявності практичної можливості, та за відсутності обставин непереборної сили, Виконавець вжитиме усіх
        зусиль для того, щоб відповідне письмове звернення було розглянуто протягом десяти робочих днів з моменту його
        отримання. Повідомлення про результати такого розгляду Виконавець направляє у письмовому вигляді на відповідну
        адресу електронної пошти особи, яка направила письмове звернення, або повідомляє їй про це за допомогою
        телефонного зв’язку, в разі наявності повного номеру телефону, за яким з такою особою можна зв’язатися.
      </p>

      <p>9. РЕКЛАМНІ МАТЕРІАЛИ.</p>

      <p>
        9.1. Використовуючи інтернет-сервіс «Mesh Align», Замовник усвідомлює, не заперечує та погоджується щодо
        наступного: Виконавець має право за допомогою інтерфейсу та функціоналу інтернет-сервісу «Mesh Align», у
        будь-який час, будь-яким способом демонструвати Замовнику будь-які матеріали рекламного характеру.
      </p>
      <p>
        9.2. Укладаючи Договір, Замовник надає свою безумовну згоду на демонстрацію у інтернет-сервісі «Mesh Align»
        останньому будь-яких матеріалів рекламного характеру, без виплати Замовнику з боку Виконавця чи інших осіб
        будь-якої грошової винагороди та/або компенсації.
      </p>

      <p>10. КОМЕРЦІЙНЕ ВИКОРИСТАННЯ.</p>

      <p>
        10.1. Замовнику забороняється використовувати інтернет-сервіс «Mesh Align» в комерційних цілях на загальних
        підставах її використання, викладених в Договорі, даних Умовах та Політиці конфіденційності.
      </p>
      <p>
        10.2. Якщо Замовник має бажання від свого імені, або від імені інших осіб, розповсюджувати за допомогою
        інтернет-сервісу «Mesh Align» певну комерційну інформацію та/або пропонувати для комерційних цілей певні товари,
        послуги чи роботи, він може звернутися з відповідним Письмовим повідомлення до Виконавця, виклавши в ньому свої
        прохання та пропозиції з даного приводу.
      </p>
      <p>
        10.3. За результатами розгляду відповідного Письмового звернення Замовника, Виконавець може дозволити комерційне
        використання інтернет-сервісу «Mesh Align» виключно на підставі та на умовах письмового (не електронного),
        окремо укладеного між Замовником та Виконавцем договору, не маючого жодного відношення до Договору, даних Умов
        та Політики конфіденційності.
      </p>

      <p className="font-bold">РЕКВІЗИТИ ТА КОНТАКТНА ІНФОРМАЦІЯ ВИКОНАВЦЯ:</p>

      <p className="font-bold">ФІЗИЧНА ОСОБА-ПІДПРИЄМЕЦЬ ПОГАРСЬКИЙ АНТОН ЮРІЙОВИЧ.</p>

      <p>
        Дата та номер запису в Єдиному державному реєстрі юридичних осіб, фізичних осіб-підприємців та громадських
        формувань: 28.03.2018 року, № 21030000000096301.
      </p>

      <p>
        Адреса (місце фактичного проживання): 69600, Україна, Запорізька область, місто Запоріжжя, Комунарський район,
        вул. Реконструктивна, будинок 3.
      </p>

      <p>Реєстраційний номер облікової картки платника податків: 2987814459.</p>

      <p>Адреса електронної пошти: meshalign@gmail.com.</p>

      <p>
        Режим роботи: з понеділка по п’ятницю кожного тижня (крім загальносвітових та українських святкових днів), з 10
        год. 00 хв. до 17 год. 00 хв.
      </p>

      <p>Тел.: + 38 097 977 39 98.</p>
    </section>
  </div>
);

export default TermsOfUseUa;
