import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { TextField, Button } from "@material-ui/core";
import { SmsOutlined, LockOutlined, Close } from "@material-ui/icons";
import PasswordRecovery from "./PasswordRecovery";
import { Redirect, useHistory } from "react-router";
import { login } from "../../actions";
import PasswordInput from "./PasswordInput";

const LoginPage = () => {
  const { t: _t } = useTranslation();

  const dispatch = useDispatch();
  const { push } = useHistory();

  const { networkErrors, isAuth } = useSelector(({ login }) => login);

  const [passRecovery, setPassRecovery] = useState(false);

  const LogInSchema = () => {
    return Yup.object().shape({
      email: Yup.string().trim().email(_t("Invalid email address")).required(_t("This field is required")),
      password: Yup.string().required(_t("This field is required")),
    });
  };

  const logIn = (email, password) => dispatch(login(email, password, () => push("/profile")));

  const toggleRecoveryPassword = () => setPassRecovery(!passRecovery);

  if (isAuth) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className="formLayout">
      <Link to="/" className="close">
        <Close /> {_t("Close")}
      </Link>
      {passRecovery ? (
        <PasswordRecovery />
      ) : (
        <div className="form">
          <div className="title">{_t("Login")}</div>
          <div className="formik">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={LogInSchema}
              onSubmit={({ email, password }) => logIn(email, password)}
            >
              {({ errors, touched }) => (
                <Form>
                  <Field name="email">
                    {({ field }) => (
                      <div className="inputWithIcon">
                        <SmsOutlined />
                        <TextField
                          type="string"
                          label="Email address"
                          margin="dense"
                          fullWidth
                          error={!!errors.email && !!touched.email}
                          helperText={!!errors.email && !!touched.email && errors.email}
                          {...field}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="password">
                    {({ field }) => (
                      <div className="inputWithIcon">
                        <LockOutlined />
                        <PasswordInput
                          name="password"
                          label="Password"
                          fullWidth
                          margin="dense"
                          errors={errors}
                          touched={touched}
                          {...field}
                        />
                      </div>
                    )}
                  </Field>
                  <div style={{ maxWidth: 300 }}>
                    {networkErrors && networkErrors.length > 0 && <div>{_t("Wrong log in or password!")}</div>}
                  </div>
                  <p className="forgot" onClick={toggleRecoveryPassword}>
                    {_t("Forgot password?")}
                  </p>
                  <Button type="submit" className="btn">
                    {_t("Let's go")}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
